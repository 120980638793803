import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { BiCalendarEvent } from "react-icons/bi";
import { BsCameraVideoFill } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import styled from "styled-components";
import { AppButton, AppText, Loading, NewAppButton } from "../Components/UI";
import { NotificationType, NotificationsContext } from "../context";
import { CallContext } from "../context/CallContext";
import iconPhone from "../images/icons-ic-phone.png";
import { formatDateIncomingCall, formatPhoneNumber } from "../utils/format";
import { theme } from "../utils/theme";
import { currentCallState } from "src/apollo/cache";
import { PhoenixAppButton } from "src/Components/UI/Phoenix";

// TODO: Add lead information based on incoming call
// Can fetch based on number
// Populate lead information based on state

const UPDATE_USER_STATUS = gql`
  mutation updateUserStatus($status: STATUS!) {
    updateUserStatus(status: $status) {
      id
      email
      status
    }
  }
`;

const FETCH_LEAD = gql`
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      id
      newIncomingComputed
      full_name
      first_name
      last_name
      business_name
      primary_phone_number
      next_scheduled_event {
        id
        start_time
        lead_intent {
          id
          type
        }
      }
      rep {
        id
        full_name
        role
      }
    }
  }
`;

const formatEventType = (type: string) => {
  switch (type) {
    case "ColdCall":
      return "Cold Call";
    case "ScheduledCallBack":
      return "Scheduled Call Back";
    case "CallBack":
      return "Call Back";
    case "Demo":
      return "Demo";
    case "Other":
      return "Event";
    default:
      return "Event";
  }
};

const renderEventIcon = (event: any) => {
  if (!event || !event.lead_intent) {
    return <BiCalendarEvent color={theme.BLACK_COLOR} size={24} />;
  }
  switch (event.lead_intent_type) {
    case "Demo":
      return <BsCameraVideoFill color={theme.BLACK_COLOR} size={24} />;
    case "ColdCall":
    case "ScheduledCallBack":
    case "CallBack":
      return <FiPhoneCall color={theme.BLACK_COLOR} size={24} />;
    case "Other":
    default:
      return <BiCalendarEvent color={theme.BLACK_COLOR} size={24} />;
  }
};

const formatEvent = (event: any) => {
  if (!event || !event.start_time) {
    return "";
  }
  const start_time = formatDateIncomingCall(event.start_time);
  const event_type = event.lead_intent ? formatEventType(event.lead_intent.type) : "Event";
  return `${event_type} Scheduled: ${start_time}`;
};

const CallComponent: React.FC = ({ children }) => {
  /**
   * Call context
   */
  const {
    goToCallState,
    revertCallState,
    setConferenceState,
    setCallEntity,
    setupDevice,
    hangup,
    device,
    unsetInputDevices,
    showOptions,
    updateInputDevices,
    updateOutputDevices,
    refreshToken,
    setupInputDevices,
    setupOutputDevices,
    showIncoming,
    setShowIncoming,
    leadId,
    setLeadId,
    callEntity,
    incomingCallEntity,
    authToken,
    incomingError,
    setIncomingError,
    resetIncomingCall,
    callState,
    setIncomingCallEntity,
    incomingCallState,
    secondaryCallIncoming,
    setSecondaryCallIncoming,
  } = useContext(CallContext);

  const { notifications, setNotifications, addNotification } = useContext(NotificationsContext);

  const [loading, setLoading] = useState(true);

  const [acceptedCall, setAcceptedCall] = useState(false);

  const [tempNotification, setTempNotification] = useState<NotificationType | undefined>(undefined);

  const [updateUserStatus, { loading: loadStatus, error: errorStatus }] = useMutation(UPDATE_USER_STATUS, {
    onCompleted({ updateUserStatus }) {
      if (!updateUserStatus) {
        return;
      }
    },
    onError({ message }) {
      console.log(message);
    },
  });

  // set connection object to state
  // show UI for set time
  // If nothing is shown, close

  const acceptCall = () => {
    setAcceptedCall(true);
    if (!incomingCallEntity || !device) {
      return;
    }
    console.log("accept call incoming connection", incomingCallEntity);
    setCallEntity(incomingCallEntity);
    setShowIncoming(false);
    localStorage.setItem("showIncoming", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));
    resetIncomingCall();
  };

  const acceptSecondCall = () => {
    hangup();
    setAcceptedCall(true);
    if (!incomingCallEntity || !device) {
      return;
    }
    console.log("accept second call incoming connection", incomingCallEntity);
    setCallEntity(incomingCallEntity);
    setShowIncoming(false);
    localStorage.setItem("showIncoming", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));
    resetIncomingCall();
    setSecondaryCallIncoming(false);
  };

  const rejectCall = () => {
    setAcceptedCall(false);
    if (!incomingCallEntity) {
      return;
    }
    incomingCallEntity.reject();
    resetIncomingCall();
    setShowIncoming(false);
    localStorage.setItem("showIncoming", JSON.stringify(false));
    window.dispatchEvent(new Event("storage"));

    unsetInputDevices();
  };

  const rejectSecondCall = () => {
    if (!incomingCallEntity) {
      return;
    }
    incomingCallEntity.reject();
    resetIncomingCall();
    setSecondaryCallIncoming(false);
  };

  useEffect(() => {
    console.log(`Show incoming change ${showIncoming}`);
    // if the modal is being unmounted and the call has not been acceptted create a missed call notification
    if (!showIncoming && !acceptedCall) {
      addNotification(tempNotification);
    }
  }, [showIncoming]);

  const { data, loading: leadLoading, error: leadError, refetch } = useQuery(FETCH_LEAD, {
    variables: { id: leadId },
    skip: !leadId,
    fetchPolicy: "no-cache",
    onCompleted(data) {
      const inboundCallPhoneNumber =
        incomingCallEntity?.customParameters?.get("phone_number") ?? data?.fetchLead?.primary_phone_number;

      setTempNotification({
        id: "temp-notification",
        temporaryNotification: true,
        lead_id: leadId,
        relevant_id: leadId,
        text_computed: `Missed Call from ${data?.fetchLead?.business_name} (${inboundCallPhoneNumber ?? "N/A"})`,
        details: undefined,
        read: false,
        payload: {
          phone_number: inboundCallPhoneNumber ?? "",
        },
        send_action_back_to_server: false,
        type: "MissedCall",
        type_label: "Notification",
        updated_at: moment().format(),
        created_at: moment().format(),
        call_to_action_read: false,
        text_action: undefined,
        salesperson: `${data?.fetchLead?.rep?.full_name ?? "Rep"} (${data?.fetchLead?.rep?.role ?? "N/A"})`,
        can_dial: true,
        phone_number: inboundCallPhoneNumber ?? "",
      });
    },

    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  return (
    <>
      {!!showIncoming && (
        <Container>
          <IncomingHeader>
            <IncomingHeaderText>INCOMING CALL</IncomingHeaderText>
          </IncomingHeader>
          <IncomingCard>
            <img src={iconPhone} alt="Phone icon" style={{ height: 59, width: 59 }} />
            {leadLoading ? (
              <Loading />
            ) : (
              !!data.fetchLead && (
                <UserDiv>
                  <NameText style={{ marginBottom: 8 }}>
                    {data?.fetchLead?.newIncomingComputed ? "Unknown Contact" : data?.fetchLead?.full_name}
                  </NameText>
                  <BusinessNameText style={{ marginBottom: 9 }}>{data?.fetchLead?.business_name}</BusinessNameText>
                  <PhoneText style={{ marginBottom: 22 }}>
                    {formatPhoneNumber(data?.fetchLead?.primary_phone_number)}
                  </PhoneText>
                  {!!data?.fetchLead?.next_scheduled_event && (
                    <DemoDiv>
                      {renderEventIcon(data?.fetchLead?.next_scheduled_event)}
                      <EventText>{formatEvent(data?.fetchLead?.next_scheduled_event)}</EventText>
                    </DemoDiv>
                  )}
                </UserDiv>
              )
            )}
            <ButtonDiv>
              <NewAppButton size={"lg"} variant={"confirm"} onClick={acceptCall}>
                TALK NOW
              </NewAppButton>
              <NewAppButton size={"lg"} variant={"attention"} onClick={rejectCall}>
                DISMISS
              </NewAppButton>
            </ButtonDiv>
          </IncomingCard>
        </Container>
      )}
      {secondaryCallIncoming && (
        <ContainerSecondary>
          <IncomingHeader>
            <IncomingHeaderText>INCOMING CALL</IncomingHeaderText>
          </IncomingHeader>
          <IncomingCard>
            <img src={iconPhone} alt="Phone icon" style={{ height: 59, width: 59 }} />
            {leadLoading ? (
              <Loading />
            ) : (
              !!data.fetchLead && (
                <UserDiv>
                  <NameText style={{ marginBottom: 8 }}>
                    {data?.fetchLead?.newIncomingComputed ? "Unknown Contact" : data?.fetchLead?.full_name}
                  </NameText>
                  <BusinessNameText style={{ marginBottom: 9 }}>{data?.fetchLead?.business_name}</BusinessNameText>
                  <PhoneText style={{ marginBottom: 22 }}>
                    {formatPhoneNumber(data?.fetchLead?.primary_phone_number)}
                  </PhoneText>
                  {!!data?.fetchLead?.next_scheduled_event && (
                    <DemoDiv>
                      {renderEventIcon(data?.fetchLead?.next_scheduled_event)}
                      <EventText>{formatEvent(data?.fetchLead?.next_scheduled_event)}</EventText>
                    </DemoDiv>
                  )}
                </UserDiv>
              )
            )}
            <ButtonDiv>
              {!currentCallState().dispositionLogged && (
                <AppText color={theme.ATTENTION700} textAlign="center" fontSize={10} fontWeight={500}>
                  Accepting this call will exit the disposition screen. Please select an outcome before answering.
                </AppText>
              )}
              <PhoenixAppButton variant="success-outline" buttonType={"primary"} onClick={acceptSecondCall}>
                ACCEPT AND END CURRENT CALL
              </PhoenixAppButton>
              <PhoenixAppButton variant="danger-outline" buttonType={"primary"} onClick={rejectSecondCall}>
                DECLINE
              </PhoenixAppButton>
            </ButtonDiv>
          </IncomingCard>
        </ContainerSecondary>
      )}
      {children}
    </>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  right: 40px;
  width: 388px;
  border-radius: 7px;
  background-color: ${theme.WHITE_COLOR};
  z-index: 100;
  border-color: ${theme.NEUTRAL100};
  border-width: 4px;
  border-style: solid;
`;

const ContainerSecondary = styled(Container)`
  left: 112px;
`;

const DemoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
  // match old styling centered with 66% width
  width: 66%;
  margin: 0 auto;
`;

const IncomingCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 437px;
`;

const UserDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const IncomingHeader = styled.div`
  height: 34px;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${theme.BLACK_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NameText = styled(AppText)`
  font-size: 22.8px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const BusinessNameText = styled(AppText)`
  font-size: 17.4px;
  color: ${theme.BLACK_COLOR};
`;

const PhoneText = styled(AppText)`
  font-size: 17.4px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const EventText = styled(AppText)`
  font-size: 13px;
  color: ${theme.BLACK_COLOR};
`;

const IncomingHeaderText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  color: ${theme.WHITE_COLOR};
`;

const ButtonText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  color: ${theme.WHITE_COLOR};
`;

const AcceptButton = styled(AppButton)`
  height: 45px;
  width: 320px;
  background-color: ${theme.SUCCESS500};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RejectButton = styled(AppButton)`
  height: 37px;
  width: 242px;
  background-color: ${theme.ATTENTION700};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { CallComponent };
