import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { theme } from "../../utils/theme";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import { gql, useLazyQuery } from "@apollo/client";
import { restAPI } from "../../apollo";
import { loggedInUser } from "../../apollo/cache";
import { CallContext, ModalContext } from "../../context";
import { initLogRocket } from "../../services/logrock";
import { setSentry } from "../../services/sentry";
import "../../utils/react-big-calendar.css";
import { BACKEND_URL, USER_PERSIST_KEY } from "../../utils/variables";
import { Call, DashboardSidebar } from "../Segments";
import { DashboardSidebarRight } from "../Segments/DashboardSidebarRight";
import {
  DialerModal,
  DisassociateLeadModal,
  EditCustomFieldsComponent,
  EditEmailModalV2,
  LeadCustomQueueFilterComponent,
  LeadFavoriteFilterComponent,
  LeadsFiltersComponent,
  MergeContactsModal,
  SkipOrReleaseLeadModal,
  SwapPrimaryLeadModal,
  UpdateLeadDataComponent,
} from "../modal";
import { ManagerGridPage } from "./";
import { SkeletonBlock } from "../UI";
import { SequenceRepViewModal } from "../modal/Sequences/SequenceRepViewModal";
import { useFlags } from "launchdarkly-react-client-sdk";
import DialerV2 from "../modal/DialerV2";
import CallV2 from "../Segments/CallV2";

const FETCH_USER = gql`
  query fetchUser {
    fetchUser {
      id
      email
      first_name
      last_name
      role
      status
      organization_id
      team_id
      team_name
      manager_id
      manager {
        id
        first_name
        last_name
      }
      phone_number
      timezone
      profile_image
      organization {
        id
        name
        onboardComplete
        international
        mrr_label
        edge_servers
        showLeadSourceToUser
        show_nocontact_button
        show_calendar_options
        auto_dial
        rep_level_call_transfer
        enable_rep_to_connect_customers
        allow_external_transfer
        show_historical_events
        specific_time_callbacks_slot_behaviour
        title_is_business_name
        enable_csv_link
      }
      always_show_calendar_options
      joinMe_connected
      created_at
      timekit_id
      timekit_type
      panda_doc_access
      dashboard_2_access
      default_audio_input
      default_audio_output
      visible_all_leads_sm
      transfer_type
      booking_transfer_type
      checkUserCalendarStatus
      allow_external_transfer
      lead_history_filter
    }
  }
`;

export const FETCH_LEAD_HISTORY = gql`
  query fetchLeadHistory($lead_id: String!, $event_filter: HistoryEventTypeOption) {
    fetchLeadHistory(lead_id: $lead_id, event_filter: $event_filter) {
      id
      title
      type
      conference_id
      twilio_action
      computed_title
      intent_type
      call_completed_with
      created_at
      channel
      computed_type
      customer_number
      communication_type
      conference {
        id
        duration
        recording_url_computed
      }
      email_item {
        id
        subject
        body
        html
        from_email
        to_email
        reply_to_email
        sender_label
      }
      SMSItem {
        id
        text
        sender_label
      }
    }
  }
`;
export interface FetchLeadHistoryExpectedResponse {
  fetchLeadHistory: LeadHistoryFetchItem[];
}

export interface LeadHistoryFetchItem {
  id: string;
  title: string;
  type: string;
  conference_id: string;
  twilio_action: string;
  computed_title: string;
  call_completed_with: string;
  intent_type: string;
  created_at: string;
  disposition: boolean;
  channel: "Inbound" | "Outbound";
  computed_type: string;
  communication_type: string;
}

const Dashboard: React.FC = () => {
  //**
  //* User context
  //**
  const {
    callState,
    callLeadId,
    callStartTime,
    phoneNumber,
    setStopScheduledEventNotification,
    callEntity,
    setCallEntity,
    callNumber,
    nextActionOverride,
    isAutoDialActive,
    setDefaultInput,
    setDefaultOutput,
    isConnectingToTwilio,
  } = useContext(CallContext);

  const {
    showCustomFieldsModal,
    openCustomFieldsModal,
    closeCustomFieldsModal,
    showEmailEditorModal,
    showDialer,
    showSMSChat,
    showCallFeedbackModal,
    selfSource,
    setSelfSource,
    customFieldsData,
    selectedContactV2,
    setSelectedContactV2,
    showFiltersModal,
    setShowFiltersModal,
    showRepFiltersModal,
    editModal,
    setEditModal,
    setEditModalLeadId,
    editModalLeadId,
    showFavoriteFiltersModal,
    showCustomQueueFiltersModal,
    skipLeadModal,
    setSkipLeadModal,
    releaseLeadModal,
    setReleaseLeadModal,
    showInboundConciergeModal,
    setShowInboundConciergeModal,
    showMergeContactsModal,
    setShowMergeContactsModal,
    showSwapPrimaryLeadModal,
    showDisassociateLeadModal,
    setShowDisassociateLeadModal,
    showDeleteTemplateModal,
    showRepViewSequenceModal,
  } = useContext(ModalContext);

  const { connectionWarnings, sequenceRepView, showNewDialer } = useFlags();

  const [blinds, setBlinds] = useState(false);

  const [currentTask, setCurrentTask] = useState("");

  function ExtractIntegrationCode() {
    return new URLSearchParams(useLocation().search);
  }

  const integrationCode = React.useMemo(() => ExtractIntegrationCode().get("code"), []);

  useEffect(() => {
    if (!!integrationCode?.length) {
      console.log("integration code: ", integrationCode);
      sendMyCode();
    }
  }, []);

  const orgID = loggedInUser().organization_id;

  const [fetchUser, { data }] = useLazyQuery(FETCH_USER, {
    fetchPolicy: "network-only",
    onCompleted: ({ fetchUser }) => {
      setDefaultInput(fetchUser.default_audio_input);
      setDefaultOutput(fetchUser.default_audio_output);
    },
  });

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    console.log("fetchUser: ", data);
    if (!data?.fetchUser) {
      return;
    }
    loggedInUser({ ...data.fetchUser });
    localStorage.setItem(USER_PERSIST_KEY, JSON.stringify(data.fetchUser));
  }, [data?.fetchUser]);

  const sendMyCode = async () => {
    try {
      const referrer = () => {
        switch (document.referrer) {
          case "https://app.pandadoc.com/":
            return "pandadoc";
          case "https://accounts.google.com/":
            return "gmail";
          default:
            //salesforce
            return "salesforce";
        }
      };
      const api_call = await restAPI.post(
        `${BACKEND_URL}/${referrer()}/grant`,
        {
          organization_id: orgID,
          code: integrationCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      console.log("sending code: ", api_call);
      return api_call;
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setSelfSource(false);
    // Add sentry user and context
    setSentry();
    try {
      initLogRocket();
    } catch (e: any) {
      console.log("logrocket error: ", e);
    }
  }, []);

  if (loggedInUser().role === "ADMIN" || loggedInUser().role === "SM") {
    return <ManagerGridPage />;
  }

  const Dialer = showNewDialer ? DialerV2 : DialerModal;
  const CallControl = showNewDialer ? CallV2 : Call;

  return (
    <>
      <DashboardContainer autoDial={false}>
        {showDialer && <Dialer />}

        <MainArea>
          <DashboardSidebar
            popup={blinds}
            setPopup={setBlinds}
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            setSelectedContact={setSelectedContactV2}
            callState={callState}
            callStartTime={callStartTime}
            openCustomFieldsModal={openCustomFieldsModal}
            closeOtherModals={() => setBlinds(false)}
          />
          {/* {connectionWarnings && <ConnectionToast />} */}
          {showFiltersModal && (
            <DarkDiv2>
              <SlideInDiv>
                <LeadsFiltersComponent />
              </SlideInDiv>
            </DarkDiv2>
          )}
          {showFavoriteFiltersModal && (
            <DarkDiv2>
              <SlideInDiv>
                <LeadFavoriteFilterComponent />
              </SlideInDiv>
            </DarkDiv2>
          )}
          {showCustomQueueFiltersModal && (
            <DarkDiv2>
              <SlideInDiv>
                <LeadCustomQueueFilterComponent />
              </SlideInDiv>
            </DarkDiv2>
          )}
          {showSwapPrimaryLeadModal && (
            <DarkDiv2>
              <SwapPrimaryLeadModal />
            </DarkDiv2>
          )}

          {showDisassociateLeadModal && (
            <DarkDiv2>
              <DisassociateLeadModal />
            </DarkDiv2>
          )}

          {showEmailEditorModal && <EditEmailModalV2 />}

          {showCustomFieldsModal && (
            <DarkDiv2>
              <SlideInDiv>
                <EditCustomFieldsComponent
                  showEditCustomFields={showCustomFieldsModal}
                  closeCustomFieldsModal={closeCustomFieldsModal}
                  fields={customFieldsData.lead.visible_custom_fields}
                  lead_id={customFieldsData?.lead?.id}
                />
              </SlideInDiv>
            </DarkDiv2>
          )}
          {editModal && !!editModalLeadId && (
            <DarkDiv2>
              <SlideInDiv>
                <UpdateLeadDataComponent
                  visible={editModal}
                  close={() => {
                    setEditModal(false);
                    setEditModalLeadId("");
                  }}
                  lead_id={editModalLeadId}
                />
              </SlideInDiv>
            </DarkDiv2>
          )}
          {skipLeadModal && (
            <>
              <SkipOrReleaseLeadModal
                onCancel={() => setSkipLeadModal(false)}
                blinds={skipLeadModal}
                setBlinds={setSkipLeadModal}
              />
            </>
          )}

          {showRepViewSequenceModal?.visible && sequenceRepView && <SequenceRepViewModal />}

          {releaseLeadModal && (
            <SkipOrReleaseLeadModal
              release
              onCancel={() => setReleaseLeadModal(false)}
              blinds={releaseLeadModal}
              setBlinds={setReleaseLeadModal}
            />
          )}

          {!showMergeContactsModal ? (
            <>
              <MainContainer>
                <CallControl
                  callState={callState}
                  callStartTime={callStartTime}
                  phoneNumber={phoneNumber}
                  setBlinds={setBlinds}
                  popup={blinds}
                  setPopup={setBlinds}
                  currentTask={currentTask}
                  setCurrentTask={setCurrentTask}
                />
              </MainContainer>
              <DashboardSidebarRight />
            </>
          ) : (
            <MergeContactModalWrap>
              <MergeContactsModal />
            </MergeContactModalWrap>
          )}
        </MainArea>
      </DashboardContainer>
    </>
  );
};

const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 552px; }
`;

const SlideInDiv = styled.div`
  position: absolute;
  left: 72px;
  width: calc(478px + 72px);
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  z-index: 8;
  background-color: ${theme.WHITE_COLOR};
`;

const MainContainer = styled.div`
  position: relative;
  height: 100%;
  background: ${theme.WHITE_COLOR};
  overflow: auto;
  min-width: 440px;
`;

interface AutoDialProp {
  autoDial: boolean;
}

const DashboardContainer = styled.div<AutoDialProp>`
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  background: ${theme.WHITE_COLOR};
`;
const MainArea = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 100%;
  background-color: ${theme.surface.neutral.tertiary};
`;

const MergeContactModalWrap = styled.div`
  padding: 24px;
  grid-column: 2 / -1;
  height: 100vh;
`;

export { Dashboard };
