import React, { useCallback, useContext, useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { gql, useQuery } from "@apollo/client";
import { FlexDiv } from "src/Components/UI";
import { PhoenixAppButton, PhoenixIcon } from "src/Components/UI/Phoenix";
import { usePub } from "src/utils/hooks";
import { DEFAULT_LEAD_SYSTEM_FILTER, LeadFilterContext, LeadFilterObject } from "src/context";
import useLeadsFilterOptions from "src/query-hooks/useLeadsFilterOptions";
import { plus, sync } from "src/images/NewDesign";
import { OptionItem } from "src/types";
import { hasValue } from "src/utils/misc";
import { AddOrEditLeadFilterSavedViewModal } from "src/Components/modal/AddOrEditLeadFilterSavedViewModal";
import { CloneLeadFilterSavedViewModal } from "src/Components/modal/CloneLeadFilterSavedViewModal";
import { DeleteLeadFilterSavedViewModal } from "src/Components/modal/DeleteLeadFilterSavedViewModal";
import { SetLeadFilterSavedViewAsDefaultModal } from "src/Components/modal/SetLeadFilterSavedViewAsDefaultModal";
import { ProfileImageWithText } from "../SettingSegments/ProfileImageWithText";
import CallResultFilter from "./CallResultFilter";
import DateFilter from "./DateFilter";
import LeadHistoryFilter from "./LeadHistoryFilter";
import MultiSelectFilter from "./MultiSelectFilter";
import NextScheduledEventFilter from "./NextScheduledEventFilter";
import NotesFilter from "./NotesFilter";
import RepActivityFilter from "./RepActivityFilter";
import SavedViewDetails from "./SavedViewDetails";
import SavedViewDropdown from "./SavedViewDropdown";
import { formatSavedViewFiltersToLeadFilterObject, MORE_FILTERS_OPTIONS, MoreFilterOption } from "./shared";
import SavedViewAction from "./SavedViewAction";
import useFetchLeadsSavedViews from "src/query-hooks/useFetchLeadsSavedViews";
import ZipCodeFilter from "./ZipCodeFilter";
import SequencesFilter from "./SequencesFilter";

const getMoreSelectedFilters = (leadSystemFilter: LeadFilterObject) => {
  const customFields = leadSystemFilter.custom_fields.map((field: any) => field.key);
  const moreFilters = MORE_FILTERS_OPTIONS.filter((option) => {
    const value = leadSystemFilter[option.value];
    return value && hasValue(value);
  }).map((option) => option.value);

  return [...moreFilters, ...customFields];
};

const FETCH_ALL_CUSTOM_FIELDS = gql`
  query FetchCustomFields {
    fetchCustomFields {
      id
      key
      type
      allow_reps_to_edit
      visible
      options
    }
  }
`;

const LeadsFilterV2 = React.memo(({ myLeads }: { myLeads?: boolean }) => {
  const publish = usePub();
  const hasLoadedSavedViews = useRef(false);
  const {
    leadSystemFilter,
    setLeadSystemFilter,
    setLeadSystemOperators,
    leadSystemOperators,
    resetLeadSystemFilters,
    resetLeadSystemOperators,
    savedViewID,
    setSavedViewID,
    setSavedView,
    leadSystemFilterCount,
  } = useContext(LeadFilterContext);

  const { mapOptionsToKey, getSubIndustryOptions } = useLeadsFilterOptions();

  const [moreFilters, setMoreFilters] = useState<string[]>(() => getMoreSelectedFilters(leadSystemFilter));

  const { data: customFields } = useQuery(FETCH_ALL_CUSTOM_FIELDS, {
    fetchPolicy: "network-only",
  });

  const customFieldsData = (customFields?.fetchCustomFields ?? [])
    ?.filter((item: any) => {
      return item.type === "Dropdown" || item.type === "MultiDropdown" || item.type === "Boolean";
    })
    ?.map((field: any) => ({
      label: field.key,
      value: field.key,
      type: "custom_field",
      item: field,
    }));

  const { data: savedViews, loading: loadingSavedViews } = useFetchLeadsSavedViews({
    onCompleted: (data) => {
      if (data.length && !hasLoadedSavedViews.current) {
        const defaultView = data.find((view: any) => view.is_default);
        setSavedViewID(defaultView?.id);
      }
      hasLoadedSavedViews.current = true;
    },
  });

  const moreFiltersOptions = [...MORE_FILTERS_OPTIONS, ...customFieldsData].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  useEffect(() => {
    if (savedViewID && savedViews.length) {
      const view = savedViews?.find((view: any) => view.id === savedViewID);
      setSavedView(view);
      if (view) {
        publish("SAVED_VIEW_UPDATED", view);
        const newleadFilter = formatSavedViewFiltersToLeadFilterObject(view.lead_filter);
        setLeadSystemFilter(newleadFilter);
        setLeadSystemOperators(view.lead_system_operators);
        setMoreFilters(getMoreSelectedFilters(newleadFilter));
      }
    }
  }, [savedViews, savedViewID]);

  const handleReset = useCallback(() => {
    resetLeadSystemFilters();
    resetLeadSystemOperators();
    setMoreFilters([]);
  }, [resetLeadSystemFilters, resetLeadSystemOperators]);

  const removeFilter = useCallback(
    (filter: string) => {
      setMoreFilters((prev) => prev.filter((f) => f !== filter));
      const updatedCustomFields = [...leadSystemFilter.custom_fields];
      const existingFieldIndex = updatedCustomFields.findIndex((field: any) => field.key === filter);

      if (existingFieldIndex !== -1) {
        updatedCustomFields.splice(existingFieldIndex, 1);
        setLeadSystemFilter({
          ...leadSystemFilter,
          custom_fields: updatedCustomFields,
        });
      } else {
        setLeadSystemFilter({
          ...leadSystemFilter,
          [filter]: DEFAULT_LEAD_SYSTEM_FILTER[filter],
        });
      }
    },
    [leadSystemFilter],
  );

  const renderFilterItem = useCallback(
    (option: MoreFilterOption) => {
      switch (option.type) {
        case "rep_activity_filter":
          return (
            <RepActivityFilter
              key={option.label}
              isMoreFilter
              options={mapOptionsToKey("reps")}
              removeFilter={() => removeFilter(option.value)}
              value={leadSystemFilter.rep_activity_filter}
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  rep_activity_filter: { ...leadSystemFilter.rep_activity_filter, ...v },
                })
              }
            />
          );

        case "next_scheduled_event":
          return (
            <NextScheduledEventFilter
              key={option.label}
              isMoreFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  next_scheduled_event: { ...leadSystemFilter.next_scheduled_event, ...v },
                })
              }
              options={mapOptionsToKey("next_scheduled_event")}
              removeFilter={() => removeFilter(option.value)}
              value={leadSystemFilter.next_scheduled_event}
            />
          );

        case "custom_field":
          const options = (option.item?.type === "Boolean" ? ["true", "false"] : option.item?.options ?? []).map(
            (item: any) => ({
              label: item,
              value: item,
            }),
          );
          return (
            <MultiSelectFilter
              key={option.label}
              isMoreFilter
              label={option.value}
              name={option.value}
              options={options}
              removeFilter={() => removeFilter(option.value)}
              onChange={(v) => {
                const updatedCustomFields = [...leadSystemFilter.custom_fields];
                const existingFieldIndex = updatedCustomFields.findIndex((field: any) => field.key === option.value);

                if (existingFieldIndex !== -1) {
                  updatedCustomFields[existingFieldIndex] = {
                    ...updatedCustomFields[existingFieldIndex],
                    value: v,
                  };
                } else {
                  updatedCustomFields.push({
                    id: option.item?.id,
                    key: option.value,
                    type: option.item?.type,
                    value: v,
                  });
                }
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  custom_fields: updatedCustomFields,
                });
              }}
              value={leadSystemFilter.custom_fields.find((field: any) => field.key === option.value)?.value ?? []}
            />
          );

        case "multi_select":
          return option.value === "sequences" ? (
            <SequencesFilter
              key={option.label}
              isMoreFilter
              onChange={(v: any) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  sequences: [...leadSystemFilter.sequences, ...v],
                })
              }
              removeFilter={() => removeFilter("sequences")}
              value={leadSystemFilter.sequences}
            />
          ) : (
            <MultiSelectFilter
              key={option.label}
              isMoreFilter
              label={option.label}
              removeFilter={() => removeFilter(option.value)}
              name={option.value}
              onChange={(v) => {
                setLeadSystemFilter({ ...leadSystemFilter, [option.value]: v });
              }}
              options={
                option.value === "sub_industry"
                  ? getSubIndustryOptions(leadSystemFilter.industries)
                  : mapOptionsToKey(option.value)
              }
              showMoreBadge={option.value !== "channels"}
              value={leadSystemFilter[option.value]}
              {...(option.hideOperator
                ? {}
                : {
                    onChangeOperator: (v) =>
                      setLeadSystemOperators({ ...leadSystemOperators, [`${option.value}_operator`]: v }),
                    operator: leadSystemOperators[
                      `${option.value}_operator` as keyof typeof leadSystemOperators
                    ] as any,
                  })}
            />
          );

        case "date":
          return (
            <DateFilter
              key={option.label}
              isMoreFilter
              label={option.label}
              removeFilter={() => removeFilter(option.value)}
              name={option.value}
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  [option.value]: { ...leadSystemFilter[option.value], ...v },
                })
              }
              value={leadSystemFilter[option.value]}
            />
          );

        case "notes":
          return (
            <NotesFilter
              key={option.label}
              isMoreFilter
              removeFilter={() => removeFilter(option.value)}
              value={leadSystemFilter.notes}
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, notes: v })}
            />
          );

        case "zip_codes":
          return (
            <ZipCodeFilter
              key={option.label}
              isMoreFilter
              removeFilter={() => removeFilter(option.value)}
              value={leadSystemFilter.zip_codes}
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, zip_codes: v })}
            />
          );

        default:
          return null;
      }
    },
    [leadSystemFilter, setLeadSystemFilter, mapOptionsToKey, getSubIndustryOptions, removeFilter],
  );

  const defaultView = savedViews?.find((view: any) => view.is_default);

  const renderRepOption = useCallback(
    (option: OptionItem & Record<string, any>) => (
      <>
        <ProfileImageWithText
          first_name={option.first_name}
          height={24}
          last_name={option.last_name}
          profile_image={option.profile_image}
          width={24}
          textSize={8}
          hideTooltip
        />
        {option.label}
      </>
    ),
    [],
  );

  const moreFilterItems = useMemo(
    () => moreFiltersOptions.filter((option) => moreFilters.includes(option.value.toString())).map(renderFilterItem),
    [moreFilters, renderFilterItem],
  );

  const handleMultiSelectChange = useCallback(
    (field: string) => (v: any) => setLeadSystemFilter({ ...leadSystemFilter, [field]: v }),
    [leadSystemFilter, setLeadSystemFilter],
  );

  const handleOperatorChange = useCallback(
    (field: string) => (v: any) => setLeadSystemOperators({ ...leadSystemOperators, [`${field}_operator`]: v }),
    [leadSystemOperators, setLeadSystemOperators],
  );

  return (
    <>
      <Container>
        {!loadingSavedViews || hasLoadedSavedViews.current ? <SavedViewDetails defaultView={defaultView} /> : null}
        <Wrapper>
          <FlexDiv align="center" gap={8} wrap="wrap">
            <MultiSelectFilter
              label="Rep"
              name="reps"
              onChange={handleMultiSelectChange("reps")}
              onChangeOperator={handleOperatorChange("reps")}
              operator={leadSystemOperators.reps_operator}
              options={mapOptionsToKey("reps")}
              value={leadSystemFilter.reps}
              renderOption={renderRepOption}
              removeFilter={() => removeFilter("reps")}
            />
            <MultiSelectFilter
              label="Industry"
              name="industries"
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, industries: v })}
              onChangeOperator={(v) => setLeadSystemOperators({ ...leadSystemOperators, industries_operator: v })}
              operator={leadSystemOperators.industries_operator}
              options={mapOptionsToKey("industries")}
              value={leadSystemFilter.industries}
              removeFilter={() => removeFilter("industries")}
            />
            <LeadHistoryFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  history_filter: { ...leadSystemFilter.history_filter, ...v },
                  call_result_filter: {
                    ...leadSystemFilter.call_result_filter,
                    lowerbound_date: v.lowerbound_date,
                    upperbound_date: v.upperbound_date,
                  },
                })
              }
              removeFilter={() => removeFilter("history_filter")}
              value={leadSystemFilter.history_filter}
            />
            <CallResultFilter
              onChange={(v) =>
                setLeadSystemFilter({
                  ...leadSystemFilter,
                  call_result_filter: { ...leadSystemFilter.call_result_filter, ...v },
                  history_filter: {
                    ...leadSystemFilter.history_filter,
                    lowerbound_date: v.lowerbound_date,
                    upperbound_date: v.upperbound_date,
                  },
                })
              }
              onChangeOperator={handleOperatorChange("call_result_type")}
              operator={leadSystemOperators.call_result_type_operator}
              removeFilter={() => removeFilter("call_result_filter")}
              value={leadSystemFilter.call_result_filter}
            />
            <MultiSelectFilter
              label="Status"
              name="status"
              onChange={(v) => setLeadSystemFilter({ ...leadSystemFilter, status: v })}
              onChangeOperator={(v) => setLeadSystemOperators({ ...leadSystemOperators, status_operator: v })}
              operator={leadSystemOperators.status_operator}
              options={mapOptionsToKey("status")}
              value={leadSystemFilter.status}
              removeFilter={() => removeFilter("status")}
            />
            <DateFilter
              label="Created Date"
              name="created_date"
              onChange={(v) =>
                setLeadSystemFilter({ ...leadSystemFilter, created_date: { ...leadSystemFilter.created_date, ...v } })
              }
              value={leadSystemFilter.created_date}
              removeFilter={() => removeFilter("created_date")}
            />

            {moreFilterItems}

            <MultiSelectFilter
              canSearch
              icon={plus}
              closeOnChange
              label="More"
              name="more_filters"
              onChange={setMoreFilters}
              options={moreFiltersOptions}
              renderHeader={null}
              stateless
              value={moreFilters}
            />

            {leadSystemFilterCount ? (
              <PhoenixAppButton buttonType="ghost-small" onClick={handleReset}>
                <PhoenixIcon svg={sync} size={12} />
                Reset Filter
              </PhoenixAppButton>
            ) : null}
          </FlexDiv>

          <FlexDiv align="center" gap={16}>
            <SavedViewAction />
            <SavedViewDropdown onReset={handleReset} savedViews={savedViews} />
          </FlexDiv>
        </Wrapper>
      </Container>

      <AddOrEditLeadFilterSavedViewModal />
      <CloneLeadFilterSavedViewModal />
      <DeleteLeadFilterSavedViewModal />
      <SetLeadFilterSavedViewAsDefaultModal defaultView={defaultView} />
    </>
  );
});

LeadsFilterV2.displayName = "LeadsFilterV2";

export default LeadsFilterV2;

const Container = styled(FlexDiv)`
  flex-direction: column;
  gap: 16px;
  padding: 10px 0;
  width: 100%;
`;

const Wrapper = styled(FlexDiv)`
  align-items: flex-start;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
  z-index: 99;
`;
