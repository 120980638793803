import {
  call_type_decision,
  call_type_demo,
  call_type_idle,
  call_type_inbound,
  call_type_offline,
  call_type_other,
  call_type_outbound,
  call_type_scheduled,
} from "src/images/NewDesign";
import { theme } from "src/utils/theme";

const DEFAULT_COLOR = "#005FEC";
const DEFAULT_BACKGROUND_COLOR = "#C8E5FF";

export const CALL_TYPE_FILTERS = [
  {
    label: "Outbound Call",
    value: "Outbound Call",
    icon: call_type_outbound,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    color: DEFAULT_COLOR,
  },
  {
    label: "Inbound Call",
    value: "Inbound Call",
    icon: call_type_inbound,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    color: DEFAULT_COLOR,
  },
  {
    label: "Scheduled Callback",
    value: "Scheduled Callback",
    icon: call_type_scheduled,
    backgroundColor: theme.text.dataviz1.disabled,
    color: theme.text.dataviz1.primary,
  },
  {
    label: "Demo",
    value: "Demo",
    icon: call_type_demo,
    backgroundColor: theme.text.success.disabled,
    color: theme.text.success.primary,
  },
  {
    label: "Decision Call",
    value: "Decision Call",
    icon: call_type_decision,
    backgroundColor: theme.text.dataviz3.disabled,
    color: theme.text.dataviz3.primary,
  },
  {
    label: "Other",
    value: "Other",
    icon: call_type_other,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    color: DEFAULT_COLOR,
  },
  {
    label: "Idle",
    value: "Idle",
    icon: call_type_idle,
    backgroundColor: theme.surface.neutral.secondary,
    color: theme.text.neutral.secondary,
  },
  {
    label: "Offline",
    value: "Offline",
    icon: call_type_offline,
    backgroundColor: theme.text.warning.disabled,
    color: theme.surface.warning.inverse,
  },
];

export const CALL_TYPE_COLOR_MAP: Record<string, string> = {
  IDLE: theme.surface.neutral.secondary,
  OFFLINE: theme.text.warning.disabled,
  Demo: theme.text.success.disabled,
  "Scheduled Callback": theme.text.dataviz1.disabled,
  "Outbound Call": DEFAULT_BACKGROUND_COLOR,
  "Inbound Call": DEFAULT_BACKGROUND_COLOR,
  "Decision Call": theme.text.dataviz3.disabled,
};

export const CALL_TYPE_ICON_MAP: Record<string, any> = {
  IDLE: call_type_idle,
  OFFLINE: call_type_offline,
  Demo: call_type_demo,
  "Scheduled Callback": call_type_scheduled,
  "Outbound Call": call_type_outbound,
  "Inbound Call": call_type_inbound,
  "Decision Call": call_type_decision,
};
