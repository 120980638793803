import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, FlexDiv } from "../../../UI";
import { functions, isEqual, omit } from "lodash";
import { LeadCardContext } from "src/context";
import { OptionItem } from "src/types";
import { ComputedLeadData } from "./LeadCardV2";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";
import { formatDate } from "src/utils/date";
import { isValidURL } from "../../../../utils/misc";

interface LeadCardTopInfoV2Props {
  computedLeadData: ComputedLeadData;
  customFieldData: any;
  currentTime: string;
}

const LeadCardTopInfoV2Component: React.FC<LeadCardTopInfoV2Props> = ({
  computedLeadData,
  customFieldData,
  currentTime,
}) => {
  const { leadCardFields, loadingLeadCardFields } = useContext(LeadCardContext);
  return (
    <CardGridMiddle>
      <FlexDiv wrap="wrap">
        {!loadingLeadCardFields &&
          leadCardFields.map((_, i) => (
            <Field
              leadCardFields={leadCardFields}
              computedLeadData={computedLeadData}
              customFieldData={customFieldData}
              currentTime={currentTime}
              index={i}
            />
          ))}
      </FlexDiv>
    </CardGridMiddle>
  );
};

// map leadCardField key/value to computedLeadData key/value
const dataMap = {
  BUSINESS_NAME: "business_name",
  CHANNEL: "channel",
  CITY: "city",
  MRR: "computed_mrr",
  COUNTRY: "country",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  FULL_NAME: "full_name",
  INDUSTRY: "industry",
  SUB_INDUSTRY: "sub_industry",
  LEAD_SOURCE: "lead_source",
  EMAIL: "primary_email",
  PRIMARY_PHONE_NUMBER: "primary_phone_number",
  STATE: "state",
  ADDRESS: "address",
  ZIP_CODE: "zip",
  CURRENT_CLOSE_DATE: "current_close_date",
};

const CardGridMiddle = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
  width: 100%;
`;

interface IField {
  leadCardFields: OptionItem[];
  computedLeadData: ComputedLeadData;
  currentTime: string;
  customFieldData: any;
  index: number;
}

const Field: React.FC<IField> = ({ leadCardFields, computedLeadData, currentTime, customFieldData, index }) => {
  const field = useMemo(() => leadCardFields[index], [leadCardFields, index]);
  const fieldValue = field.value?.toString() as keyof typeof dataMap;
  const key = dataMap[fieldValue] as keyof ComputedLeadData;
  const value = computedLeadData[key];
  const customValue = customFieldData?.find((e: any) => e.key === field.value)?.value;

  const formattedValue = useMemo(() => {
    switch (key) {
      case "current_close_date":
        return value ? formatDate(value as string) : null;
      case "state":
        return value && computedLeadData?.timezone_by_state && currentTime
          ? `${value} (${currentTime}${computedLeadData?.timezone_by_state
            ?.split(" ")
            ?.pop()
            ?.replace(/[{()}]/g, "") ?? ""
          })`
          : value
            ? value
            : null;
      default:
        return value;
    }
  }, [value, computedLeadData, currentTime]);

  const fullWidth = leadCardFields.length % 2 !== 0 && index === leadCardFields.length - 1;
  const hasOverflowTooltip = useMemo(() => value?.toString()?.length > 24 && !fullWidth, [value, fullWidth]);

  return (
    <FlexDiv
      direction="column"
      padding="4px 0px"
      width={fullWidth ? "100%" : "50%"}
      style={{ borderBottom: `1px solid ${theme.border.neutral.secondary}` }}
      key={`${computedLeadData?.id}-${field.value}`}
    >
      <PhoenixStyledTooltip id={`field-tooltip-${key}-${computedLeadData?.id}`} />
      <AppText fontSize={10} lineHeight={14} color={theme.text.neutral.secondary}>
        {field.label}
      </AppText>
      <AppText
        fontSize={12}
        lineHeight={18}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: fullWidth ? undefined : "95%",
        }}
        data-tip={value || customValue}
        data-for={hasOverflowTooltip && `field-tooltip-${key}-${computedLeadData?.id}`}
      >
        {isValidURL(formattedValue || customValue || "NA") ? (
          <a
            href={`${(formattedValue || customValue || "NA").startsWith("http")
              ? ""
              : "//"
              }${formattedValue || customValue || "NA"}`}
            target="_blank"
            rel="noreferrer"
          >
            {formattedValue || customValue || "NA"}
          </a>
        ) : (
          formattedValue || customValue || "NA"
        )}
      </AppText>
    </FlexDiv>
  );
};

const MemoizedLeadCardTopInfoV2 = React.memo(LeadCardTopInfoV2Component, (oldProps, newProps) => {
  const oldP = omit(oldProps, functions(oldProps));
  const newP = omit(newProps, functions(newProps));
  return isEqual(oldP, newP);
});

export { MemoizedLeadCardTopInfoV2 as LeadCardTopInfoV2 };
