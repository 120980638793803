import { gql, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { Formik, FormikProps } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { loggedInUser } from "../../apollo/cache";
import { ModalContext } from "../../context/ModalContext";
import { iconDesktop } from "../../images";
import { theme } from "../../utils/theme";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { AppButton, AppErrorText, AppSidebarCard, AppText, DarkDiv, FlexDiv, Loading, SkeletonBlock } from "../UI";
import {
  AddAssociatedContact,
  AddOrEditContactModal,
  DisassociateLeadModal,
  EditCustomFieldsComponent,
  EditEmailModalV2,
  Modal,
  PanelModal,
  SwapPrimaryLeadModal,
  UpdateLeadDataComponent,
} from "../modal";
import { EditEventDetailsV2, SelectDispo } from "./";

import { appToast } from "../../utils/toast";

import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { useHistory } from "react-router-dom";
import { LeadFilterContext } from "src/context";
import { CORE_LEAD_CARD_DATA } from "../../apollo/fragments";
import { google_meet_logo, list, map_pin, users, zoom_logo } from "../../images/NewDesign";
import { sanitizeURL } from "../../utils/misc";
import { APP_ENABLEMENT_ROLES, APP_REP_ROLES, APP_ROLES, DEFAULT_CLOUDINARY_IMAGE } from "../../utils/variables";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { cloudinary } from "./../../services/cloudinary";
import { FETCH_HISTORICAL_ITEM } from "./MyScheduleCal";

interface InviteProps {
  eventEditData: any;
}

export const CREATE_JOIN_ME = gql`
  mutation createJoinMe($start: DateTime!, $end: DateTime!) {
    createJoinMe(start: $start, end: $end) {
      id
      viewer_url
      meeting_id
    }
  }
`;

export const FETCH_NEXT_DIAL = gql`
  query fetchNextDial($lead_filter: LeadFilter, $override_timezone_contraints: Boolean) {
    fetchNextDial(lead_filter: $lead_filter, override_timezone_contraints: $override_timezone_contraints) {
      id
      lead_id
      type
      is_scheduled_item
      schedule_item {
        id
        start_time
        end_time
        google_space_sellfire_id
        google_space_info {
          google_space_sellfire_id
          id
          meeting_url
          meeting_code
        }
        zoom_meeting_sellfire_id
        zoom_meeting_info {
          zoom_meeting_sellfire_id
          meeting_url
          meeting_id
        }
      }
    }
  }
`;

export const CREATE_GENERAL_TIME_SCHEDULED_EVENT = gql`
  mutation createGeneralTimeScheduledEvent(
    $lead_id: String!
    $day: DateTime
    $week_start: DateTime
    $week_end: DateTime
    $month: DateTime
    $anytime_after: DateTime
    $anytime_before: DateTime
    $timezone: String
    $user_id: String
    $intent_id: String
    $disposition_id: String
    # $disposition_type: DISPOSITIONTYPE
    $person_spoke_to: CALLRESULT
    $call: Boolean
    $action: String
    $from_custom_call_queue: Boolean
    $call_sid: String
  ) {
    createGeneralTimeScheduledEvent(
      lead_id: $lead_id
      day: $day
      week_start: $week_start
      week_end: $week_end
      month: $month
      anytime_after: $anytime_after
      anytime_before: $anytime_before
      timezone: $timezone
      user_id: $user_id
      intent_id: $intent_id
      disposition_id: $disposition_id
      # disposition_type: $disposition_type
      person_spoke_to: $person_spoke_to
      call: $call
      action: $action
      from_custom_call_queue: $from_custom_call_queue
      call_sid: $call_sid
    ) {
      id
      lead {
        last_lead_activity {
          id
          related_disposition {
            id
            completed_text
            completed_image
            completed_tip
          }
        }
      }
    }
  }
`;

const FETCH_LEAD = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      ...CoreLeadCardData
      id
      first_name
      last_name
      full_name
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      business_name
      industry
      lead_source
      channel
      status
      title
      content
      city
      state
      country
      timezone_by_state
      address
      zip
      rep_id
      upcoming_events {
        id
        action_label
        start_time
      }
      call_notes {
        id
        notes
        created_at
      }
      alternate_contacts {
        id
        channel
        type
        value
        title
      }
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
      ...CoreLeadCardData
    }
  }
`;

export interface IEventPageScheduleItem {
  id: string;

  deleted_at: string | null;
  action_label: string;
  start_time: string;
  end_time: string;
  what: string;
  where: string;
  description: string;
  timezone: string;
  join_me_id?: string | null;
  join_me_item?: {
    schedule_item_id: string;
    schedule_item: {
      booking_id: string;
      join_me_item: {
        id: string;
        viewer_url: string;
      };
    };
  } | null;
  google_space_sellfire_id: string | null;
  google_space_info?: {
    meeting_url: string;
    meeting_code: string;
  };
  timeoff_request: {
    result: string;
    schedule_item_id: string;
  } | null;
  guests: Array<{
    id: string;
    email: string;
    result: string;
  }>;
  lead_intent: {
    id: string;
    type: string;
    label: string;
    associated_action: string;
    lead: {
      id: string;
      full_name: string;
      business_name: string;
      primary_email: string;
      primary_phone_number: string;
    };
    user: {
      id: string;
      full_name: string;
      team_name: string;
      role: string;
    };
  } | null;
}

export interface IEventPageScheduleItem {
  id: string;

  deleted_at: string | null;
  action_label: string;
  start_time: string;
  end_time: string;
  what: string;
  where: string;
  description: string;
  timezone: string;
  join_me_id?: string | null;
  join_me_item?: {
    schedule_item_id: string;
    schedule_item: {
      booking_id: string;
      join_me_item: {
        id: string;
        viewer_url: string;
      };
    };
  } | null;
  google_space_sellfire_id: string | null;
  google_space_info?: {
    meeting_url: string;
    meeting_code: string;
  };
  zoom_meeting_sellfire_id: string | null;
  zoom_meeting_info?: {
    meeting_url: string;
    meeting_id: string;
  };
  timeoff_request: {
    result: string;
    schedule_item_id: string;
  } | null;
  guests: Array<{
    id: string;
    email: string;
    result: string;
  }>;
  lead_intent: {
    id: string;
    type: string;
    label: string;
    associated_action: string;
    lead: {
      id: string;
      full_name: string;
      business_name: string;
      primary_email: string;
      primary_phone_number: string;
    };
    user: {
      id: string;
      full_name: string;
      team_name: string;
      role: string;
    };
  } | null;
}

const FETCH_SCHEDULE_ITEM = gql`
  query scheduleItem($id: String!) {
    scheduleItem(where: { id: $id }) {
      id
      deleted_at
      action_label
      start_time
      end_time
      what
      where
      description
      timezone
      join_me_id
      join_me_item {
        schedule_item_id
        schedule_item {
          booking_id
          join_me_item {
            id
            viewer_url
          }
        }
      }
      google_space_sellfire_id
      google_space_info {
        meeting_url
        meeting_code
      }
      zoom_meeting_sellfire_id
      zoom_meeting_info {
        meeting_url
        meeting_id
      }
      timeoff_request {
        result
        schedule_item_id
      }
      guests {
        id
        email
        result
      }
      lead_intent {
        id
        type
        label
        associated_action
        lead {
          id
          full_name
          business_name
          primary_email
          primary_phone_number
        }
        user {
          id
          full_name
          team_name
          role
        }
      }
    }
  }
`;

const DELETE_SCHEDULED_EVENT = gql`
  mutation DeleteScheduledEvent($schedule_item_id: String!, $disposition_id: String!, $lead_id: String!) {
    deleteScheduledEvent(schedule_item_id: $schedule_item_id, disposition_id: $disposition_id, lead_id: $lead_id) {
      id
      booking_id
      overflow_booking_id
      description
      action_label
      graph
      state
      what
      where
      timezone
      available_actions
      start_time
      end_time
      movable
    }
  }
`;

const FETCH_PROFILE_IMAGE = gql`
  query FetchLeadAndOwnedRep($id: String!) {
    fetchLeadAndOwnedRep(id: $id) {
      lead {
        id
      }
      rep {
        profile_image
      }
    }
  }
`;

interface deleteEventFormikProps {
  // loaded from event
  schedule_item_id: string;
  lead_id: string;
  // must be selected through modal flow
  updated_person_spoke_to: string;
  updated_disposition_id: string;
}

const deleteEventDefaultProps = {
  schedule_item_id: "",
  lead_id: "",
  disposition_id: "",
};

const EventDetails: React.FC<InviteProps> = ({ eventEditData }) => {
  const { leadFilter } = useContext(LeadFilterContext);
  const {
    showCustomFieldsModal,
    setCustomFieldsModal,
    openCustomFieldsModal,

    closeCustomFieldsModal,
    showEmailEditorModal,

    customFieldsData,
    selectedContactV2,
    editModal,
    setEditModal,
    editModalLeadId,
    setEditModalLeadId,
    showAddOrEditContactModal,
    setShowAddOrEditContactModal,
    showAddAssociateModal,
    setShowAddAssociateModal,
    showSwapPrimaryLeadModal,
    showDisassociateLeadModal,
  } = useContext(ModalContext);

  const { zoomIntegration, googleMeetIntegration } = useFlags();

  const formikRef = useRef() as any;

  const [editEventModal, setEditEventModal] = useState(false);

  const [deleteEventModal, setDeleteEventModal] = useState(false);

  const [isThisACallback, setIsThisACallback] = useState(false);

  const history = useHistory();

  const [joinMeMeeting, setJoinMeMeeting] = useState({} as any);

  const { data: leadData, loading: leadLoading, error: leadError, refetch: refetchLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "network-only",
    skip: !eventEditData?.lead_id,
    variables: {
      id: eventEditData.lead_id,
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const {
    data: dataProfileImage,
    loading: loadingProfileImage,
    error: errorProfileImage,
    refetch: refetchProfileImage,
  } = useQuery(FETCH_PROFILE_IMAGE, {
    fetchPolicy: "network-only",
    variables: {
      id: eventEditData.lead_id,
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataEvent, loading: loadingEvent, error: errorEvent, refetch: refetchEvent } = useQuery<{
    scheduleItem: IEventPageScheduleItem;
  }>(FETCH_SCHEDULE_ITEM, {
    fetchPolicy: "network-only",
    skip: !eventEditData?.id,
    variables: {
      id: eventEditData?.id,
    },
    onCompleted({ scheduleItem }) {
      setIsThisACallback(
        ["Schedule Callback", "Scheduled Callback", "Schedule Call Back", "ScheduledCallBack"].includes(
          scheduleItem?.lead_intent?.type || "",
        ),
      );
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataNextDial, loading: loadingNextDial, error: errorNextDial } = useQuery(FETCH_NEXT_DIAL, {
    fetchPolicy: "network-only",
    skip: !APP_REP_ROLES.includes(loggedInUser().role as APP_ROLES),
  });

  const nextDialEvents = dataNextDial?.fetchNextDial;

  const eventIsInNextDial = nextDialEvents?.some((item: any) => item?.schedule_item?.id === eventEditData?.id);

  const matchingNextDialEvent = nextDialEvents?.find((item: any) => item?.schedule_item?.id === eventEditData?.id);

  const { data: dataHistoricalItem, loading: loadingHistoricalItem, error: errorHistoricalItem } = useQuery(
    FETCH_HISTORICAL_ITEM,
    {
      fetchPolicy: "network-only",
      skip: !eventEditData?.id,
      variables: {
        id: eventEditData.id,
      },
    },
  );

  const [createJoinMe, { loading: loadingJoinMe, error: errorJoinMe }] = useMutation(CREATE_JOIN_ME, {
    async onCompleted({ createJoinMe }) {
      if (!createJoinMe) {
        appToast("Error creating JoinMe. Please try again.");
        return;
      }
      setJoinMeMeeting(createJoinMe);
      appToast("JoinMe created");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `createJoinMe GraphQL Error: ${message}`,
      });
      console.log("Error in createJoinMe: ", message);
    },
  });

  const [deleteScheduledEvent, { loading: loadingDeleteEvent }] = useMutation(DELETE_SCHEDULED_EVENT, {
    onCompleted({}) {
      loggedInUser().role === "ADMIN" ? history.push(`/event-manager/`) : history.push(`/manage-schedule/`);
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `deleteScheduledEvent GraphQL Error: ${message}`,
      });
      console.log("Error in deleteScheduledEvent: ", message);
    },
  });

  useEffect(() => {
    if (!!dataEvent?.scheduleItem?.join_me_item?.schedule_item?.join_me_item) {
      setJoinMeMeeting(dataEvent.scheduleItem.join_me_item.schedule_item.join_me_item);
    }
  }, [dataEvent]);

  const findTypeOfEvent = (): string | undefined => {
    if (isThisACallback) {
      return "Call";
    }
    if (dataEvent?.scheduleItem?.lead_intent?.type === "Decision Call") {
      return "Decision Call";
    }
    if (dataEvent?.scheduleItem?.lead_intent?.type === "Demo") {
      return "Demo";
    }
  };

  const loading = loadingEvent || loadingProfileImage;
  const error = errorEvent || errorProfileImage;

  if (loadingEvent) return <Loading />;

  if (errorEvent) return <AppErrorText>{errorEvent?.message}</AppErrorText>;

  if (error) return <AppErrorText>Something went wrong.</AppErrorText>;

  const deleteEventSchema = Yup.object().shape({
    lead_id: Yup.string().required(),
    schedule_item_id: Yup.string().required(),
    updated_person_spoke_to: Yup.string().required(),
    updated_disposition_id: Yup.string().required(),
  });

  const profileImage = cloudinary
    .image(
      dataProfileImage?.fetchLeadAndOwnedRep?.rep?.profile_image
        ? dataProfileImage?.fetchLeadAndOwnedRep?.rep?.profile_image
        : DEFAULT_CLOUDINARY_IMAGE,
    )
    .resize(fill().width(40).height(40));

  return (
    <>
      {showSwapPrimaryLeadModal && (
        <>
          <DarkDiv />
          <SwapPrimaryLeadModal />
        </>
      )}

      {showDisassociateLeadModal && (
        <>
          <DarkDiv />
          <DisassociateLeadModal />
        </>
      )}
      <EventContainer>
        <LeftSidebar>
          {showAddOrEditContactModal ? (
            <SidebarContainer>
              <SidebarWrapper>
                <AddOrEditContactModal setShowAddContact={setShowAddOrEditContactModal} />
              </SidebarWrapper>
            </SidebarContainer>
          ) : showAddAssociateModal ? (
            <SidebarContainer>
              <SidebarWrapper>
                <AddAssociatedContact setShowAddAssociatedContact={setShowAddAssociateModal} />
              </SidebarWrapper>
            </SidebarContainer>
          ) : (
            <>
              <div
                style={{
                  marginTop: "11px",
                }}
              >
                {leadLoading ? (
                  <Loading />
                ) : leadError ? (
                  <AppErrorText>Error loading lead data</AppErrorText>
                ) : (
                  <LeadCardV2
                    border
                    borderRadius={"8px"}
                    hideActionButtons
                    parentType={"eventDetails"}
                    expandOnRender
                    leadData={leadData?.fetchLead}
                    // if the event intent is currently in the next dial we want to show the start meeting CTA
                    intent={eventIsInNextDial ? matchingNextDialEvent : undefined}
                  />
                )}
              </div>

              <AppSidebarCard showExpand title="Scheduled Events">
                {!!leadData?.fetchLead?.upcoming_events?.length ? (
                  leadData?.fetchLead?.upcoming_events?.map((item: any) => (
                    <>
                      <ScheduledEventDiv
                        onClick={() => {
                          const sanitizedURL = sanitizeURL(`/manage-schedule/${item.id}`);

                          history.push(sanitizedURL);
                        }}
                      >
                        <AppText>{item.action_label}</AppText>
                        <AppText>
                          {moment(item.start_time).format("MM/DD/YYYY")} &nbsp;{" "}
                          {moment(item.start_time).format("h:mm A")}
                        </AppText>
                      </ScheduledEventDiv>
                      <Divider />
                    </>
                  ))
                ) : (
                  <ScheduledEventDiv>
                    <AppText>No scheduled events</AppText>
                  </ScheduledEventDiv>
                )}
              </AppSidebarCard>
            </>
          )}
        </LeftSidebar>

        <Page>
          {editModal && (
            <Modal open={editModal} onClose={() => setEditModal(false)} closeButtonSize={16}>
              <UpdateLeadDataComponent
                customHeight
                visible={editModal}
                close={() => {
                  setEditModal(false);
                  setEditModalLeadId("");
                }}
                lead_id={editModalLeadId}
              />
            </Modal>
          )}
          {editEventModal && (
            <EditEventDetailsV2
              lead_id={eventEditData.lead_id}
              dataEvent={dataEvent}
              isThisACallback={isThisACallback}
              closeModal={() => setEditEventModal(false)}
            />
          )}
          {deleteEventModal && (
            <PanelModal open={deleteEventModal} onClose={() => setDeleteEventModal(false)}>
              <Formik
                validateOnMount
                enableReinitialize
                innerRef={formikRef}
                initialValues={{
                  // loaded from event and not editable
                  schedule_item_id: eventEditData?.id || deleteEventDefaultProps.schedule_item_id,
                  lead_id: eventEditData?.lead_id || deleteEventDefaultProps.lead_id,
                  // must be selected through modal flow.
                  updated_person_spoke_to: "",
                  updated_disposition_id: "",
                }}
                validationSchema={deleteEventSchema}
                onSubmit={async (values) => {
                  await deleteScheduledEvent({
                    variables: {
                      schedule_item_id: values.schedule_item_id,
                      lead_id: values.lead_id,
                      // person spoke to not needed as disposition_id is generated from person_spoke_to selection
                      disposition_id: values.updated_disposition_id,
                    },
                  });

                  setDeleteEventModal(false);
                }}
              >
                {({
                  submitForm,
                  isSubmitting,
                  values,
                  setFieldValue,
                  dirty,
                  isValid,
                  errors,
                  touched,
                }: FormikProps<deleteEventFormikProps>) => {
                  console.log("values", values);
                  console.log("errors", errors);

                  if (loading)
                    return (
                      <DeleteModal style={{ marginTop: 20 }}>
                        <SkeletonBlock height={"100%"} width={"100%"} borderRadius={6} />
                      </DeleteModal>
                    );

                  return (
                    <DeleteModal style={{ marginTop: 20 }}>
                      <AppText fontSize={20} fontWeight={600}>
                        Delete Event
                      </AppText>
                      <SelectDispo
                        lead_id={dataEvent?.scheduleItem?.lead_intent?.lead?.id ?? ""}
                        selectedEventType={"Delete Event"}
                        selectedPersonSpokeTo={values.updated_person_spoke_to}
                        setSelectedPersonSpokeTo={(person: string) => setFieldValue("updated_person_spoke_to", person)}
                        selectedDispositionId={values.updated_disposition_id}
                        setSelectedDispositionId={(id: string) => setFieldValue("updated_disposition_id", id)}
                        vertical
                      />

                      <ButtonDiv>
                        <PhoenixAppButton
                          variant={"danger-outline"}
                          buttonType="secondary"
                          onClick={() => {
                            setDeleteEventModal(false);
                          }}
                        >
                          Cancel
                        </PhoenixAppButton>
                        <PhoenixAppButton
                          variant="brand"
                          buttonType="secondary"
                          disabled={isSubmitting || loadingDeleteEvent || !isValid}
                          onClick={() => {
                            submitForm();
                          }}
                        >
                          Delete Event
                        </PhoenixAppButton>
                      </ButtonDiv>
                    </DeleteModal>
                  );
                }}
              </Formik>
            </PanelModal>
          )}
          {showCustomFieldsModal && (
            <Modal open={showCustomFieldsModal} onClose={() => setCustomFieldsModal(false)} closeButtonSize={16}>
              <EditCustomFieldsComponent
                showEditCustomFields={showCustomFieldsModal}
                closeCustomFieldsModal={closeCustomFieldsModal}
                fields={customFieldsData?.lead?.visible_custom_fields}
                lead_id={customFieldsData?.lead?.id}
                customHeight
              />
            </Modal>
          )}
          {showEmailEditorModal && <EditEmailModalV2 />}

          <>
            {!!dataEvent?.scheduleItem?.deleted_at ? (
              <>
                <HeaderRow>
                  <SectionHeader fontSize={20}>Deleted Event Details</SectionHeader>
                </HeaderRow>
                <Main>
                  <FlexDiv
                    justify="space-between"
                    align="center"
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <FlexDiv direction="column">
                      <LabelText fontSize={18} style={{ fontWeight: 600 }}>
                        {dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.what}
                      </LabelText>
                      <DetailText>
                        {!!dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.start_time &&
                        !!dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.timezone ? (
                          <>
                            {moment(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.start_time)
                              .tz(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.timezone)
                              .format("dddd, MMMM Do")}
                            &nbsp;
                            <span style={{ fontWeight: 500 }}>
                              {moment(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.start_time)
                                .tz(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.timezone)
                                .format("h:mm A")}{" "}
                              -&nbsp;
                              {moment(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.end_time)
                                .tz(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.timezone)
                                .format("h:mm A")}
                              &nbsp; (
                              {moment.tz(dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.timezone).format("z")}
                              )
                            </span>
                          </>
                        ) : (
                          "Time not specified"
                        )}
                      </DetailText>
                    </FlexDiv>
                  </FlexDiv>
                  <InfoDiv>
                    <AccountSettingsBorder>
                      <AdvancedImageStyle cldImg={profileImage} />
                    </AccountSettingsBorder>
                    <div>
                      <LabelText style={{ marginTop: "15px" }}>Owning Rep</LabelText>

                      <DetailText style={{ marginBottom: "25px" }}>{`${
                        dataHistoricalItem?.fetchHistoricalEvent?.user?.full_name ?? "N/A"
                      }`}</DetailText>
                    </div>
                  </InfoDiv>
                  <Divider />
                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={map_pin} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Event Location</LabelText>
                      <DetailText>{dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.where}</DetailText>
                      {/* for google meet events */}
                      {dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.where &&
                        dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.google_space_sellfire_id && (
                          <DetailText fontSize={10}>
                            This text is for reference. At the time of the event, please click the "Start Scheduled
                            Meeting" button on the lead card to initiate the meeting.
                          </DetailText>
                        )}
                    </div>
                  </InfoDiv>
                  <Divider />
                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={list} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Event Description</LabelText>
                      <DetailText>{dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.description}</DetailText>
                    </div>
                  </InfoDiv>
                  <Divider />

                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={users} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Guests</LabelText>
                      {!!dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.guests.length ? (
                        dataHistoricalItem?.fetchHistoricalEvent?.schedule_item?.guests?.map((item: any) => (
                          <DetailText>{item.email}</DetailText>
                        ))
                      ) : (
                        <DetailText>No guests added. </DetailText>
                      )}
                    </div>
                  </InfoDiv>
                </Main>
              </>
            ) : (
              <>
                <HeaderRow>
                  <SectionHeader fontSize={20}>Event Detail</SectionHeader>
                  <FlexDiv gap={8}>
                    <PhoenixAppButton
                      variant="danger-outline"
                      buttonType="secondary"
                      onClick={() => {
                        setDeleteEventModal(true);
                      }}
                    >
                      Delete
                    </PhoenixAppButton>
                    <PhoenixAppButton
                      variant="brand-outline"
                      buttonType="secondary"
                      onClick={() => setEditEventModal(true)}
                    >
                      Edit
                    </PhoenixAppButton>
                  </FlexDiv>
                </HeaderRow>
                <Main>
                  <FlexDiv
                    justify="space-between"
                    align="center"
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    <FlexDiv direction="column">
                      <LabelText fontSize={18} style={{ fontWeight: 600 }}>
                        {dataEvent?.scheduleItem?.what}
                      </LabelText>
                      <DetailText>
                        {!!dataEvent?.scheduleItem?.start_time && !!dataEvent?.scheduleItem?.timezone ? (
                          <>
                            {moment(dataEvent?.scheduleItem?.start_time)
                              .tz(dataEvent?.scheduleItem?.timezone)
                              .format("dddd, MMMM Do")}
                            &nbsp;
                            <span style={{ fontWeight: 500 }}>
                              {moment(dataEvent?.scheduleItem?.start_time)
                                .tz(dataEvent?.scheduleItem?.timezone)
                                .format("h:mm A")}{" "}
                              -&nbsp;
                              {moment(dataEvent?.scheduleItem?.end_time)
                                .tz(dataEvent?.scheduleItem?.timezone)

                                .format("h:mm A")}
                              &nbsp; ({moment.tz(dataEvent?.scheduleItem?.timezone).format("z")})
                            </span>
                          </>
                        ) : (
                          "Time not specified"
                        )}
                      </DetailText>
                    </FlexDiv>
                    {zoomIntegration && !!dataEvent?.scheduleItem?.zoom_meeting_sellfire_id && (
                      <FlexDiv align="center" gap={7}>
                        <img src={zoom_logo} height={32} />
                        <AppText fontSize={12} fontWeight={500}>
                          This event has Zoom enabled
                        </AppText>
                      </FlexDiv>
                    )}
                    {googleMeetIntegration && dataEvent?.scheduleItem?.google_space_sellfire_id && (
                      <FlexDiv align="center" gap={7}>
                        <img src={google_meet_logo} height={32} />
                        <AppText fontSize={12} fontWeight={500}>
                          This event has Google Meet enabled
                        </AppText>
                      </FlexDiv>
                    )}
                  </FlexDiv>
                  <InfoDiv>
                    <AccountSettingsBorder>
                      <AdvancedImageStyle cldImg={profileImage} />
                    </AccountSettingsBorder>
                    <div>
                      <LabelText style={{ marginTop: "15px" }}>Owning Rep</LabelText>

                      <DetailText style={{ marginBottom: "25px" }}>{`${
                        dataEvent?.scheduleItem?.lead_intent?.user?.full_name ?? "N/A"
                      }`}</DetailText>
                    </div>
                  </InfoDiv>
                  <Divider />
                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={map_pin} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Event Location</LabelText>
                      <DetailText>{dataEvent?.scheduleItem?.where}</DetailText>
                      {/* for google meet events */}
                      {dataEvent?.scheduleItem?.where && dataEvent?.scheduleItem?.google_space_sellfire_id && (
                        <AppText fontSize={8} color={theme.NEUTRAL400}>
                          This text is for reference. At the time of the event, please click the "Start Scheduled
                          Meeting" button on the lead card to initiate the meeting.
                        </AppText>
                      )}
                    </div>
                    {loggedInUser().joinMe_connected && (
                      <>
                        <LabelText>JoinMe URL</LabelText>
                        {!!joinMeMeeting?.id ? (
                          <>
                            <LinkHeader>Viewer Link</LinkHeader>
                            <DropdownOptionContainer>
                              <LinkGridContainer>
                                <LinkDiv>
                                  <Link>{joinMeMeeting.viewer_url ?? "No link available."}</Link>
                                </LinkDiv>
                                <RightText
                                  onClick={() => {
                                    navigator?.clipboard?.writeText(joinMeMeeting.viewer_url);
                                  }}
                                >
                                  Copy
                                </RightText>
                              </LinkGridContainer>
                            </DropdownOptionContainer>
                          </>
                        ) : loadingJoinMe ? (
                          <SkeletonBlock height={60} width={"100%"} borderRadius={6} />
                        ) : (
                          loggedInUser().joinMe_connected && (
                            <JoinMeButton
                              width={257}
                              fontsize={13}
                              onClick={() =>
                                createJoinMe({
                                  variables: {
                                    start: moment(dataEvent?.scheduleItem?.start_time)
                                      .tz(dataEvent?.scheduleItem?.timezone || "")
                                      .toDate(),
                                    end: moment(dataEvent?.scheduleItem?.end_time)
                                      .tz(dataEvent?.scheduleItem?.timezone || "")
                                      .toDate(),
                                  },
                                })
                              }
                            >
                              <>
                                <img src={iconDesktop} height={32} /> <p>Create JoinMe {findTypeOfEvent()} Link</p>
                              </>
                            </JoinMeButton>
                          )
                        )}
                      </>
                    )}
                  </InfoDiv>
                  <Divider />
                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={list} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Event Description</LabelText>
                      <DetailText>{dataEvent?.scheduleItem?.description}</DetailText>
                    </div>
                  </InfoDiv>
                  <Divider />

                  <InfoDiv>
                    <IconContainer>
                      <PhoenixIcon svg={users} size={24} variant="brand" />
                    </IconContainer>
                    <div>
                      <LabelText>Guests</LabelText>
                      {!!dataEvent?.scheduleItem.guests.length ? (
                        dataEvent.scheduleItem.guests?.map((item: any) => <DetailText>{item.email}</DetailText>)
                      ) : (
                        <DetailText>No guests added. </DetailText>
                      )}
                    </div>
                  </InfoDiv>
                </Main>
              </>
            )}
          </>
        </Page>
      </EventContainer>
    </>
  );
};

const Main = styled.div`
  padding: 40px;
`;

const Page = styled.div`
  border: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.WHITE_COLOR};
  border-radius: 8px;
  margin-top: 26px;
  margin-bottom: 20px;
  margin-right: 24px;
  overflow: auto;
`;

const SectionHeader = styled(AppText)`
  font-style: normal;
  font-weight: bold;
`;

const Link = styled.div`
  font-weight: 400;
  min-width: 0;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LinkDiv = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  justify-content: flex-end;
  min-width: 0;
`;

const RightText = styled(AppText)`
  justify-self: end;
  align-self: end;
  color: ${theme.PRIMARY500};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  opacity: 70%;
  text-decoration: none;
  border-bottom: none;
  :hover {
    opacity: 100%;
    cursor: pointer;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
  a {
    text-decoration: none;
    color: ${theme.PRIMARY500};
  }
`;

const SidebarContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
`;

const SidebarWrapper = styled.div`
  position: relative;

  border-right: 1px solid ${theme.surface.neutral.tertiary};
  padding-bottom: 100px;
`;
const LinkGridContainer = styled.div`
  display: grid;
  padding: 13px;
  grid-gap: 16px;
  width: 100%;
  max-width: 325px;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: 1fr;
  background-color: #f4f4f4;
  border-radius: 4px;
`;

const LinkHeader = styled.p`
  font-weight: 500;
  font-size: 10px;
  margin: 5px;
  color: #5e5e5e;
`;

const DropdownOptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 300px;
`;

const JoinMeButton = styled(AppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  margin: 0px;
  margin-bottom: 10px;
`;

const LabelText = styled(AppText)`
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const DetailText = styled(AppText)`
  font-style: normal;
  color: ${theme.NEUTRAL400};
  line-height: 15px;
  margin-bottom: 5px;
`;

const LeftSidebar = styled.div`
  overflow: auto;
  margin-top: 0px;
  height: 100%;
  background: ${theme.surface.neutral.tertiary};
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 64px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const EventContainer = styled.div`
  display: grid;
  background: ${theme.surface.neutral.tertiary};
  grid-template-columns: 480px 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  position: relative;
`;

const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  padding: 24px 40px;
  align-items: flex-end;
`;

const ScheduledEventDiv = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  cursor: pointer;
`;

const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
  align-items: center;
  height: max-content;
  overflow: auto;
  max-height: 70vh;
  padding: 16px;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-around;
  width: 100%;
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 30px;
  width: 36px;
  height: 36px;
  margin-bottom: 2px;
`;

const AccountSettingsBorder = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-top: 14px;
  margin: 14px 8px 0px 8px;
`;

const IconContainer = styled.div`
  height: 40px;
  width: 40px;
  background-color: transparent;
  color: ${theme.PRIMARY500};
  margin: 14px 8px 0px 8px;
  display: flex;
  justify-content: center;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 20px 0px;
`;

export { EventDetails };
