import React, { createContext, FunctionComponent, useState, useMemo, Dispatch, SetStateAction, useEffect } from "react";
import { getIntegrationLabel } from "../utils/format";
import { IIntegrations, SelectedContactType, SystemConfigNavShape } from "../types";
import { getLocalStorage } from "../utils/misc";
import { OPSIQFormsFormikProps } from "../Components/Pages/OPSIQForms/OPSIQFormDetail";

export const defaultCSVModal = {
  num_imported: undefined,
  num_updated: undefined,
  num_staged: undefined,
  upload_id: undefined,
  num_error: undefined,
  error_report_url: undefined,
  visible: false,
};
interface FieldsData {
  id: string;
  external_object: string;
  external_field: string;
  external_field_label: string;
  opsiq_object: string;
  opsiq_field: string;
  opsiq_field_label: string;
  opsiq_field_type: string;
  sync_rule: string;
  trigger_events: string[];
  mapping_type: string;
  template_id?: string;
}

interface IRelatedRecordModalData {
  id?: string;
  integration: IIntegrations;
}

type BulkActionType = "Reassign" | "Unassign" | "Favorite" | "CustomQueue" | undefined;

interface BulkMergeLeadsModalData {
  selected_lead_ids: string[];
}

interface BulkStatusChangeModalData {
  selected_lead_ids: string[];
  desired_status: "Resting" | "Retired" | "LongResting" | "Customer";
}

interface ISwapPrimaryLeadModalData {
  primary_lead_id: string;
  associate_lead_id: string;

  parentType?: string;
  associate_primary_phone_number?: string;
  rep_id?: string;
}

interface IUpdateFormModalData {
  id: string | undefined;
  name: string | undefined;
}

interface IDeleteFormModalData {
  id: string;
  deleteOpsiqForm: () => void;
}

interface ModalContextState {
  showCustomFieldsModal: boolean;
  setCustomFieldsModal: (showCustomFieldsModal: boolean) => void;
  openCustomFieldsModal: () => void;
  closeCustomFieldsModal: () => void;
  showJoinConferenceModal: boolean;
  openJoinConferenceModal: () => void;
  closeJoinConferenceModal: () => void;
  showEmailEditorModal: boolean;

  openEmailEditorModal: (params: {
    lead_id: string;
    full_name: string;
    email: string;
    email_thread_id?: string;
    subject?: string;
    intent: any;
    isManualEmailSequenceStep: boolean;
    sequenceStepId?: string;
    action_source?: string;
    html?: string;
  }) => void;
  closeEmailEditorModal: () => void;
  emailEditorLeadData: {
    lead_id: string;
    full_name: string;
    email: string;
    email_thread_id?: string;
    subject?: string;
    intent?: any;
    isManualEmailSequenceStep?: boolean;
    sequenceStepId?: string;
    action_source?: string;
    html?: string;
  };
  composeEmailData: { lead_id: string; html: string };
  setComposeEmailData: Dispatch<SetStateAction<{ lead_id: string; html: string }>>;
  showCallParticipantsModal: boolean;
  setShowCallParticipantsModal: React.Dispatch<React.SetStateAction<boolean>>;
  showJoinDetailsModal: boolean;
  setShowJoinDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
  showDialer: boolean;
  showConsentModal: boolean;
  setShowConsentModal: React.Dispatch<React.SetStateAction<boolean>>;
  selfSource: boolean;
  setSelfSource: React.Dispatch<React.SetStateAction<boolean>>;
  showDashboardViewModal: boolean;
  setShowDashboardViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateDashboardView: boolean;
  setUpdateDashboardView: React.Dispatch<React.SetStateAction<boolean>>;
  deleteDashboardView: { label: string; value: unknown } | null;
  setDeleteDashboardView: React.Dispatch<React.SetStateAction<{ label: string; value: unknown } | null>>;
  showDashboardShareViewModal: boolean;
  setShowDashboardShareViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDialer: () => void;
  closeDialer: () => void;
  toggleDialer: () => void;
  showSMSChat: boolean;
  openSMSChat: ({
    lead_id,
    phone_number,
    intent,
    action_source,
  }: {
    lead_id: string;
    phone_number: string;
    intent: any;
    action_source?: string;
  }) => void;
  closeSMSChat: () => void;
  SMSLead: {
    lead_id: string;
    phone_number: string;
    intent: any;
    action_source?: string;
  };
  csvModal: any;
  setCsvModal: Dispatch<SetStateAction<any>>;
  showForgotPasswordModal: boolean;
  setShowForgotPasswordModal: Dispatch<SetStateAction<boolean>>;
  skipLeadModal: boolean;
  setSkipLeadModal: Dispatch<SetStateAction<boolean>>;
  myLeadsColumnModal: boolean;
  setMyLeadsColumnModal: Dispatch<SetStateAction<boolean>>;
  releaseLeadModal: boolean;
  setReleaseLeadModal: Dispatch<SetStateAction<boolean>>;
  customFieldsData: any;
  setCustomFieldsData: any;
  voiceMessageModal: boolean;
  setVoiceMessageModal: Dispatch<SetStateAction<boolean>>;
  callNotesModal: boolean;
  setCallNotesModal: Dispatch<SetStateAction<boolean>>;
  showCallFeedbackModal: boolean;
  setShowCallFeedbackModal: Dispatch<SetStateAction<boolean>>;
  showTwilioFeedbackModal: boolean;
  setShowTwilioFeedbackModal: Dispatch<SetStateAction<boolean>>;
  deleteModal: boolean;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
  folderUpdateModal: boolean;
  setFolderUpdateModal: Dispatch<SetStateAction<boolean>>;
  shareFolderModal: boolean;
  setShareFolderModal: Dispatch<SetStateAction<boolean>>;
  saveCallToLibraryModal: boolean;
  setSaveCallToLibraryModal: Dispatch<SetStateAction<boolean>>;
  currentConferenceID: string;
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
  moveToAnotherFolderModal: boolean;
  setMoveToAnotherFolderModal: Dispatch<SetStateAction<boolean>>;
  showAddOrEditProductModal: boolean;
  setShowAddOrEditProductModal: Dispatch<SetStateAction<boolean>>;
  showManageDeleteProductModal: boolean;
  setShowManageDeleteProductModal: Dispatch<SetStateAction<boolean>>;
  showAddSalePageModal: boolean;
  setShowAddSalePageModal: Dispatch<SetStateAction<boolean>>;
  showDeleteSalePageModal: boolean;
  setShowDeleteSalePageModal: Dispatch<SetStateAction<boolean>>;
  deleteHiddenPage: string;
  setDeleteHiddenPage: Dispatch<SetStateAction<string>>;
  showChoosePageModal: boolean;
  setShowChoosePageModal: Dispatch<SetStateAction<boolean>>;
  showChoosePageModalState: "new" | "existing";
  setShowChoosePageModalState: Dispatch<SetStateAction<"new" | "existing">>;
  showDeleteFieldModal: boolean;
  setShowDeleteFieldModal: Dispatch<SetStateAction<boolean>>;
  editModal: boolean;
  setEditModal: Dispatch<SetStateAction<boolean>>;
  editModalLeadId: string;
  setEditModalLeadId: Dispatch<SetStateAction<string>>;
  showFiltersModal: boolean;
  setShowFiltersModal: Dispatch<SetStateAction<boolean>>;
  showFavoriteFiltersModal: boolean;
  setShowFavoriteFiltersModal: Dispatch<SetStateAction<boolean>>;
  showCustomQueueFiltersModal: boolean;
  setShowCustomQueueFiltersModal: Dispatch<SetStateAction<boolean>>;
  showRepFiltersModal: boolean;
  setShowRepFiltersModal: Dispatch<SetStateAction<boolean>>;
  showAddAssociateModal: boolean;
  setShowAddAssociateModal: Dispatch<SetStateAction<boolean>>;
  primaryLeadToAddAssociateTo: string;
  setPrimaryLeadToAddAssociateTo: Dispatch<SetStateAction<string>>;
  skipOrReleaseLeadModalData: {
    lead_id: string;
    isNextDial: boolean;
    intent: any;
  };

  setSkipOrReleaseLeadModalData: Dispatch<
    SetStateAction<{
      lead_id: string;
      isNextDial: boolean;
      intent: any;
    }>
  >;

  emailModal: boolean;
  setEmailModal: Dispatch<SetStateAction<boolean>>;

  reassignLeadModal: boolean;
  setReassignLeadModal: Dispatch<SetStateAction<boolean>>;

  selectedEmail: {
    from: string;
    date: string;
    time: string;
    subject: string;
    html: string;
    lead_id: string;
    lead_has_opted_out_of_email: boolean;
    name: string;
    email_thread_id?: string;
    channel: string;
    to: string[];
  };
  setSelectedEmail: Dispatch<
    SetStateAction<{
      from: string;
      date: string;
      time: string;
      subject: string;
      html: string;
      lead_id: string;
      lead_has_opted_out_of_email: boolean;
      name: string;
      email_thread_id?: string;
      channel: string;
      to: string[];
    }>
  >;

  selectedLeadToReassign: {
    lead_id: string;
    lead_id_list: string[];
    source_user_id: string;
  };
  setSelectedLeadToReassign: Dispatch<
    SetStateAction<{
      lead_id: string;
      lead_id_list: string[];
      source_user_id: string;
    }>
  >;

  leadSourceModalData: {
    name_modal_on: boolean;
    delete_modal_on: boolean;
    id: string;
    label: string;
  };
  setLeadSourceModalData: Dispatch<
    SetStateAction<{
      name_modal_on: boolean;
      delete_modal_on: boolean;
      id: string;
      label: string;
    }>
  >;

  industryModalData: {
    name_modal_on: boolean;
    delete_modal_on: boolean;
    id: string;
    label: string;
    sub_fields: string[];
  };
  setIndustryModalData: Dispatch<
    SetStateAction<{
      name_modal_on: boolean;
      delete_modal_on: boolean;
      id: string;
      label: string;
      sub_fields: string[];
    }>
  >;

  dispositionReasonModalData: {
    name_modal_on: boolean;
    delete_modal_on: boolean;
    id: string;
    label: string;
    disposition_type: "Not Interested" | "Not Qualified";
  };
  setDispositionReasonModalData: Dispatch<
    SetStateAction<{
      name_modal_on: boolean;
      delete_modal_on: boolean;
      id: string;
      label: string;
      disposition_type: "Not Interested" | "Not Qualified";
    }>
  >;
  customObjectModalData: {
    name_modal_on: boolean;
    delete_modal_on: boolean;
    id: string;
    label: string;
  };
  setCustomObjectModalData: Dispatch<
    SetStateAction<{
      name_modal_on: boolean;
      delete_modal_on: boolean;
      id: string;
      label: string;
    }>
  >;

  selectEventModal: boolean;
  setSelectEventModal: Dispatch<SetStateAction<boolean>>;

  generalTimesEventsModal: boolean;
  setGeneralTimesEventsModal: Dispatch<SetStateAction<boolean>>;

  // deprecated
  integrationErrorLogModal: boolean;
  setIntegrationErrorLogModal: Dispatch<SetStateAction<boolean>>;

  integrationReportingComponent: boolean;
  setIntegrationReportingComponent: Dispatch<SetStateAction<boolean>>;

  selectedIntegration: IIntegrations | undefined;
  setSelectedIntegration: Dispatch<SetStateAction<IIntegrations | undefined>>;

  selectedIntegrationLabel: string;

  convertedIntegrationName: string;
  setConvertedIntegrationName: Dispatch<SetStateAction<string>>;

  showIntegrationMapRulesModal: boolean;
  setShowIntegrationMapRulesModal: Dispatch<SetStateAction<boolean>>;

  integrationMapPicklistModal: boolean;
  setIntegrationMapPicklistModal: Dispatch<SetStateAction<boolean>>;

  mapStateData: FieldsData;
  setMapStateData: Dispatch<SetStateAction<FieldsData>>;
  resetMapStateData: () => void;

  isUnassignOn: BulkActionType;
  setIsUnassignOn: Dispatch<SetStateAction<BulkActionType>>;

  editIntegrationType: string;
  setEditIntegrationType: Dispatch<SetStateAction<string>>;

  templateId: string | undefined;
  setTemplateId: Dispatch<SetStateAction<string | undefined>>;

  skipReleaseLeadIntentId: string | undefined;
  setSkipReleaseLeadIntentId: Dispatch<SetStateAction<string | undefined>>;

  skipReleaseLeadReason: string | undefined;
  setSkipReleaseLeadReason: Dispatch<SetStateAction<string | undefined>>;

  skipReleaseLeadText: string | undefined;
  setSkipReleaseLeadText: Dispatch<SetStateAction<string | undefined>>;

  showTabNav: boolean;
  setShowTabNav: Dispatch<SetStateAction<boolean>>;

  tabNavData: SystemConfigNavShape[] | undefined;
  setTabNavData: Dispatch<SetStateAction<SystemConfigNavShape[] | undefined>>;

  isSystemConfigNavExpanded: boolean;
  setIsSystemConfigNavExpanded: Dispatch<SetStateAction<boolean>>;

  currentTab: string | undefined;
  setCurrentTab: Dispatch<SetStateAction<string | undefined>>;

  skipReleaseLeadModalOnConfirm: () => void;
  setSkipReleaseLeadModalOnConfirm: Dispatch<SetStateAction<() => void>>;

  pipelineCloseDateModal: boolean;
  setPipelineCloseDateModal: Dispatch<SetStateAction<boolean>>;

  pipelineMetricDefaultValueModal: boolean;
  setPipelineMetricDefaultValueModal: Dispatch<SetStateAction<boolean>>;

  showInboundConciergeModal: boolean;
  setShowInboundConciergeModal: Dispatch<SetStateAction<boolean>>;

  showUpdateFormModal: boolean;
  setShowUpdateFormModal: Dispatch<SetStateAction<boolean>>;

  formPreviewModalData: {
    values: OPSIQFormsFormikProps;
  };
  setFormPreviewModalData: Dispatch<
    SetStateAction<{
      values: OPSIQFormsFormikProps;
    }>
  >;

  updateFormModalData: IUpdateFormModalData;
  setUpdateFormModalData: Dispatch<SetStateAction<IUpdateFormModalData>>;

  showDeleteFormModal: boolean;
  setShowDeleteFormModal: Dispatch<SetStateAction<boolean>>;

  deleteFormModalData: IDeleteFormModalData;
  setDeleteFormModalData: Dispatch<SetStateAction<IDeleteFormModalData>>;

  inboundConciergeModalData: any;
  setInboundConciergeModalData: Dispatch<SetStateAction<any>>;

  showSalesTeamsManageColumnsModal: boolean;
  setShowSalesTeamsManageColumnsModal: Dispatch<SetStateAction<boolean>>;

  showSalesTeamsBulkEditModal: any;
  setShowSalesTeamsBulkEditModal: Dispatch<SetStateAction<any>>;

  showMergeContactsModal: any;
  setShowMergeContactsModal: Dispatch<SetStateAction<any>>;

  showMergeCOLeadModal: boolean;
  setShowMergeCOLeadModal: Dispatch<SetStateAction<any>>;

  showAddOrEditContactModal: boolean;
  setShowAddOrEditContactModal: Dispatch<SetStateAction<boolean>>;

  showSwapPrimaryLeadModal: boolean;
  setShowSwapPrimaryLeadModal: Dispatch<SetStateAction<boolean>>;

  swapPrimaryLeadModalData: ISwapPrimaryLeadModalData;
  setSwapPrimaryLeadModalData: Dispatch<SetStateAction<ISwapPrimaryLeadModalData>>;

  showMergeLeadsModal: any;
  setShowMergeLeadsModal: Dispatch<SetStateAction<any>>;

  mergeLeadsData: any;
  setMergeLeadsData: Dispatch<SetStateAction<any>>;

  selectedContactV2: SelectedContactType;
  setSelectedContactV2: Dispatch<SetStateAction<SelectedContactType>>;

  showDisassociateLeadModal: boolean;
  setShowDisassociateLeadModal: Dispatch<SetStateAction<boolean>>;

  showSMSAndEmailDetailsModal: boolean;
  setShowSMSAndEmailDetailsModal: Dispatch<SetStateAction<boolean>>;

  SMSAndEmailDetailsModalData: any;
  setSMSAndEmailDetailsModalData: Dispatch<SetStateAction<any>>;

  showDeleteSequenceModal: boolean;
  setShowDeleteSequenceModal: Dispatch<SetStateAction<boolean>>;

  showHideReportingSectionModal: boolean;
  setShowHideReportingSectionModal: Dispatch<SetStateAction<boolean>>;

  showEditSectionModal: boolean;
  setShowEditSectionModal: Dispatch<SetStateAction<boolean>>;

  showReportingViewModal: boolean;
  setShowReportingViewModal: Dispatch<SetStateAction<boolean>>;

  showRenameSequenceModal: boolean;
  setShowRenameSequenceModal: Dispatch<SetStateAction<boolean>>;

  renameSequenceData: any;
  setRenameSequenceData: Dispatch<SetStateAction<any>>;

  showSequenceStepModal: boolean;
  setShowSequenceStepModal: Dispatch<SetStateAction<boolean>>;

  showSequenceBranchModal: boolean;
  setShowSequenceBranchModal: Dispatch<SetStateAction<boolean>>;

  showSelectAnActionModal: boolean;
  setShowSelectAnActionModal: Dispatch<SetStateAction<boolean>>;

  showSelectASequenceModal: boolean;
  setShowSelectASequenceModal: Dispatch<SetStateAction<boolean>>;

  showSequenceReportingFilterModal: boolean;
  setShowSequenceReportingFilterModal: Dispatch<SetStateAction<boolean>>;

  sequenceStepData: any;
  setSequenceStepData: Dispatch<SetStateAction<any>>;

  showConfirmDeleteSequenceStep: boolean;
  setShowConfirmDeleteSequenceStep: Dispatch<SetStateAction<boolean>>;

  showEditEndSequenceModal: boolean;
  setShowEditEndSequenceModal: Dispatch<SetStateAction<boolean>>;

  showRepViewSequenceModal: {
    visible: boolean;
    sequence_id: string;
    sequence_step_id: string;
  };

  setShowRepViewSequenceModal: Dispatch<
    SetStateAction<{
      visible: boolean;
      sequence_id: string;
      sequence_step_id: string;
    }>
  >;

  sequenceNavExpanded: boolean;
  setSequenceNavExpanded: Dispatch<SetStateAction<boolean>>;

  selectedSequenceStepId: string;
  setSelectedSequenceStepId: Dispatch<SetStateAction<string>>;

  updateStepTrigger: boolean;
  setUpdateStepTrigger: Dispatch<SetStateAction<boolean>>;

  sequenceStepModalView: "metric" | "edit";
  setSequenceStepModalView: Dispatch<SetStateAction<"metric" | "edit">>;

  showDeleteProductModal: boolean;
  setShowDeleteProductModal: Dispatch<SetStateAction<boolean>>;

  showDisconnectIntegrationModal: boolean;
  setShowDisconnectIntegrationModal: Dispatch<SetStateAction<boolean>>;

  selectedIntegrationDisconnect: string;
  setSelectedIntegrationDisconnect: Dispatch<SetStateAction<string>>;

  showNoEventModal: boolean;
  setShowNoEventModal: Dispatch<SetStateAction<boolean>>;

  showDeleteNoEventModal: boolean;
  setShowDeleteNoEventModal: Dispatch<SetStateAction<boolean>>;

  showSetupRelatedRecordModal: boolean;
  setShowSetupRelatedRecordModal: Dispatch<SetStateAction<boolean>>;

  relatedRecordModalData: IRelatedRecordModalData;

  setRelatedRecordModalData: Dispatch<SetStateAction<IRelatedRecordModalData>>;

  noEventModalData: {
    event_id: string | undefined | null;
  };
  setNoEventModalData: Dispatch<
    SetStateAction<{
      event_id: string | undefined | null;
    }>
  >;

  selectedSale: {
    id?: string;
    sale_item_id?: string;
    lead_id?: string;
    product_update_modal_type: "add" | "edit";
    product_id?: string;
  };
  setSelectedSale: Dispatch<
    SetStateAction<{
      id?: string;
      sale_item_id?: string;
      lead_id?: string;
      product_update_modal_type: "add" | "edit";
      product_id?: string;
    }>
  >;

  resetSelectedSale: () => void;

  transferRuleModalData: {
    type: "Add" | "Edit";
    rule_id?: string;
    rule_type?: "Booking" | "Transfer";
  };
  setTransferRuleModalData: Dispatch<
    SetStateAction<{
      type: "Add" | "Edit";
      rule_id?: string;
      rule_type?: "Booking" | "Transfer";
    }>
  >;

  showTransferRuleModal: boolean;
  setShowTransferRuleModal: Dispatch<SetStateAction<boolean>>;

  showBulkMergeLeadsModal: boolean;
  setShowBulkMergeLeadsModal: Dispatch<SetStateAction<boolean>>;

  bulkMergeLeadsModalData: BulkMergeLeadsModalData;
  setBulkMergeLeadsModalData: Dispatch<SetStateAction<BulkMergeLeadsModalData>>;

  showBulkStatusChangeModal: boolean;
  setShowBulkStatusChangeModal: Dispatch<SetStateAction<boolean>>;

  bulkStatusChangeModalData: BulkStatusChangeModalData;
  setBulkStatusChangeModalData: Dispatch<SetStateAction<BulkStatusChangeModalData>>;

  showUpdateProductModal: boolean;
  setShowUpdateProductModal: Dispatch<SetStateAction<boolean>>;

  showUpdateTermsModal: boolean;
  setShowUpdateTermsModal: Dispatch<SetStateAction<boolean>>;

  emailTemplateModal: any;
  setEmailTemplateModal: Dispatch<SetStateAction<any>>;

  editEmailTemplateID: any;
  setEditEmailTemplateID: Dispatch<SetStateAction<string>>;

  showFormPreviewModal: boolean;
  setShowFormPreviewModal: Dispatch<SetStateAction<boolean>>;

  smsTemplateModal: any;
  setSMSTemplateModal: Dispatch<SetStateAction<any>>;

  editSMSTemplateID: any;
  setEditSMSTemplateID: Dispatch<SetStateAction<string>>;

  showDeleteTemplateModal: boolean;
  setShowDeleteTemplateModal: Dispatch<SetStateAction<boolean>>;

  deleteTemplateModalId: string;
  setDeleteTemplateModalId: Dispatch<SetStateAction<string>>;

  deleteTemplateModalType: "email" | "sms";

  showTemplatePreviewModal: boolean;
  setShowTemplatePreviewModal: Dispatch<SetStateAction<boolean>>;
  templatePreviewModalData: {
    leadId?: string;
    templateId?: string;
    templateType: string;
    noDarkDiv?: boolean;
  };
  setTemplatePreviewModalData: Dispatch<
    SetStateAction<{
      leadId?: string;
      templateId?: string;
      templateType: string;
      noDarkDiv?: boolean;
    }>
  >;

  showSequenceCriteriaModal: boolean;
  setShowSequenceCriteriaModal: Dispatch<SetStateAction<boolean>>;

  showClearCustomQueueModal: boolean;
  setShowClearCustomQueueModal: Dispatch<SetStateAction<boolean>>;

  showToggleIBCModal: boolean;
  setShowToggleIBCModal: Dispatch<SetStateAction<boolean>>;
  showSoundbiteModal: boolean;
  setShowSoundbiteModal: Dispatch<SetStateAction<boolean>>;
  showCoachingNotesModal: boolean;
  setShowCoachingNotesModal: Dispatch<SetStateAction<boolean>>;
  showDeleteCoachNoteModal: boolean;
  setShowDeleteCoachNoteModal: Dispatch<SetStateAction<boolean>>;
  coachingNoteFormattedTimestamp: { start: string; end: string };
  setCoachingNoteFormattedTimestamp: Dispatch<SetStateAction<{ start: string; end: string }>>;
  tempRegionId: string | null;
  setTempRegionId: Dispatch<SetStateAction<string | null>>;
  showJoinGoogleMeetModal: boolean;
  setShowJoinGoogleMeetModal: Dispatch<SetStateAction<boolean>>;
  joinGoogleMeetModalData: {
    dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
    meetingLink: string;
  };
  setJoinGoogleMeetModalData: Dispatch<
    SetStateAction<{
      dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
      meetingLink: string;
    }>
  >;
  joinZoomModalData: {
    dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
    meetingLink: string;
  };
  setJoinZoomModalData: Dispatch<
    SetStateAction<{
      dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
      meetingLink: string;
    }>
  >;

  showJoinZoomModal: boolean;
  setShowJoinZoomModal: Dispatch<SetStateAction<boolean>>;

  leadFilterSavedViewModalData: any;
  setLeadFilterSavedViewModalData: Dispatch<SetStateAction<any>>;
  showAddOrEditLeadFilterSavedViewModal: boolean;
  setShowAddOrEditLeadFilterSavedViewModal: Dispatch<SetStateAction<boolean>>;
  showDeleteLeadFilterSavedView: boolean;
  setShowDeleteLeadFilterSavedView: Dispatch<SetStateAction<boolean>>;
  showCloneLeadFilterSavedView: boolean;
  setShowCloneLeadFilterSavedView: Dispatch<SetStateAction<boolean>>;
  showNewDeployVersionBanner: boolean;
  setShowNewDeployVersionBanner: Dispatch<SetStateAction<boolean>>;
  showSetLeadFilterSavedViewAsDefaultModal: boolean;
  setShowSetLeadFilterSavedViewAsDefaultModal: Dispatch<SetStateAction<boolean>>;
  showAddOrEditLabelModal: boolean;
  setShowAddOrEditLabelModal: Dispatch<SetStateAction<boolean>>;
}

export const ModalContext = createContext<ModalContextState>({} as ModalContextState);

export const ModalProvider: FunctionComponent = ({ children }) => {
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const [showTabNav, setShowTabNav] = useState(false);
  const [tabNavData, setTabNavData] = useState<SystemConfigNavShape[] | undefined>(undefined);
  const [isSystemConfigNavExpanded, setIsSystemConfigNavExpanded] = useState<boolean>(
    getLocalStorage("systemConfigNavExpanded", false) || false,
  );

  //WYSIWYG Editor modal
  const [showEmailEditorModal, setEmailEditorModal] = useState(false);
  const [showBulkMergeLeadsModal, setShowBulkMergeLeadsModal] = useState(false);
  const [bulkMergeLeadsModalData, setBulkMergeLeadsModalData] = useState<BulkMergeLeadsModalData>({
    selected_lead_ids: [],
  });
  const [showBulkStatusChangeModal, setShowBulkStatusChangeModal] = useState(false);
  const [bulkStatusChangeModalData, setBulkStatusChangeModalData] = useState<BulkStatusChangeModalData>({
    selected_lead_ids: [],
    desired_status: "Resting",
  });
  const [showNewDeployVersionBanner, setShowNewDeployVersionBanner] = useState(false);
  const [showAddAssociateModal, setShowAddAssociateModal] = useState(false);
  const [showAddOrEditContactModal, setShowAddOrEditContactModal] = useState(false);
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [showUpdateTermsModal, setShowUpdateTermsModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showDisconnectIntegrationModal, setShowDisconnectIntegrationModal] = useState(false);
  const [selectedIntegrationDisconnect, setSelectedIntegrationDisconnect] = useState("");
  const [primaryLeadToAddAssociateTo, setPrimaryLeadToAddAssociateTo] = useState("");
  const [myLeadsColumnModal, setMyLeadsColumnModal] = useState(false);
  const [emailEditorLeadData, setEmailEditorLeadData] = useState({
    lead_id: "",
    full_name: "",
    email: "",
    email_thread_id: "",
    subject: "",
    intent: undefined,
    isManualEmailSequenceStep: false,
    sequenceStepId: "",
    action_source: undefined as string | undefined,
    html: "",
  });
  const [editIntegrationType, setEditIntegrationType] = useState("");
  const [templateId, setTemplateId] = useState<string | undefined>(undefined);
  const [selectedSale, setSelectedSale] = useState<{
    id?: string;
    sale_item_id?: string;
    lead_id?: string;
    product_update_modal_type: "add" | "edit";
    product_id?: string;
  }>({
    id: "",
    sale_item_id: "",
    lead_id: "",
    product_update_modal_type: "add",
    product_id: "",
  });

  const resetSelectedSale = () => {
    setSelectedSale({
      id: "",
      sale_item_id: "",
      lead_id: "",
      product_update_modal_type: "add",
      product_id: "",
    });
  };
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [deleteTemplateModalId, setDeleteTemplateModalId] = useState("");
  const [deleteTemplateModalType, setDeleteTemplateModalType] = useState<"email" | "sms">("email");

  const [showNoEventModal, setShowNoEventModal] = useState<boolean>(false);
  const [showDeleteNoEventModal, setShowDeleteNoEventModal] = useState<boolean>(false);
  const [noEventModalData, setNoEventModalData] = useState({
    event_id: null as string | undefined | null,
  });

  const [composeEmailData, setComposeEmailData] = useState<{ lead_id: string; html: string }>({
    lead_id: "",
    html: "",
  });

  const openEmailEditorModal = ({
    lead_id,
    full_name,
    email,
    email_thread_id = "",
    subject = "",
    intent = undefined,
    isManualEmailSequenceStep = false,
    sequenceStepId = "",
    action_source,
    html = "",
  }: {
    lead_id: string;
    full_name: string;
    email: string;
    email_thread_id?: string;
    subject?: string;
    intent?: any;
    isManualEmailSequenceStep?: boolean;
    sequenceStepId?: string;
    action_source?: string;
    html?: string;
  }) => {
    setEmailEditorLeadData({
      lead_id: lead_id,
      full_name: full_name,
      email: email,
      email_thread_id: email_thread_id,
      subject,
      intent,
      isManualEmailSequenceStep,
      sequenceStepId,
      action_source,
      html,
    });
    setEmailEditorModal(true);
  };
  const closeEmailEditorModal = () => setEmailEditorModal(false);
  //Custom fields modal
  const [showCustomFieldsModal, setCustomFieldsModal] = useState(false);

  // CSV Upload modal
  const [csvModal, setCsvModal] = useState(defaultCSVModal);

  // Forgot Password modal
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const openCustomFieldsModal = () => {
    console.log("open custom fields modal");
    setCustomFieldsModal(true);
  };

  useEffect(() => {
    console.log("custom fields modal: ", showCustomFieldsModal);
  }, [showCustomFieldsModal]);

  const closeCustomFieldsModal = () => setCustomFieldsModal(false);
  //Sets the the dialer status

  //Join Conference modal
  const [showJoinConferenceModal, setJoinConferenceModal] = useState(false);
  const openJoinConferenceModal = () => setJoinConferenceModal(true);
  const closeJoinConferenceModal = () => setJoinConferenceModal(false);

  // Dashboard View
  const [showDashboardViewModal, setShowDashboardViewModal] = useState<boolean>(false);
  const [updateDashboardView, setUpdateDashboardView] = useState<boolean>(false);
  const [deleteDashboardView, setDeleteDashboardView] = useState<{ label: string; value: unknown } | null>(null);
  const [showDashboardShareViewModal, setShowDashboardShareViewModal] = useState<boolean>(false);

  //Self Source Context
  const [selfSource, setSelfSource] = useState(false);

  //Manager Feedback Modal
  const [showCallFeedbackModal, setShowCallFeedbackModal] = useState(false);

  //Twilio Feedback Modal
  const [showTwilioFeedbackModal, setShowTwilioFeedbackModal] = useState(false);

  //Call Participants Modal
  const [showCallParticipantsModal, setShowCallParticipantsModal] = useState(false);

  //Join Details Modal
  const [showJoinDetailsModal, setShowJoinDetailsModal] = useState(false);

  // Inbound Concierge Modal
  const [showInboundConciergeModal, setShowInboundConciergeModal] = useState(false);

  // Create or Update OPSIQ Form Modal
  // Create or Update OPSIQ Form Modal
  const [showUpdateFormModal, setShowUpdateFormModal] = useState(false);

  const [updateFormModalData, setUpdateFormModalData] = useState<IUpdateFormModalData>({
    id: undefined,
    name: undefined,
  });

  // form preview modal
  const [showFormPreviewModal, setShowFormPreviewModal] = useState(false);

  const [formPreviewModalData, setFormPreviewModalData] = useState<{
    values: OPSIQFormsFormikProps;
  }>({
    values: {} as OPSIQFormsFormikProps,
  });

  // Delete OPSIQ Form Modal
  const [showDeleteFormModal, setShowDeleteFormModal] = useState(false);

  const [deleteFormModalData, setDeleteFormModalData] = useState<IDeleteFormModalData>({
    id: "",
    deleteOpsiqForm: () => {},
  });

  // Toggle IBC Modal
  const [showToggleIBCModal, setShowToggleIBCModal] = useState(false);

  // Modal to swap associate lead with primary lead

  const [showSwapPrimaryLeadModal, setShowSwapPrimaryLeadModal] = useState(false);

  // modal to disassociate associate lead from primary lead

  const [showDisassociateLeadModal, setShowDisassociateLeadModal] = useState(false);

  // modal for seeing full details on lead-history item's SMS and Emails

  const [showSMSAndEmailDetailsModal, setShowSMSAndEmailDetailsModal] = useState(false);

  const [SMSAndEmailDetailsModalData, setSMSAndEmailDetailsModalData] = useState({} as any);

  // Sequences
  const [showTransferRuleModal, setShowTransferRuleModal] = useState<boolean>(false);
  const [showDeleteSequenceModal, setShowDeleteSequenceModal] = useState(false);
  const [showRenameSequenceModal, setShowRenameSequenceModal] = useState(false);
  const [renameSequenceData, setRenameSequenceData] = useState({} as any);
  const [showSequenceStepModal, setShowSequenceStepModal] = useState(false);
  const [showSequenceBranchModal, setShowSequenceBranchModal] = useState(false);
  const [showSelectAnActionModal, setShowSelectAnActionModal] = useState(false);
  const [showSelectASequenceModal, setShowSelectASequenceModal] = useState(false);
  const [showSequenceReportingFilterModal, setShowSequenceReportingFilterModal] = useState(false);
  const [sequenceStepData, setSequenceStepData] = useState({} as any);
  const [showConfirmDeleteSequenceStep, setShowConfirmDeleteSequenceStep] = useState(false);
  const [showEditEndSequenceModal, setShowEditEndSequenceModal] = useState(false);
  const [showRepViewSequenceModal, setShowRepViewSequenceModal] = useState({
    visible: false,
    sequence_id: "",
    sequence_step_id: "",
  });
  const [sequenceNavExpanded, setSequenceNavExpanded] = useState<boolean>(false);
  const [selectedSequenceStepId, setSelectedSequenceStepId] = useState("false");
  const [updateStepTrigger, setUpdateStepTrigger] = useState<boolean>(false);
  const [sequenceStepModalView, setSequenceStepModalView] = useState<"metric" | "edit">("edit");
  const [showHideReportingSectionModal, setShowHideReportingSectionModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showReportingViewModal, setShowReportingViewModal] = useState(false);

  const [showJoinGoogleMeetModal, setShowJoinGoogleMeetModal] = useState(false);
  const [showJoinZoomModal, setShowJoinZoomModal] = useState(false);

  const [joinGoogleMeetModalData, setJoinGoogleMeetModalData] = useState<{
    dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
    meetingLink: string;
  }>({
    dialInToMeeting: () => {},
    meetingLink: "",
  });

  const [joinZoomModalData, setJoinZoomModalData] = useState<{
    dialInToMeeting: ({ newPhoneNumber, newPin }: { newPhoneNumber: string; newPin: string }) => void;
    meetingLink: string;
  }>({
    dialInToMeeting: () => {},
    meetingLink: "",
  });

  const [swapPrimaryLeadModalData, setSwapPrimaryLeadModalData] = useState<ISwapPrimaryLeadModalData>({
    primary_lead_id: "",
    associate_lead_id: "",
  });

  // Lead Filter Saved View Modals
  const [showAddOrEditLeadFilterSavedViewModal, setShowAddOrEditLeadFilterSavedViewModal] = useState(false);
  const [showDeleteLeadFilterSavedView, setShowDeleteLeadFilterSavedView] = useState(false);
  const [showCloneLeadFilterSavedView, setShowCloneLeadFilterSavedView] = useState(false);
  const [leadFilterSavedViewModalData, setLeadFilterSavedViewModalData] = useState<any>(null);
  const [showSetLeadFilterSavedViewAsDefaultModal, setShowSetLeadFilterSavedViewAsDefaultModal] = useState(false);

  //Dialer modal
  const [showDialer, setShowdialer] = useState(false);
  const openDialer = () => setShowdialer(true);
  const closeDialer = () => setShowdialer(false);
  const toggleDialer = () => setShowdialer(!showDialer);

  //Consent to Record modal
  const [showConsentModal, setShowConsentModal] = useState(false);

  //SMS modal
  const [showSMSChat, setShowSMSChat] = useState(false);
  const [SMSLead, setSMSLead] = useState({
    lead_id: "",
    phone_number: "",
    intent: undefined,
    action_source: undefined as string | undefined,
  });
  const openSMSChat = ({
    lead_id,
    phone_number,
    intent,
    action_source,
  }: {
    lead_id: string;
    phone_number: string;
    intent: undefined;
    action_source?: string;
  }) => {
    setShowSMSChat(true);

    console.log("openSMSChat", phone_number);

    setSMSLead({
      lead_id,
      phone_number,
      intent: intent,
      action_source: action_source,
    });
  };
  const closeSMSChat = () => {
    setShowSMSChat(false);
    setSMSLead({ lead_id: "", phone_number: "", intent: undefined, action_source: undefined });
  };

  const [skipLeadModal, setSkipLeadModal] = useState(false);
  const openSkipLeadModal = () => setSkipLeadModal(true);
  const closeSkipLeadModal = () => setSkipLeadModal(false);

  const [releaseLeadModal, setReleaseLeadModal] = useState(false);

  const [skipReleaseLeadIntentId, setSkipReleaseLeadIntentId] = useState<string | undefined>(undefined);
  const [skipReleaseLeadReason, setSkipReleaseLeadReason] = useState<string | undefined>(undefined);
  const [skipReleaseLeadText, setSkipReleaseLeadText] = useState<string | undefined>(undefined);

  // Custom fields data
  const [customFieldsData, setCustomFieldsData] = useState({
    lead: { id: "", visible_custom_fields: {} } as any,
  });

  // Custom voice message recording modal
  const [voiceMessageModal, setVoiceMessageModal] = useState(false);

  // Call notes add/edit modal
  const [callNotesModal, setCallNotesModal] = useState(false);

  // Call library modals
  const [deleteModal, setDeleteModal] = useState(false);
  const [folderUpdateModal, setFolderUpdateModal] = useState(false);
  const [shareFolderModal, setShareFolderModal] = useState(false);
  const [saveCallToLibraryModal, setSaveCallToLibraryModal] = useState(false);
  const [moveToAnotherFolderModal, setMoveToAnotherFolderModal] = useState(false);

  // Current conferecne ID to save to folder
  const [currentConferenceID, setCurrentConferenceID] = useState("");

  // Edit custom fields modal state
  const [editModal, setEditModal] = useState(false);

  // Manage Products page
  const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState<boolean>(false);
  const [showManageDeleteProductModal, setShowManageDeleteProductModal] = useState<boolean>(false);

  // Configure Make Sale page
  const [showAddSalePageModal, setShowAddSalePageModal] = useState<boolean>(false);
  const [showDeleteSalePageModal, setShowDeleteSalePageModal] = useState<boolean>(false);
  const [deleteHiddenPage, setDeleteHiddenPage] = useState<string>("");
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false);
  const [showChoosePageModal, setShowChoosePageModal] = useState<boolean>(false);
  const [showChoosePageModalState, setShowChoosePageModalState] = useState<"new" | "existing">("new");

  // this should be refactored to be shared with the above after associatedContacts flag is deprecated
  const [selectedContactV2, setSelectedContactV2] = useState<SelectedContactType>({
    id: "",
    lead_id: "",
    category: "",
    phone_number: "",
    local_phone_number: "",
    country_code: "",
    country: "",
    email: "",
    title: "",
    type: "",
    channel: "Phone",
    card_type: "",
    conference_number: "",
    has_conference_number: false,
  });

  // The lead id of lead in update lead modal
  const [editModalLeadId, setEditModalLeadId] = useState("");

  // transfer rule modal data
  const [transferRuleModalData, setTransferRuleModalData] = useState<{
    type: "Add" | "Edit";
    rule_id?: string;
    rule_type?: "Booking" | "Transfer";
  }>({
    type: "Add",
    rule_type: "Transfer",
    rule_id: "",
  });

  // Filters modal
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showFavoriteFiltersModal, setShowFavoriteFiltersModal] = useState(false);
  const [showCustomQueueFiltersModal, setShowCustomQueueFiltersModal] = useState(false);
  const [showRepFiltersModal, setShowRepFiltersModal] = useState(false);

  // Lead Detail Screen Modals
  const [emailModal, setEmailModal] = useState(false);
  const [reassignLeadModal, setReassignLeadModal] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState({
    from: "",
    date: "",
    time: "",
    subject: "",
    html: "",
    lead_id: "",
    lead_has_opted_out_of_email: false,
    name: "",
    to: [] as string[],
    channel: "",
  });

  const [selectedLeadToReassign, setSelectedLeadToReassign] = useState({
    lead_id: "" as string,
    lead_id_list: [] as string[],
    source_user_id: "",
  });

  const [leadSourceModalData, setLeadSourceModalData] = useState({
    name_modal_on: false,
    delete_modal_on: false,
    id: "",
    label: "",
  });

  const [industryModalData, setIndustryModalData] = useState({
    name_modal_on: false,
    delete_modal_on: false,
    id: "",
    label: "",
    sub_fields: [] as string[],
  });

  const [dispositionReasonModalData, setDispositionReasonModalData] = useState({
    name_modal_on: false,
    delete_modal_on: false,
    id: "",
    label: "",
    disposition_type: "Not Interested" as "Not Interested" | "Not Qualified",
  });

  const [customObjectModalData, setCustomObjectModalData] = useState({
    name_modal_on: false,
    delete_modal_on: false,
    id: "",
    label: "",
  });

  const [skipOrReleaseLeadModalData, setSkipOrReleaseLeadModalData] = useState({
    lead_id: "",
    isNextDial: false,
    intent: {},
  });

  const [showSetupRelatedRecordModal, setShowSetupRelatedRecordModal] = useState(false);
  const [relatedRecordModalData, setRelatedRecordModalData] = useState<IRelatedRecordModalData>({
    id: "",
    integration: IIntegrations.Salesforce,
  });

  // Select which scheduled event to call from overbooked events
  const [selectEventModal, setSelectEventModal] = useState(false);

  // General Events My Schedule modal
  const [generalTimesEventsModal, setGeneralTimesEventsModal] = useState(false);

  // deprecated
  const [integrationErrorLogModal, setIntegrationErrorLogModal] = useState(false);

  const [integrationReportingComponent, setIntegrationReportingComponent] = useState(false);

  const [selectedIntegration, setSelectedIntegration] = useState<IIntegrations | undefined>(IIntegrations.Salesforce);

  const selectedIntegrationLabel = getIntegrationLabel(selectedIntegration ?? "");

  const [convertedIntegrationName, setConvertedIntegrationName] = useState("");

  const [showIntegrationMapRulesModal, setShowIntegrationMapRulesModal] = useState(false);
  const [integrationMapPicklistModal, setIntegrationMapPicklistModal] = useState(false);

  // Pipeline Funnel Values Modals
  const [pipelineCloseDateModal, setPipelineCloseDateModal] = useState(false);
  const [pipelineMetricDefaultValueModal, setPipelineMetricDefaultValueModal] = useState(false);

  const DEFAULT_MAP_STATE_DATA = {
    id: "",
    external_object: "",
    external_field: "",
    external_field_label: "",
    opsiq_object: "Lead",
    opsiq_field: "",
    opsiq_field_label: "",
    opsiq_field_type: "",
    sync_rule: "",
    trigger_events: [] as string[],
    mapping_type: "",
  };

  const [mapStateData, setMapStateData] = useState(DEFAULT_MAP_STATE_DATA);
  const resetMapStateData = () => {
    setMapStateData(DEFAULT_MAP_STATE_DATA);
  };
  const [isUnassignOn, setIsUnassignOn] = useState<BulkActionType>(undefined);

  const [skipReleaseLeadModalOnConfirm, setSkipReleaseLeadModalOnConfirm] = useState<() => void>(() => {});

  // set with the ably channel inboundConcierge
  const [inboundConciergeModalData, setInboundConciergeModalData] = useState({
    business_name: "business_name",
    lead_name: "lead_name",
    phone_number: "lead_phone_number",
    // used in the gotToCall
    lead_id: "lead_id",
    // tells the FE how much time is left to call the lead *starts the timer from the this value*
    timer_start_time: "2021-01-01T00:00:00.000Z",
    // used in the modal for styling changes and to show the address
    existingLead: true,
    // only shown for existing leads
    address: "1234 test st",
    // to be passed into the cancel or call mutations to update the BE on the status of the inbound lead
    callMeNowId: "callMeNowId",
  });

  // Sales Teams 'List View' 'Manage Columns'
  const [showSalesTeamsManageColumnsModal, setShowSalesTeamsManageColumnsModal] = useState(false);

  // Sales Teams Bulk Edit
  const [showSalesTeamsBulkEditModal, setShowSalesTeamsBulkEditModal] = useState(null);

  // Merge Associated Contacts
  const [showMergeContactsModal, setShowMergeContactsModal] = useState(false);
  const [showMergeCOLeadModal, setShowMergeCOLeadModal] = useState(false);
  const [showMergeLeadsModal, setShowMergeLeadsModal] = useState(false);
  const [mergeLeadsData, setMergeLeadsData] = useState({});

  // Email and SMS templates
  const [emailTemplateModal, setEmailTemplateModal] = useState(false);
  const [editEmailTemplateID, setEditEmailTemplateID] = useState("");
  const [smsTemplateModal, setSMSTemplateModal] = useState(false);
  const [editSMSTemplateID, setEditSMSTemplateID] = useState("");

  const [showTemplatePreviewModal, setShowTemplatePreviewModal] = useState(false);
  const [templatePreviewModalData, setTemplatePreviewModalData] = useState({}) as any;

  const [showClearCustomQueueModal, setShowClearCustomQueueModal] = useState(false);

  // Sequences
  const [showSequenceCriteriaModal, setShowSequenceCriteriaModal] = useState(false);

  // coaching
  const [showSoundbiteModal, setShowSoundbiteModal] = useState(false);
  const [showCoachingNotesModal, setShowCoachingNotesModal] = useState(false);
  const [showDeleteCoachNoteModal, setShowDeleteCoachNoteModal] = useState(false);
  const [coachingNoteFormattedTimestamp, setCoachingNoteFormattedTimestamp] = useState({ start: "", end: "" });
  const [tempRegionId, setTempRegionId] = useState<string | null>(null);
  // Manage Labels page
  const [showAddOrEditLabelModal, setShowAddOrEditLabelModal] = useState<boolean>(false);

  const memoizedValue = useMemo(
    () => ({
      showCustomFieldsModal,
      setCustomFieldsModal,
      openCustomFieldsModal,
      closeCustomFieldsModal,
      showCallFeedbackModal,
      setShowCallFeedbackModal,
      showTwilioFeedbackModal,
      setShowTwilioFeedbackModal,
      showJoinConferenceModal,
      openJoinConferenceModal,
      closeJoinConferenceModal,
      showEmailEditorModal,
      openEmailEditorModal,
      composeEmailData,
      setComposeEmailData,
      closeEmailEditorModal,

      emailEditorLeadData,
      showCallParticipantsModal,
      setShowCallParticipantsModal,
      showJoinDetailsModal,
      setShowJoinDetailsModal,
      showDialer,
      showConsentModal,
      setShowConsentModal,
      csvModal,
      setCsvModal,

      openDialer,
      closeDialer,
      toggleDialer,
      showSMSChat,
      openSMSChat,
      closeSMSChat,
      SMSLead,
      showForgotPasswordModal,
      setShowForgotPasswordModal,
      selfSource,
      setSelfSource,
      showDashboardViewModal,
      setShowDashboardViewModal,
      updateDashboardView,
      setUpdateDashboardView,
      deleteDashboardView,
      setDeleteDashboardView,
      showDashboardShareViewModal,
      setShowDashboardShareViewModal,
      skipLeadModal,
      openSkipLeadModal,
      closeSkipLeadModal,
      setSkipLeadModal,
      customFieldsData,
      setCustomFieldsData,
      voiceMessageModal,
      setVoiceMessageModal,
      callNotesModal,
      setCallNotesModal,
      deleteModal,
      setDeleteModal,
      folderUpdateModal,
      setFolderUpdateModal,
      joinGoogleMeetModalData,
      setJoinGoogleMeetModalData,
      showJoinGoogleMeetModal,
      setShowJoinGoogleMeetModal,
      joinZoomModalData,
      setJoinZoomModalData,
      showJoinZoomModal,
      setShowJoinZoomModal,
      shareFolderModal,
      setShareFolderModal,
      saveCallToLibraryModal,
      setSaveCallToLibraryModal,
      currentConferenceID,
      setCurrentConferenceID,
      moveToAnotherFolderModal,
      setMoveToAnotherFolderModal,

      editModal,
      setEditModal,

      showAddOrEditProductModal,
      setShowAddOrEditProductModal,
      showManageDeleteProductModal,
      setShowManageDeleteProductModal,

      showAddSalePageModal,
      setShowAddSalePageModal,
      showDeleteSalePageModal,
      setShowDeleteSalePageModal,
      deleteHiddenPage,
      setDeleteHiddenPage,
      showChoosePageModal,
      setShowChoosePageModal,
      showChoosePageModalState,
      setShowChoosePageModalState,
      showDeleteFieldModal,
      setShowDeleteFieldModal,

      setEditModalLeadId,
      editModalLeadId,
      showFiltersModal,
      setShowFiltersModal,
      showRepFiltersModal,
      setShowRepFiltersModal,
      showFavoriteFiltersModal,
      setShowFavoriteFiltersModal,
      showCustomQueueFiltersModal,
      setShowCustomQueueFiltersModal,
      emailModal,
      setEmailModal,
      reassignLeadModal,
      setReassignLeadModal,
      selectedEmail,
      setSelectedEmail,
      selectedLeadToReassign,
      setSelectedLeadToReassign,
      leadSourceModalData,
      setLeadSourceModalData,
      industryModalData,
      setIndustryModalData,
      dispositionReasonModalData,
      setDispositionReasonModalData,
      customObjectModalData,
      setCustomObjectModalData,
      releaseLeadModal,
      setReleaseLeadModal,
      selectEventModal,
      setSelectEventModal,
      generalTimesEventsModal,
      setGeneralTimesEventsModal,
      // deprecated
      integrationErrorLogModal,
      setIntegrationErrorLogModal,
      integrationReportingComponent,
      setIntegrationReportingComponent,

      selectedIntegration,
      setSelectedIntegration,
      convertedIntegrationName,
      setConvertedIntegrationName,
      showIntegrationMapRulesModal,
      setShowIntegrationMapRulesModal,
      integrationMapPicklistModal,
      setIntegrationMapPicklistModal,
      mapStateData,
      setMapStateData,
      resetMapStateData,
      isUnassignOn,
      setIsUnassignOn,
      myLeadsColumnModal,
      setMyLeadsColumnModal,
      editIntegrationType,
      setEditIntegrationType,
      templateId,
      setTemplateId,
      skipReleaseLeadIntentId,
      setSkipReleaseLeadIntentId,
      skipReleaseLeadReason,
      setSkipReleaseLeadReason,

      skipReleaseLeadText,
      setSkipReleaseLeadText,
      showNewDeployVersionBanner,
      setShowNewDeployVersionBanner,
      showTabNav,
      setShowTabNav,
      tabNavData,
      setTabNavData,
      isSystemConfigNavExpanded,
      setIsSystemConfigNavExpanded,
      currentTab,
      setCurrentTab,
      skipReleaseLeadModalOnConfirm,
      setSkipReleaseLeadModalOnConfirm,
      skipOrReleaseLeadModalData,
      setSkipOrReleaseLeadModalData,
      pipelineCloseDateModal,
      setPipelineCloseDateModal,

      pipelineMetricDefaultValueModal,
      setPipelineMetricDefaultValueModal,
      selectedIntegrationLabel,
      showInboundConciergeModal,
      setShowInboundConciergeModal,

      showUpdateFormModal,
      setShowUpdateFormModal,
      showFormPreviewModal,
      setShowFormPreviewModal,
      formPreviewModalData,
      setFormPreviewModalData,
      showDeleteFormModal,
      setShowDeleteFormModal,
      deleteFormModalData,
      setDeleteFormModalData,
      updateFormModalData,
      setUpdateFormModalData,
      showSalesTeamsManageColumnsModal,
      setShowSalesTeamsManageColumnsModal,
      showSalesTeamsBulkEditModal,
      setShowSalesTeamsBulkEditModal,
      showMergeContactsModal,
      setShowMergeContactsModal,
      showMergeCOLeadModal,
      setShowMergeCOLeadModal,
      showMergeLeadsModal,
      setShowMergeLeadsModal,
      mergeLeadsData,
      setMergeLeadsData,

      inboundConciergeModalData,
      setInboundConciergeModalData,
      showAddAssociateModal,
      setShowAddAssociateModal,
      primaryLeadToAddAssociateTo,
      setPrimaryLeadToAddAssociateTo,

      showAddOrEditContactModal,
      setShowAddOrEditContactModal,

      showSwapPrimaryLeadModal,
      setShowSwapPrimaryLeadModal,
      swapPrimaryLeadModalData,
      setSwapPrimaryLeadModalData,
      selectedContactV2,
      setSelectedContactV2,
      showDisassociateLeadModal,
      setShowDisassociateLeadModal,
      showSMSAndEmailDetailsModal,
      setShowSMSAndEmailDetailsModal,
      SMSAndEmailDetailsModalData,
      setSMSAndEmailDetailsModalData,
      selectedSale,
      setSelectedSale,
      showUpdateProductModal,
      setShowUpdateProductModal,
      showUpdateTermsModal,
      setShowUpdateTermsModal,
      showDeleteProductModal,
      setShowDeleteProductModal,
      showDisconnectIntegrationModal,
      setShowDisconnectIntegrationModal,
      selectedIntegrationDisconnect,
      setSelectedIntegrationDisconnect,

      showDeleteSequenceModal,
      setShowDeleteSequenceModal,
      showHideReportingSectionModal,
      setShowHideReportingSectionModal,
      showEditSectionModal,
      setShowEditSectionModal,
      showReportingViewModal,
      setShowReportingViewModal,
      showRenameSequenceModal,
      setShowRenameSequenceModal,
      renameSequenceData,
      setRenameSequenceData,
      showSequenceStepModal,
      setShowSequenceStepModal,
      showSequenceBranchModal,
      setShowSequenceBranchModal,
      showSequenceCriteriaModal,
      setShowSequenceCriteriaModal,

      showSoundbiteModal,
      setShowSoundbiteModal,
      showCoachingNotesModal,
      setShowCoachingNotesModal,
      showDeleteCoachNoteModal,
      setShowDeleteCoachNoteModal,
      coachingNoteFormattedTimestamp,
      setCoachingNoteFormattedTimestamp,
      tempRegionId,
      setTempRegionId,

      showSelectAnActionModal,
      setShowSelectAnActionModal,
      showSelectASequenceModal,
      setShowSelectASequenceModal,
      showSequenceReportingFilterModal,
      setShowSequenceReportingFilterModal,
      sequenceStepData,
      setSequenceStepData,
      showConfirmDeleteSequenceStep,
      setShowConfirmDeleteSequenceStep,
      showEditEndSequenceModal,
      setShowEditEndSequenceModal,
      showRepViewSequenceModal,
      setShowRepViewSequenceModal,
      sequenceNavExpanded,

      setSequenceNavExpanded,
      selectedSequenceStepId,
      setSelectedSequenceStepId,
      updateStepTrigger,
      setUpdateStepTrigger,
      sequenceStepModalView,
      setSequenceStepModalView,

      emailTemplateModal,
      setEmailTemplateModal,
      editEmailTemplateID,
      setEditEmailTemplateID,
      smsTemplateModal,
      setSMSTemplateModal,
      editSMSTemplateID,
      setEditSMSTemplateID,

      showDeleteTemplateModal,
      setShowDeleteTemplateModal,
      deleteTemplateModalId,
      setDeleteTemplateModalId,
      deleteTemplateModalType,
      setDeleteTemplateModalType,
      showTemplatePreviewModal,
      setShowTemplatePreviewModal,
      templatePreviewModalData,
      setTemplatePreviewModalData,
      resetSelectedSale,
      transferRuleModalData,
      setTransferRuleModalData,
      showTransferRuleModal,
      setShowTransferRuleModal,
      showNoEventModal,
      setShowNoEventModal,
      showDeleteNoEventModal,
      setShowDeleteNoEventModal,
      noEventModalData,
      setNoEventModalData,
      showBulkMergeLeadsModal,
      setShowBulkMergeLeadsModal,
      bulkMergeLeadsModalData,
      setBulkMergeLeadsModalData,
      showBulkStatusChangeModal,
      setShowBulkStatusChangeModal,
      bulkStatusChangeModalData,
      setBulkStatusChangeModalData,
      showClearCustomQueueModal,
      setShowClearCustomQueueModal,
      showSetupRelatedRecordModal,
      setShowSetupRelatedRecordModal,
      relatedRecordModalData,
      setRelatedRecordModalData,
      showToggleIBCModal,
      setShowToggleIBCModal,

      showAddOrEditLeadFilterSavedViewModal,
      setShowAddOrEditLeadFilterSavedViewModal,
      leadFilterSavedViewModalData,
      setLeadFilterSavedViewModalData,
      showDeleteLeadFilterSavedView,
      setShowDeleteLeadFilterSavedView,
      showCloneLeadFilterSavedView,
      setShowCloneLeadFilterSavedView,
      showSetLeadFilterSavedViewAsDefaultModal,
      setShowSetLeadFilterSavedViewAsDefaultModal,
      showAddOrEditLabelModal,
      setShowAddOrEditLabelModal,
    }),
    [
      showNewDeployVersionBanner,
      setShowNewDeployVersionBanner,
      joinGoogleMeetModalData,
      setJoinGoogleMeetModalData,
      showJoinGoogleMeetModal,
      setShowJoinGoogleMeetModal,
      joinZoomModalData,
      setJoinZoomModalData,
      showJoinZoomModal,
      setShowJoinZoomModal,
      skipOrReleaseLeadModalData,
      setSkipOrReleaseLeadModalData,

      setCustomFieldsModal,

      showCustomFieldsModal,
      openCustomFieldsModal,
      showCallFeedbackModal,
      setShowCallFeedbackModal,
      showTwilioFeedbackModal,
      setShowTwilioFeedbackModal,
      closeCustomFieldsModal,
      showJoinConferenceModal,
      openJoinConferenceModal,
      closeJoinConferenceModal,
      showEmailEditorModal,
      openEmailEditorModal,
      composeEmailData,
      setComposeEmailData,
      closeEmailEditorModal,
      emailEditorLeadData,
      showCallParticipantsModal,
      setShowCallParticipantsModal,
      showJoinDetailsModal,
      setShowJoinDetailsModal,
      showDialer,
      showConsentModal,
      setShowConsentModal,
      openDialer,
      closeDialer,
      toggleDialer,
      showSMSChat,
      openSMSChat,
      closeSMSChat,
      SMSLead,
      showForgotPasswordModal,
      setShowForgotPasswordModal,
      selfSource,
      setSelfSource,
      showDashboardViewModal,
      setShowDashboardViewModal,
      updateDashboardView,
      setUpdateDashboardView,
      deleteDashboardView,
      setDeleteDashboardView,
      showDashboardShareViewModal,
      setShowDashboardShareViewModal,
      skipLeadModal,
      setSkipLeadModal,
      openSkipLeadModal,
      closeSkipLeadModal,
      customFieldsData,
      setCustomFieldsData,
      voiceMessageModal,
      setVoiceMessageModal,
      callNotesModal,
      setCallNotesModal,
      deleteModal,
      setDeleteModal,
      folderUpdateModal,
      setFolderUpdateModal,
      shareFolderModal,
      setShareFolderModal,

      saveCallToLibraryModal,
      setSaveCallToLibraryModal,
      currentConferenceID,
      setCurrentConferenceID,
      moveToAnotherFolderModal,
      setMoveToAnotherFolderModal,

      editModal,
      setEditModal,
      showAddOrEditProductModal,
      setShowAddOrEditProductModal,
      showManageDeleteProductModal,
      setShowManageDeleteProductModal,
      showAddSalePageModal,
      setShowAddSalePageModal,
      showDeleteSalePageModal,
      setShowDeleteSalePageModal,
      deleteHiddenPage,
      setDeleteHiddenPage,
      showChoosePageModal,
      setShowChoosePageModal,
      showChoosePageModalState,
      setShowChoosePageModalState,
      showDeleteFieldModal,
      setShowDeleteFieldModal,
      showBulkMergeLeadsModal,
      setShowBulkMergeLeadsModal,
      bulkMergeLeadsModalData,
      setBulkMergeLeadsModalData,
      showBulkStatusChangeModal,
      setShowBulkStatusChangeModal,
      bulkStatusChangeModalData,
      setBulkStatusChangeModalData,
      setEditModalLeadId,
      editModalLeadId,
      showFiltersModal,
      setShowFiltersModal,
      showFavoriteFiltersModal,
      setShowFavoriteFiltersModal,
      showCustomQueueFiltersModal,
      setShowCustomQueueFiltersModal,
      showRepFiltersModal,
      setShowRepFiltersModal,
      emailModal,
      setEmailModal,
      reassignLeadModal,
      setReassignLeadModal,
      selectedEmail,
      setSelectedEmail,
      selectedLeadToReassign,
      setSelectedLeadToReassign,
      leadSourceModalData,
      setLeadSourceModalData,
      industryModalData,
      setIndustryModalData,
      dispositionReasonModalData,
      setDispositionReasonModalData,
      releaseLeadModal,
      setReleaseLeadModal,
      selectEventModal,
      setSelectEventModal,
      generalTimesEventsModal,
      setGeneralTimesEventsModal,
      integrationErrorLogModal,
      setIntegrationErrorLogModal,
      integrationReportingComponent,
      setIntegrationReportingComponent,
      selectedIntegration,

      setSelectedIntegration,
      convertedIntegrationName,
      setConvertedIntegrationName,
      showIntegrationMapRulesModal,
      setShowIntegrationMapRulesModal,
      setIntegrationMapPicklistModal,
      mapStateData,
      setMapStateData,
      resetMapStateData,
      isUnassignOn,
      setIsUnassignOn,
      myLeadsColumnModal,
      setMyLeadsColumnModal,
      editIntegrationType,
      setEditIntegrationType,
      templateId,
      setTemplateId,
      skipReleaseLeadIntentId,
      setSkipReleaseLeadIntentId,
      skipReleaseLeadReason,
      setSkipReleaseLeadReason,
      skipReleaseLeadText,
      setSkipReleaseLeadText,
      showTabNav,
      setShowTabNav,
      tabNavData,
      setTabNavData,
      isSystemConfigNavExpanded,
      setIsSystemConfigNavExpanded,
      currentTab,
      setCurrentTab,
      skipReleaseLeadModalOnConfirm,
      setSkipReleaseLeadModalOnConfirm,
      pipelineCloseDateModal,
      setPipelineCloseDateModal,
      pipelineMetricDefaultValueModal,
      setPipelineMetricDefaultValueModal,
      selectedIntegrationLabel,
      showInboundConciergeModal,
      setShowInboundConciergeModal,
      showUpdateFormModal,
      setShowUpdateFormModal,
      showDeleteFormModal,
      setShowDeleteFormModal,
      deleteFormModalData,
      setDeleteFormModalData,
      updateFormModalData,
      setUpdateFormModalData,
      inboundConciergeModalData,
      setInboundConciergeModalData,
      showSalesTeamsManageColumnsModal,
      setShowSalesTeamsManageColumnsModal,
      showSalesTeamsBulkEditModal,
      setShowSalesTeamsBulkEditModal,

      showMergeContactsModal,
      setShowMergeContactsModal,
      showMergeCOLeadModal,
      setShowMergeCOLeadModal,
      showMergeLeadsModal,
      setShowMergeLeadsModal,
      mergeLeadsData,
      setMergeLeadsData,
      showAddAssociateModal,
      setShowAddAssociateModal,
      primaryLeadToAddAssociateTo,
      setPrimaryLeadToAddAssociateTo,
      showAddOrEditContactModal,
      setShowAddOrEditContactModal,
      showSwapPrimaryLeadModal,
      setShowSwapPrimaryLeadModal,
      swapPrimaryLeadModalData,
      setSwapPrimaryLeadModalData,
      selectedContactV2,
      setSelectedContactV2,
      showDisassociateLeadModal,
      setShowDisassociateLeadModal,
      showSMSAndEmailDetailsModal,
      setShowSMSAndEmailDetailsModal,
      SMSAndEmailDetailsModalData,
      setSMSAndEmailDetailsModalData,
      showDeleteSequenceModal,
      setShowDeleteSequenceModal,
      showHideReportingSectionModal,
      setShowHideReportingSectionModal,
      showEditSectionModal,
      setShowEditSectionModal,
      showReportingViewModal,
      setShowReportingViewModal,
      showRenameSequenceModal,
      setShowRenameSequenceModal,
      renameSequenceData,
      setRenameSequenceData,
      showSequenceStepModal,
      setShowSequenceStepModal,
      showSequenceBranchModal,
      setShowSequenceBranchModal,
      showSelectAnActionModal,
      setShowSelectAnActionModal,
      showSelectASequenceModal,
      setShowSelectASequenceModal,
      showSequenceReportingFilterModal,
      setShowSequenceReportingFilterModal,
      sequenceStepData,
      setSequenceStepData,
      showConfirmDeleteSequenceStep,
      setShowConfirmDeleteSequenceStep,
      showEditEndSequenceModal,
      setShowEditEndSequenceModal,
      showRepViewSequenceModal,
      setShowRepViewSequenceModal,
      sequenceNavExpanded,
      setSequenceNavExpanded,
      selectedSequenceStepId,
      setSelectedSequenceStepId,
      updateStepTrigger,
      setUpdateStepTrigger,
      sequenceStepModalView,
      setSequenceStepModalView,
      selectedSale,
      setSelectedSale,
      showUpdateProductModal,
      setShowUpdateProductModal,
      showUpdateTermsModal,
      setShowUpdateTermsModal,
      showDeleteProductModal,
      setShowDeleteProductModal,
      showDisconnectIntegrationModal,
      setShowDisconnectIntegrationModal,
      selectedIntegrationDisconnect,
      setSelectedIntegrationDisconnect,
      emailTemplateModal,
      setEmailTemplateModal,
      editEmailTemplateID,
      setEditEmailTemplateID,
      smsTemplateModal,
      setSMSTemplateModal,
      editSMSTemplateID,
      setEditSMSTemplateID,
      showDeleteTemplateModal,
      setShowDeleteTemplateModal,
      deleteTemplateModalId,
      setDeleteTemplateModalId,
      deleteTemplateModalType,
      setDeleteTemplateModalType,
      showTemplatePreviewModal,
      setShowTemplatePreviewModal,
      templatePreviewModalData,
      setTemplatePreviewModalData,
      showSequenceCriteriaModal,
      setShowSequenceCriteriaModal,
      showSoundbiteModal,
      setShowSoundbiteModal,
      showCoachingNotesModal,
      setShowCoachingNotesModal,
      showDeleteCoachNoteModal,
      setShowDeleteCoachNoteModal,
      coachingNoteFormattedTimestamp,
      setCoachingNoteFormattedTimestamp,
      tempRegionId,
      setTempRegionId,
      resetSelectedSale,
      transferRuleModalData,
      setTransferRuleModalData,
      showTransferRuleModal,
      setShowTransferRuleModal,
      showNoEventModal,
      setShowNoEventModal,
      showDeleteNoEventModal,
      setShowDeleteNoEventModal,
      noEventModalData,
      setNoEventModalData,
      showClearCustomQueueModal,
      setShowClearCustomQueueModal,
      showSetupRelatedRecordModal,
      setShowSetupRelatedRecordModal,
      relatedRecordModalData,
      setRelatedRecordModalData,
      showFormPreviewModal,
      setShowFormPreviewModal,
      formPreviewModalData,
      setFormPreviewModalData,
      showToggleIBCModal,
      setShowToggleIBCModal,
      showJoinGoogleMeetModal,
      setShowJoinGoogleMeetModal,
      joinGoogleMeetModalData,
      setJoinGoogleMeetModalData,

      showAddOrEditLeadFilterSavedViewModal,
      setShowAddOrEditLeadFilterSavedViewModal,
      leadFilterSavedViewModalData,
      setLeadFilterSavedViewModalData,
      showDeleteLeadFilterSavedView,
      setShowDeleteLeadFilterSavedView,
      showCloneLeadFilterSavedView,
      setShowCloneLeadFilterSavedView,
      showSetLeadFilterSavedViewAsDefaultModal,
      setShowSetLeadFilterSavedViewAsDefaultModal,
      showAddOrEditLabelModal,
      setShowAddOrEditLabelModal,
    ],
  );

  return <ModalContext.Provider value={memoizedValue}>{children}</ModalContext.Provider>;
};
