import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  EMAIL_UNSUBSCRIBED_REP_TOOLTIP,
  SMS_UNSUBSCRIBED_REP_TOOLTIP,
  camelToProperSplit,
  formatBusinessName,
  formatUnixDateTime,
  formatUnixImportDate,
} from "../../utils/format";
import { theme } from "../../utils/theme";
import { AppErrorText, AppSidebarCard, AppText, Loading, NewAppSelect, SkeletonBlock, StatCard } from "../UI";
import { FlexDiv } from "../UI/FlexDiv";
import { PhoenixMenuSelect } from "../UI/Phoenix";
import { getLocalStorage, sanitizeURL, setEnvDocTitle } from "./../../utils/misc";

import { BsThreeDotsVertical } from "react-icons/bs";
import { loggedInUser } from "../../apollo/cache";
import { LeadCardContext } from "../../context";
import { iconPlayNew } from "../../images";
import {
  addNewUser,
  arrowsLeftAndRight,
  arrowsUpAndDown,
  associated_white,
  chat,
  contact_info_add,
  flame,
  mail,
  merge_primary,
  phone_primary,
  user_white,
} from "../../images/NewDesign";
import { appToast, errorToast } from "../../utils/toast";
import { LeadCardV2 } from "../Segments/DashboardSideBarSegments/LeadCardV2";
import { NewAppButton } from "../UI/NewAppButton";
import {
  AddAssociatedContact,
  AddOrEditContactModal,
  DisassociateLeadModal,
  EditCustomFieldsComponent,
  FullEmailModal,
  MergeContactsModal,
  Modal,
  ReassignLeadModalComponent,
  SwapPrimaryLeadModal,
  UpdateLeadDataComponent,
} from "../modal";

import { useFlags } from "launchdarkly-react-client-sdk";
import { FiChevronDown, FiChevronUp, FiEdit } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import { restAPI } from "../../apollo";
import { CallContext, LeadFilterContext, ModalContext } from "../../context";
import { SelectedContactType } from "../../types";
import { veryLargeDesktopSize } from "../../utils/breakpoints";
import {
  CommunicationStates,
  formatCallDuration,
  formatDateTime,
  formatImportDate,
  formatPhoneNumber,
} from "../../utils/format";
import { displaySanitizedHTML } from "../../utils/misc";
import { APP_ENABLEMENT_ROLES, APP_ROLES, BACKEND_URL } from "../../utils/variables";

import { AppTable, AppTableItems, TableRow, TableTD } from "../UI/AppTable";
import { PhoenixAppButton, PhoenixIcon } from "../UI/Phoenix";
import { CORE_LEAD_CARD_DATA } from "./../../apollo/fragments";
import { MixpanelActions } from "./../../services/mixpanel";
import { CommunicationsActivityLeadDetail } from "./LeadDetail";
import Step7Container from "./LeadDetail/Step7Container";

import * as DOMPurify from "dompurify";
import { useTabFocus } from "../../utils/hooks";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import RelatedRecordsCard from "./LeadDetail/CustomObjectRelatedRecord";
import RelatedRecordCardData from "./LeadDetail/CustomObjectRelatedRecordData";
import { SMSMessageActivity } from "./LeadDetail/SMSMessageActivity";
import { SMSMessageActivityV2 } from "./LeadDetail/SMSMessageActivityV2";
import { configureAbly, useChannel } from "@ably-labs/react-hooks";
import { ABLY_OPTIONS } from "src/services/ably";
import { CallResultPill, DispositionReasonPill } from "../UI/Pills";
import { StyledTooltip } from "../Dumb";
import { isUndefined } from "lodash";

const FETCH_LEAD = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLead($id: String!) {
    fetchLead(id: $id) {
      ...CoreLeadCardData
      id
      rep {
        id
        first_name
        last_name
      }
      organization {
        double_booking
      }
      lead_intent {
        id
        type
      }
      address
      associate_parent_id
      sms_sub_status
      email_sub_status
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
    }
  }
`;

const FETCH_CALL_NOTES = gql`
  query fetchLeadCallNoteHistory($lead_id: String!, $show_all_lead: Boolean!) {
    fetchLeadCallNoteHistory(lead_id: $lead_id, show_all_lead: $show_all_lead) {
      id
      notes
      html
      created_at
      user {
        id
        full_name
      }
    }
  }
`;

const FETCH_UPCOMING_EVENTS = gql`
  query fetchScheduledItems($lead_id: String!, $show_all_lead: Boolean) {
    fetchScheduledItems(lead_id: $lead_id, show_all_lead: $show_all_lead) {
      id
      start_time
      action_label
      Lead {
        id
        associate_parent_id
      }
    }
  }
`;

const FETCH_ASSOCIATE_PARENT = gql`
  # this fetch exists so we dont need to refetch the entire lead onComplete of certain mutations
  query fetchAssociateParent($id: String!) {
    fetchLead(id: $id) {
      id
      associate_parent_id
    }
  }
`;

const FETCH_OWNERSHIP_HISTORY = gql`
  query fetchOwnershipHistory($lead_id: String!, $show_all_lead: Boolean) {
    fetchLeadAssignmentHistory(lead_id: $lead_id, show_all_lead: $show_all_lead) {
      id
      sender_user {
        id
        full_name
      }
      receiver_user {
        id
        full_name
      }
      created_at
      lead_assignment_origin_label
      custom_assignment_origin
      custom_who_initiated
    }
  }
`;

export const FETCH_CUSTOM_OBJECT_SUMMARY = gql`
  query fetchAssociatedCustomObjects($lead_id: String!) {
    fetchAssociatedCustomObjects(lead_id: $lead_id) {
      id
      name
      association_count
    }
  }
`;
interface expectedSMSItemResponse {
  id?: string;
  SMSItem?: {
    id?: string;
    sent_at?: string;
    text?: string;
    isFromRep?: boolean;
    sender_label?: string;
  };
  type?: string;
  computed_title?: string;
  created_at?: string;
  user?: {
    id?: string;
    first_name?: string;
    last_name?: string;
    profile_image?: string;
  };
  lead?: {
    id?: string;
    first_name?: string;
    last_name?: string;
  };
}

interface SMSListItem {
  id?: string;
  sent_at?: string;
  text?: string;
  isFromRep?: boolean;
  sender_label?: string;
  created_at?: string;
  type?: string;
  computed_title?: string;
  lead_number_used?: string;
  lead_activity?: {
    id?: string;
  };
  user?: {
    id?: string;
    first_name?: string;
    last_name?: string;
    profile_image?: string;
  };
  lead?: {
    id?: string;
    first_name?: string;
    last_name?: string;
  };
}

const FETCH_SMS_ITEMS = gql`
  query fetchSMSItems(
    $lead_id: String!
    $event_filter: HistoryEventTypeOption
    $show_all_lead: Boolean
    $skip: Int!
    $take: Int!
  ) {
    fetchLeadHistory(
      lead_id: $lead_id
      event_filter: $event_filter
      show_all_lead: $show_all_lead
      take: $take
      skip: $skip
    ) {
      id
      user {
        id
        first_name
        last_name
        profile_image
        phone_number
      }
      lead {
        id
        first_name
        last_name
        rep_id
      }
      SMSItem {
        id
        sent_at
        text
        isFromRep
        sender_label
        lead_number_used
        lead_activity {
          id
        }
        sequence_name
      }
      type
      computed_title
      created_at
    }
  }
`;

interface expectedEmailItemResponse {
  id?: string;
  email_item?: {
    id?: string;
    from_email?: string;
    to_email?: string;
    subject?: string;
    channel?: string;
    sender_label?: string;
    body?: string;
    html?: string;
    sent_at?: string;
    opened_at?: string;
    clicked_at?: string;
  };
  type?: string;
  computed_title?: string;
  created_at?: string;
}

interface EmailListItem {
  id?: string;
  from_email?: string;
  to_email?: string;
  subject?: string;
  channel?: string;
  sender_label?: string;
  body?: string;
  html?: string;
  sent_at?: string;
  opened_at?: string;
  clicked_at?: string;
  type?: string;
  computed_title?: string;
  created_at?: string;
}

const FETCH_EMAIL_ITEMS = gql`
  query fetchLeadEmails(
    $lead_id: String!
    $event_filter: HistoryEventTypeOption
    $show_all_lead: Boolean
    $skip: Int!
    $take: Int!
  ) {
    fetchLeadHistory(
      lead_id: $lead_id
      event_filter: $event_filter
      show_all_lead: $show_all_lead
      take: $take
      skip: $skip
    ) {
      id
      email_item {
        id
        from_email
        to_email
        subject
        channel
        sender_label
        body
        html
        sent_at
        opened_at
        clicked_at
        email_thread_id
      }
      type
      computed_title
      created_at
    }
  }
`;

const FETCH_RECENT_EMAIL_SYNC_RECORDS = gql`
  query fetchRecentEmailSyncRecords($lead_id: String!) {
    fetchRecentEmailSyncRecords(lead_id: $lead_id) {
      id
      lead_id
      lead {
        id
        full_name
      }
      user_id
      created_at
    }
  }
`;

const FETCH_LEAD_SALE_MADE = gql`
  query fetchAllSales($lead_id: String!, $associate_lead_ids: [String!]!) {
    fetchAllSales(lead_id: $lead_id, associate_lead_ids: $associate_lead_ids) {
      id
      products
    }
  }
`;

const FETCH_LEAD_CALL_HISTORY = gql`
  query fetchLeadCallHistory(
    $lead_id: String!
    $show_all_lead: Boolean!
    $event_filter: HistoryEventTypeOption
    $skip: Int!
    $take: Int!
  ) {
    fetchLeadHistory(
      lead_id: $lead_id
      show_all_lead: $show_all_lead
      event_filter: $event_filter
      take: $take
      skip: $skip
    ) {
      id
      is_last_activity
      created_at
      communication_type
      title
      call_end_reason
      conference {
        id
        start_time
        duration
        recording_url_computed
      }
      related_disposition {
        id
        label
      }
      related_disposition_reason
      call_completed_with
      lead {
        id
        first_name
        last_name
        full_name
        associate_parent_id
      }
      user {
        id
        full_name
        role
      }
      schedule_item {
        id
        start_time
      }
      stat_item_aggregation {
        id
        set
        disregarded_set
        hold
        disregarded_hold
        sale
        otf
        dm_connect
        ndm_connect
        initial_set
        initial_hold
        rescheduled_set
        rescheduled_hold
        closing_opp_scheduled
        closing_opp_held
        followup_hold
      }
      associate_child {
        id
        first_name
        last_name
        full_name
        associate_parent_id
      }
    }
  }
`;

const FETCH_ORGANIZATION = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      Reps {
        id
        full_name
      }
    }
  }
`;

const FETCH_DISPOSITION_OPTIONS = gql`
  query GetDispositionChangeOptions($lead_activity_id: String!) {
    getDispositionChangeOptions(lead_activity_id: $lead_activity_id) {
      disposition_id
      label
    }
  }
`;

const FETCH_ASSOCIATED_LEADS = gql`
  query FetchAssociateContact($primaryLeadId: String!) {
    fetchAssociateContact(primary_lead_id: $primaryLeadId) {
      id
      first_name
      last_name
      full_name
      conference_number
      primary_phone_number
      local_primary_phone_number
      primary_phone_number_country_code
      primary_phone_number_title
      primary_phone_number_type
      primary_email
      primary_email_title
      primary_email_type
      lead_ownership_status
      rep_id
      country
      alternate_contacts {
        id
        title
        channel
        type
        value
      }
      next_intent_scheduled_or_unscheduled {
        id
        event_type_label
        dial_num_in_sales_cycle
        anytime_before #general time
        anytime_after
        anytime_day
        anytime_day_upperbound
        anytime_tz
        general_time_start_date
        general_time_end_date
        current_sequence_step
        schedule_item {
          #specific time
          id
          start_time
          end_time
        }
        replacement_lead_intent_id
      }
    }
  }
`;

const FETCH_ASSOCIATION_HISTORY = gql`
  query fetchAssociateContactHistory($lead_id: String!) {
    fetchAssociateContactHistory(lead_id: $lead_id) {
      created_at
      action
      associate_lead {
        business_name
        full_name
      }
      rep {
        full_name
      }
    }
  }
`;

const FETCH_ASSOCIATE_STATS = gql`
  query fetchCurrentAssociateStats($lead_id: String!) {
    fetchCurrentAssociateStats(lead_id: $lead_id)
  }
`;

const UPDATE_DISPOSITION_TYPE = gql`
  mutation ChangeDispositionOption($disposition_id: String!, $lead_activity_id: String!) {
    changeDispositionOption(disposition_id: $disposition_id, lead_activity_id: $lead_activity_id) {
      id
      published
      channel
      conference_id
      title
      type
    }
  }
`;

const CHANGE_DISPOSITION_REP = gql`
  mutation ChangeDispositionRep($new_rep_id: String!, $lead_activity_id: String!) {
    changeDispositionRep(new_rep_id: $new_rep_id, lead_activity_id: $lead_activity_id) {
      id
      published
      channel
      notes
      conference_id
      title
      type
    }
  }
`;

const REMOVE_CALL_RESULT = gql`
  mutation removeCallResult($lead_activity_id: String!) {
    removeCallResult(lead_activity_id: $lead_activity_id) {
      id
      published
      channel
      notes
      conference_id
      title
      type
    }
  }
`;

const SWAP_PRIMARY_NUMBER = gql`
  mutation swapPrimaryNumberOnLead($contact_id: String!) {
    swapPrimaryNumberOnLead(contact_id: $contact_id) {
      id
    }
  }
`;

const FETCH_RECOMMENDED_ACTION_V2 = gql`
  query fetchFilteredLeadsInQueueV2($skip: Int, $take: Int, $lead_filter: LeadFilter, $sort_option: DialSortOption) {
    fetchFilteredLeadsInQueueV2(skip: $skip, take: $take, lead_filter: $lead_filter, sort_option: $sort_option) {
      id
      lead_id
      type
      lead {
        id
        sequence_id
        sequence_step {
          actions {
            id
            task
            custom_task_note
            scheduled_event_behavior
          }
        }
        organization {
          id
          name
          allow_remove_step_action
          allow_snooze_step_action
        }
        favorited
        primary_phone_number
        current_lead_type
        city
        state
        country
        timezone_by_state
        lead_source
        industry
        sub_industry
        business_name
        full_name
        first_name
        last_name
        lead_ownership_status
        rep_id
        computed_mrr
        current_close_date
      }
    }
  }
`;
interface ParamTypes {
  lead_id: string;
}

interface LocationProps {
  email_id?: string;
}

interface editCallHistoryModalItem {
  type: "Rep" | "Call Result";
  lead_activity_id: string;
  new_rep_id: string;
  disposition_id: string;
}

const SaleMadeComponent = (props: { lead_title: string; lead_id: string; associate_lead_ids: string }) => {
  const { lead_title, lead_id, associate_lead_ids } = props;

  const { data: listDataSale, loading } = useQuery(FETCH_LEAD_SALE_MADE, {
    variables: {
      lead_id: lead_id,
      associate_lead_ids: associate_lead_ids,
    },
    fetchPolicy: "cache-and-network",
  });

  const leadSaleList = listDataSale?.fetchAllSales;
  const leadIsClosed = !!leadSaleList?.length;
  // const repWhoClosed = leadIsClosed ? leadSaleList[0]?.user : undefined;

  return (
    <FlexDiv direction="column" gap={4}>
      <FlexDiv gap={8} align="center">
        <AppText fontSize={20} fontWeight={600}>
          {lead_title}
        </AppText>
        {!loading && leadIsClosed && (
          <KeyEventDiv
            style={{
              backgroundColor: theme.SUCCESS500,
            }}
            key={`sale-sale`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              SALE CLOSED
            </AppText>
          </KeyEventDiv>
        )}
      </FlexDiv>
      {!loading && leadIsClosed && (
        <AppText fontSize={12} style={{ color: theme.PILL_GRAY }}>
          AE closed a sale with the lead
        </AppText>
      )}
    </FlexDiv>
  );
};

const LeadDetailV3: React.FC = () => {
  const { gmailSync, adminModifySale, leadIntegrationRelatedRecords } = useFlags();

  const { lead_id } = useParams<ParamTypes>();
  const [leadNotFound, setLeadNotFound] = useState(false);

  const [step, setStep] = useState(1);
  const [showLastActivityOptions, setShowLastActivityOptions] = useState(false);
  const [showRemoveCallResultModal, setShowRemoveCallResultModal] = useState(false);
  const [editCallHistoryModalItem, setEditCallHistoryModalItem] = useState<editCallHistoryModalItem>({
    type: "Rep",
    lead_activity_id: "",
    new_rep_id: "",
    disposition_id: "",
  });
  const [editCallHistoryModal, setEditCallHistoryModal] = useState(false);
  const [lastActivityId, setLastActivityId] = useState("");
  const {
    emailModal,
    setEmailModal,
    setSelectedEmail,
    showCustomFieldsModal,
    setCustomFieldsModal,
    closeCustomFieldsModal,

    customFieldsData,
    setCustomFieldsData,
    selectedContactV2,
    editModal,
    setEditModal,
    setEditModalLeadId,
    editModalLeadId,
    reassignLeadModal,
    setReassignLeadModal,
    selectedLeadToReassign,
    setSelectedLeadToReassign,
    showAddAssociateModal,
    setShowAddAssociateModal,
    setShowMergeContactsModal,
    showMergeContactsModal,
    setMergeLeadsData,
    setPrimaryLeadToAddAssociateTo,
    showAddOrEditContactModal,
    setShowAddOrEditContactModal,
    showDisassociateLeadModal,
    showSwapPrimaryLeadModal,
    showUpdateProductModal,
    setShowUpdateProductModal,
    showUpdateTermsModal,
    setShowUpdateTermsModal,
    selectedSale,
    setSelectedSale,
  } = useContext(ModalContext);

  const { leadFilter } = useContext(LeadFilterContext);

  const { setSuggestedActionData } = useContext(CallContext);

  const history = useHistory();
  const location = useLocation<LocationProps>();

  const [filterByAssociateContact, setFilterByAssociateContact] = useState("All");

  // infinite scroll skip/take vars
  const takeNumber = 15;

  const [callSkip, setCallSkip] = useState(0);
  const [callList, setCallList] = useState<any>([]);
  const [tabRefresh, setTabRefresh] = useState(false);
  const [callHasMore, setCallHasMore] = useState(true);

  const [smsSkip, setSmsSkip] = useState(0);
  const [smsList, setSmsList] = useState<SMSListItem[]>([]);
  const [smsHasMore, setSmsHasMore] = useState(true);

  const [emailSkip, setEmailSkip] = useState(0);
  const [emailList, setEmailList] = useState<EmailListItem[]>([]);
  const [emailHasMore, setEmailHasMore] = useState(true);

  const [initialLoad, setInitialLoad] = useState(true);
  const { customObject } = useFlags();

  const handleShowMore = (skipType: string) => {
    switch (skipType) {
      case "Call": {
        setCallSkip(callSkip + takeNumber);
        break;
      }
      case "SMS": {
        setSmsSkip(smsSkip + takeNumber);
        break;
      }
      case "Email": {
        setEmailSkip(emailSkip + takeNumber);
        break;
      }
    }
  };

  const { data: dataLead, loading: loadingLead, error: errorLead, refetch: refetchLead } = useQuery(FETCH_LEAD, {
    fetchPolicy: "no-cache",
    skip: !lead_id,
    variables: { id: lead_id },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
      appToast("Error fetching lead.");
    },
  });

  configureAbly(ABLY_OPTIONS);

  const channel_name = lead_id ? `notify-custom_fields_update:${lead_id}` : "";

  useChannel(channel_name, (payload) => {
    console.log("custom field update from Ably: ", payload, channel_name);
    if (payload?.data?.lead_id) {
      refetchLead().then((result) => {
        console.log({ fetchLead: result });
        setCustomFieldsData(result?.data?.fetchLead ? { lead: result?.data?.fetchLead } : customFieldsData);
      });
    }
  });

  useEffect(() => {
    MixpanelActions.track("Lead Detail", {
      type: "Page Load",
    });
  }, []);

  useEffect(() => {
    const openEmailActivity = getLocalStorage("open_recent_activity", "")?.email_id || "";

    // const openSMSActivity = getLocalStorage("open_recent_activity")?.sms_id;
    if (!location?.state?.email_id && !!openEmailActivity) {
      location.state = { email_id: openEmailActivity };
      localStorage.setItem("open_recent_activity", JSON.stringify({ email_id: "" }));
    }

    if (getLocalStorage("hotlead_redirect")?.type === "email opened") {
      setStep(3);
    } else {
      setStep(parseInt(getLocalStorage("step_history", "1")));
    }
  }, []);

  const handleStepChange = (step: string) => {
    window.localStorage.setItem("step_history", step);
    setStep(parseInt(window?.localStorage?.getItem("step_history") ?? "1"));
  };

  const { data: dataUpcomingEvents, loading: loadingUpcomingEvents, error: errorUpcomingEvents } = useQuery(
    FETCH_UPCOMING_EVENTS,
    {
      fetchPolicy: "no-cache",
      skip: !lead_id,
      variables: { lead_id: lead_id, show_all_lead: true },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataAssociatedParent, loading: loadingAssociatedParent, error: errorAssociatedParent } = useQuery(
    // this fetch exists so we dont need to refetch the entire lead onComplete of certain mutations
    FETCH_ASSOCIATE_PARENT,
    {
      skip: !lead_id,
      variables: { id: lead_id },
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataCallNotes, loading: loadingCallNotes, error: errorCallNotes } = useQuery(FETCH_CALL_NOTES, {
    variables: {
      lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
      show_all_lead: !filterByAssociateContact || filterByAssociateContact === "All" ? true : false,
    },
    fetchPolicy: "no-cache",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataOwnershipHistory, loading: loadingOwnershipHistory, error: errorOwnershipHistory } = useQuery(
    FETCH_OWNERSHIP_HISTORY,
    {
      variables: {
        lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
        show_all_lead: !filterByAssociateContact || filterByAssociateContact === "All" ? true : false,
      },
      fetchPolicy: "network-only",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataAssociateStat, loading: loadingAssociateStat, error: errorAssociateStat } = useQuery(
    FETCH_ASSOCIATE_STATS,
    {
      variables: {
        lead_id: filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
        show_all_lead: filterByAssociateContact === "All" ? true : false,
      },
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataAssociated, loading: loadingAssociated, error: errorAssociated } = useQuery(
    FETCH_ASSOCIATED_LEADS,
    {
      variables: {
        primaryLeadId: lead_id,
      },
      skip: !lead_id,
      fetchPolicy: "no-cache",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: listData, loading: listLoading, error: listError, refetch: refetchLeadCallHistory } = useQuery(
    FETCH_LEAD_CALL_HISTORY,
    {
      variables: {
        lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
        show_all_lead: !filterByAssociateContact || filterByAssociateContact === "All" ? true : false,
        event_filter: "CallAndNonCall",
        skip: tabRefresh ? 0 : callSkip,
        take: takeNumber,
      },
      fetchPolicy: "cache-and-network",
      onCompleted(data) {
        const fetchLeadHistory = data?.fetchLeadHistory;

        if (!fetchLeadHistory) return;

        if (initialLoad) setInitialLoad(false);
        const incomingCallItems = [...fetchLeadHistory]?.map((ele: any) => ele);

        const newList = tabRefresh
          ? [...incomingCallItems, ...callList].filter(
              (item, index, array) => array.findIndex((a) => a?.id === item?.id) === index,
            )
          : [...callList, ...incomingCallItems].filter(
              (item, index, array) => array.findIndex((a) => a?.id === item?.id) === index,
            );

        setCallList(newList);
        tabRefresh && setTabRefresh(false);
        if (incomingCallItems?.length < takeNumber) setCallHasMore(false);
        else setCallHasMore(true);
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const {
    data: dataRecommendedActionV2,
    loading: loadingRecommendedActionV2,
    error: errorRecommendedActionV2,
  } = useQuery(FETCH_RECOMMENDED_ACTION_V2, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      lead_filter: leadFilter,
      skip: 0,
      take: 1,
      // the active queue let's the user change this sort option
      // the suggested action is always sorted by the organization's order defind by admin
      sort_option: "OrganizationOrder",
    },
    onCompleted() {
      const intentData = dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0];

      const leadData = intentData?.lead;

      setSuggestedActionData({
        intentData: intentData,
        leadData: leadData,
        expectedAction: leadData?.sequence_step?.actions?.[0]?.task,
      });
      console.log("suggestedActionData:", {
        intentData: intentData,
        leadData: leadData,
        expectedAction: leadData?.sequence_step?.actions?.[0]?.task,
      });
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: dataSMS, loading: loadingSMS, error: errorSMS } = useQuery(FETCH_SMS_ITEMS, {
    fetchPolicy: "cache-and-network",
    variables: {
      lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
      event_filter: "SMS",
      show_all_lead: !filterByAssociateContact || filterByAssociateContact === "All" ? true : false,
      skip: smsSkip,
      take: takeNumber,
    },
    onCompleted(data) {
      const fetchLeadHistory = data?.fetchLeadHistory;
      if (!fetchLeadHistory) return;

      const incomingSmsItems = [...fetchLeadHistory]?.map((ele: expectedSMSItemResponse) => {
        return {
          ...ele?.SMSItem,
          id: ele?.SMSItem?.id || ele?.id,
          created_at: ele?.created_at,
          type: ele?.type,
          computed_title: ele?.computed_title,
          user: ele?.user,
          lead: ele?.lead,
        } as SMSListItem;
      });

      const deduplicatedList = [...smsList, ...incomingSmsItems].reduce<SMSListItem[]>((acc, cv) => {
        return acc.some((a) => a.id === cv.id) ? acc : [...acc, cv];
      }, []);

      const newList = deduplicatedList.sort((a: any, b: any) => {
        return moment(a?.created_at || a?.sent_at).isBefore(moment(b?.created_at || b?.sent_at)) ? 1 : -1;
      });

      setSmsList(newList);
      if (incomingSmsItems?.length < takeNumber) setSmsHasMore(false);
      else setSmsHasMore(true);
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });
  const { data: dataEmail, loading: loadingEmail, error: errorEmail, refetch: refetchEmails } = useQuery(
    FETCH_EMAIL_ITEMS,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
        event_filter: "Email",
        show_all_lead: !filterByAssociateContact || filterByAssociateContact === "All" ? true : false,
        skip: emailSkip,
        take: takeNumber,
      },
      onCompleted(data) {
        const fetchLeadHistory = data?.fetchLeadHistory;
        if (!fetchLeadHistory) return;

        const incomingEmailItems = [...fetchLeadHistory]?.map((ele: expectedEmailItemResponse) => {
          return {
            ...ele.email_item,
            id: ele?.email_item?.id || ele?.id,
            created_at: ele.created_at,
            type: ele?.type,
            computed_title: ele?.computed_title,
          } as EmailListItem;
        });

        const newListWithoutDoubled = [...emailList, ...incomingEmailItems].reduce<EmailListItem[]>(
          (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
          [],
        );

        const SortedNewList = newListWithoutDoubled.sort((a: any, b: any) => {
          return moment(a?.created_at || a?.sent_at).isBefore(moment(b?.created_at || b?.sent_at)) ? 1 : -1;
        });

        setEmailList(SortedNewList);
        if (incomingEmailItems?.length < takeNumber) setEmailHasMore(false);
        else setEmailHasMore(true);
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataSync, loading: loadingSync, error: errorSync, refetch: refetchSync } = useQuery(
    FETCH_RECENT_EMAIL_SYNC_RECORDS,
    {
      fetchPolicy: "network-only",
      variables: {
        lead_id: lead_id,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataAssociationHistory, loading: loadingAssociationHistory, error: errorAssociationHistory } = useQuery(
    FETCH_ASSOCIATION_HISTORY,
    {
      fetchPolicy: "network-only",
      variables: {
        lead_id: !filterByAssociateContact || filterByAssociateContact === "All" ? lead_id : filterByAssociateContact,
      },
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const { data: dataCustomObjectSummary, loading: loadingCustomObjectSummary } = useQuery(FETCH_CUSTOM_OBJECT_SUMMARY, {
    fetchPolicy: "network-only",
    variables: {
      lead_id: lead_id,
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const refreshCallHistoryTable = () => {
    // reset infinite scroll data
    setCallList([]);
    setCallSkip(0);

    // refetch
    refetchLeadCallHistory();
  };

  const [changeType, { loading: loadingChangeType }] = useMutation(UPDATE_DISPOSITION_TYPE, {
    onCompleted({ changeDispositionOption }) {
      if (!changeDispositionOption) {
        return;
      }

      refreshCallHistoryTable();

      setEditCallHistoryModal(false);

      appToast("Call Type Updated!");
    },
    onError({ message, name }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `updateDispositionType GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
    refetchQueries: ["fetchLeadCallHistory"],
  });

  const [changeRep, { loading: loadingChangeRep }] = useMutation(CHANGE_DISPOSITION_REP, {
    onCompleted({ changeDispositionRep }) {
      if (!changeDispositionRep) {
        return;
      }
      setEditCallHistoryModal(false);
      appToast("Call Rep Updated!");

      refreshCallHistoryTable();

      setEditCallHistoryModal(false);
    },
    onError({ message, name }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `changeDispositionRep GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
    refetchQueries: ["fetchLeadCallHistory"],
  });

  const [removeCallResult, { loading: loadingRemoveCallResult }] = useMutation(REMOVE_CALL_RESULT, {
    fetchPolicy: "no-cache",
    variables: { lead_activity_id: lastActivityId },
    onCompleted({ removeCallResult }) {
      if (!removeCallResult) {
        return;
      }
      setEditCallHistoryModal(false);
      appToast("Call Result Removed!");

      // update call list's first item to remove related disposition
      const newCallList = callList?.map((call: any) =>
        call?.id === removeCallResult?.id ? { ...call, related_disposition: null } : call,
      );
      setCallList(newCallList);
    },
    onError({ message, name }) {
      errorToast(message);
      Sentry.captureEvent({
        message: `removeCallResult GraphQL Error: ${message}`,
      });
      console.log(`Error in ${name}: `, message);
    },
    refetchQueries: ["fetchLeadCallHistory"],
  });

  useEffect(() => {
    if (!!dataEmail && location?.state?.email_id) {
      handleStepChange("3");
      const selectedEmail = dataEmail?.fetchLeadHistory
        ?.map((item: any) => item.email_item)
        .find((emailItem: any) => emailItem?.id === location.state.email_id);

      if (selectedEmail) {
        setSelectedEmail({
          from: selectedEmail.from_email,
          date: moment(selectedEmail.sent_at).format("MM/DD/YYYY"),
          time: moment(selectedEmail.sent_at).format("h:mm A"),
          subject: selectedEmail.subject,
          html: selectedEmail.html,
          lead_id: lead_id,
          lead_has_opted_out_of_email:
            dataLead?.fetchLead?.email_sub_status === "OPSIQUnsubscribed" ||
            dataLead?.fetchLead?.email_sub_status === "LeadUnsubscribed",
          name: dataLead?.fetchLead?.business_name,
          email_thread_id: selectedEmail.email_thread_id,
          to: selectedEmail.to_email,
          channel: selectedEmail.channel,
        });
        setEmailModal(true);
      }
    }
    return () => {
      setEmailModal(false);
    };
  }, [dataEmail]);

  const { data: dataOrg, loading: loadingOrg, error: errorOrg } = useQuery(FETCH_ORGANIZATION, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const viewRecord = (id: string) => {
    history.push(`/lead-detail/${lead_id}/custom-object/${id}`);
  };

  useEffect(() => {
    if (!lead_id || leadNotFound) {
      appToast("lead not found");
      appToast("redirecting");
      setTimeout(() => {
        history.push(`/dash/`);
      }, 2000);
    }
  }, [lead_id, leadNotFound]);

  useEffect(() => {
    if (dataLead?.fetchLead === null) {
      setLeadNotFound(true);
    }

    if (dataLead?.fetchLead?.business_name) {
      setEnvDocTitle(`${dataLead?.fetchLead?.business_name}`);
    }

    return () => {
      setEnvDocTitle();
    };
  }, [dataLead]);

  // Refetch call history table when switching between tabs. This is to ensure that the call history table is always up to date
  // 10 second delay on refetch to prevent spamming the backend
  useTabFocus(
    () => {
      refetchLeadCallHistory();
      setTabRefresh(true);
    },
    { buffer: 10000 },
  );

  const titleIsBusinessName = loggedInUser()?.organization?.title_is_business_name;

  const formatLeadCardTitle = useCallback(() => {
    if (titleIsBusinessName) {
      return formatBusinessName(business_name);
    }
    return dataLead?.fetchLead?.full_name || dataLead?.fetchLead?.address || "NA";
  }, [titleIsBusinessName, dataLead]);

  // unused currently unsure if we need this
  // const syncGmail = async () => {
  //   try {
  //     const response = await restAPI.get(`${BACKEND_URL}/gmail/syncemails?lead_id=${lead_id}`, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     });
  //     if (!!response?.data) {
  //       console.log("emails synced");
  //       await refetchEmails();
  //       await refetchSync();
  //       return response;
  //     }
  //   } catch (error: any) {
  //     console.log("error", error);
  //   }
  // };

  const HeaderButtons = ({
    step,
    selectedSale,
    setSelectedSale,
  }: {
    step: number;
    selectedSale: any;
    setSelectedSale: any;
  }) => {
    if (selectedSale.id && step === 7) {
      return (
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <PhoenixAppButton
            variant="brand-outline"
            buttonType="secondary"
            onClick={() => {
              setShowUpdateTermsModal(true);
            }}
            uppercase
          >
            edit terms
          </PhoenixAppButton>

          <PhoenixAppButton
            variant="brand"
            buttonType="secondary"
            onClick={() => {
              setSelectedSale({
                ...selectedSale,
                product_update_modal_type: "add",
              });

              setShowUpdateProductModal(true);
            }}
            uppercase
          >
            add product
          </PhoenixAppButton>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderKeyEvent = (type: any, key: any) => {
    switch (type) {
      case "set":
      case "disregarded_set":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY600,
            }}
            key={`${type}-${key}`}
            data-tip={
              type === "disregarded_set"
                ? "This set happened pre-merge. This will not count in the dashboard"
                : undefined
            }
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              SET
            </AppText>
          </KeyEventDiv>
        );
      case "initial_set":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              INITIAL SET
            </AppText>
          </KeyEventDiv>
        );
      case "rescheduled_set":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              RESCHEDULED SET
            </AppText>
          </KeyEventDiv>
        );
      case "hold":
      case "disregarded_hold":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY600,
            }}
            key={`${type}-${key}`}
            data-tip={
              type === "disregarded_hold"
                ? "This hold happened pre-merge. This will not count in the dashboard"
                : undefined
            }
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              HOLD
            </AppText>
          </KeyEventDiv>
        );
      case "initial_hold":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              INITIAL HOLD
            </AppText>
          </KeyEventDiv>
        );
      case "rescheduled_hold":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              RESCHEDULED HOLD
            </AppText>
          </KeyEventDiv>
        );
      case "sale":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.SUCCESS500,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              SALE CLOSED
            </AppText>
          </KeyEventDiv>
        );
      case "otf":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY600,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              OTF
            </AppText>
          </KeyEventDiv>
        );
      case "dm_connect":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.NEUTRAL200,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              DM CONNECT
            </AppText>
          </KeyEventDiv>
        );
      case "ndm_connect":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.NEUTRAL200,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              NDM CONNECT
            </AppText>
          </KeyEventDiv>
        );
      case "NonCall":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY600,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              NON-CALL
            </AppText>
          </KeyEventDiv>
        );
      case "Scheduled Event":
      case "Schedule Callback":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              SCHEDULED EVENT
            </AppText>
          </KeyEventDiv>
        );
      case "Rescheduled Event":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              RESCHEDULED EVENT
            </AppText>
          </KeyEventDiv>
        );
      case "Delete Scheduled Event":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              DELETED SCHEDULED EVENT
            </AppText>
          </KeyEventDiv>
        );
      case "decision_call_set":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              CLOSING OPPORTUNITY SET
            </AppText>
          </KeyEventDiv>
        );
      case "closing_opp_held":
      case "followup_hold":
      case "decision_call_held":
        return (
          <KeyEventDiv
            style={{
              backgroundColor: theme.PRIMARY400,
            }}
            key={`${type}-${key}`}
          >
            <AppText fontSize={8} fontWeight={600} variant="white">
              CLOSING OPPORTUNITY HELD
            </AppText>
          </KeyEventDiv>
        );
      default:
        break;
    }
  };

  const Header = ({ step, selectedSale }: { step: number; selectedSale: any }) => {
    return (
      <LeadHistoryHeader align="center" gap={8}>
        <AppText fontSize={12} fontWeight={500}>
          Filter by Contact
        </AppText>
        <div style={{ width: "40%" }}>
          <NewAppSelect
            options={[
              { label: "Show All", value: "All" },
              {
                label:
                  dataLead?.fetchLead?.first_name && dataLead?.fetchLead?.last_name
                    ? `${dataLead?.fetchLead?.first_name} ${dataLead?.fetchLead?.last_name}`
                    : "No Name",
                value: dataLead?.fetchLead?.id,
              },
              ...(!!dataAssociated?.fetchAssociateContact
                ? dataAssociated?.fetchAssociateContact?.map((item: any) => {
                    return {
                      label: item?.first_name && item?.last_name ? `${item?.first_name} ${item?.last_name}` : "No Name",
                      value: item?.id,
                    };
                  })
                : []),
            ]}
            value={filterByAssociateContact}
            onChange={(e: any) => {
              if (e?.value !== filterByAssociateContact) {
                e?.value && setFilterByAssociateContact(e?.value);

                // reset infinite scroll data
                setCallList([]);
                setCallSkip(0);
                setSmsList([]);
                setSmsSkip(0);
                setEmailList([]);
                setEmailSkip(0);
              }
            }}
            noErrorNeeded
            isDisabled={step === 7 && selectedSale.id}
          />
        </div>

        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <HeaderButtons step={step} selectedSale={selectedSale} setSelectedSale={setSelectedSale} />
        </div>
      </LeadHistoryHeader>
    );
  };

  const renderAssociateStatCard = ({ id, contacts, loading }: { id: string; contacts: any; loading?: boolean }) => {
    if (loading) return <SkeletonBlock height={24} width={100} borderRadius={8} />;

    if (contacts && contacts[id]) {
      return (
        <StatCard
          icon={contacts[id] === "Primary" ? user_white : associated_white}
          text={contacts[id].toUpperCase()}
          variant={contacts[id] === "Primary" ? "primary" : "secondary"}
        />
      );
    }
    return <StatCard icon={arrowsLeftAndRight} text="DISASSOCIATED" variant="tertiary" />;
  };

  const renderCustomObject = () => {
    if (customObject) {
      let object_exist = !!dataCustomObjectSummary?.fetchAssociatedCustomObjects.length;
      return (
        <Right>
          <AppSidebarCard title="Related Records" showExpand={false} marginBottom="16px">
            {object_exist ? (
              <RelatedRecordsCard
                lead_id={lead_id}
                customObjectSummary={dataCustomObjectSummary?.fetchAssociatedCustomObjects}
                viewRecord={viewRecord}
              />
            ) : (
              <NoRelatedRecordDiv>
                <AppText color={theme.NEUTRAL300} fontSize={14} fontWeight={500}>
                  No related records for this lead
                </AppText>
              </NoRelatedRecordDiv>
            )}
          </AppSidebarCard>

          <RelatedRecordCardData
            lead_id={lead_id}
            customObjectSummary={dataCustomObjectSummary?.fetchAssociatedCustomObjects}
          />
        </Right>
      );
    }
    return <></>;
  };
  const [
    fetchDispositionOptions,
    { data: dataDispositionOptions, loading: loadingDispositionOptions, error: errorDispositionOptions },
  ] = useLazyQuery(FETCH_DISPOSITION_OPTIONS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });
  const [showCallResultPopup, setShowCallResultPopup] = useState(false);
  const [showRepResultPopup, setShowRepResultPopup] = useState(false);

  const business_name = dataLead?.fetchLead?.business_name;

  const renderEvents = (item: any) => {
    const aggregationKeys = Object.keys(item?.stat_item_aggregation || {})
      .filter((key: any) => item?.stat_item_aggregation[key] === 1)
      ?.map((key: any, i: number) => renderKeyEvent(key, i));

    const nonCallTypeEvent =
      item.communication_type === "NonCall"
        ? [renderKeyEvent(item.communication_type, item.communication_type), renderKeyEvent(item.title, item.title)]
        : [];

    return [...aggregationKeys, ...nonCallTypeEvent];
  };

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured on the Lead Detail page."}>
      {loadingLead ? (
        <FullViewportDiv>
          <SkeletonBlock height={"100%"} width={"100%"} borderRadius={8} />
        </FullViewportDiv>
      ) : errorLead ? (
        <Main>
          <AppErrorText>
            An error has occured on the Lead Detail page. Please refresh the page or contact support if the error
            persists.
          </AppErrorText>
        </Main>
      ) : (
        <Main>
          {editCallHistoryModal && (
            <Modal open={editCallHistoryModal} onClose={() => setEditCallHistoryModal(false)} closeButtonSize={16}>
              <CenterModalDiv>
                <LabelText>Confirm Change</LabelText>
                <AppText fontSize={14}>
                  Are you sure you would like to change this {editCallHistoryModalItem.type.toLowerCase()}?
                </AppText>
                <Divider />
                <ButtonDiv>
                  <NewAppButton
                    variant={"attention"}
                    onClick={() => {
                      setEditCallHistoryModal(false);
                    }}
                  >
                    Cancel
                  </NewAppButton>
                  <NewAppButton
                    variant={"primary"}
                    disabled={!!loadingChangeRep || !!loadingChangeType}
                    onClick={async () => {
                      if (editCallHistoryModalItem.type === "Rep") {
                        await changeRep({
                          variables: {
                            new_rep_id: editCallHistoryModalItem.new_rep_id,
                            lead_activity_id: editCallHistoryModalItem.lead_activity_id,
                          },
                        });
                      } else if (editCallHistoryModalItem.type === "Call Result") {
                        await changeType({
                          variables: {
                            disposition_id: editCallHistoryModalItem.disposition_id,
                            lead_activity_id: editCallHistoryModalItem.lead_activity_id,
                          },
                        });
                      }
                      setShowAddOrEditContactModal(false);
                    }}
                  >
                    {!!loadingChangeRep || !!loadingChangeType ? <Loading /> : "Confirm"}
                  </NewAppButton>
                </ButtonDiv>
              </CenterModalDiv>
            </Modal>
          )}
          <FullEmailModal />
          {editModal && (
            <Modal open={editModal} onClose={() => setEditModal(false)} closeButtonSize={16}>
              <UpdateLeadDataComponent
                customHeight
                visible={editModal}
                close={() => {
                  setEditModal(false);
                  setEditModalLeadId("");
                }}
                lead_id={editModalLeadId}
              />
            </Modal>
          )}
          {showCustomFieldsModal && (
            <Modal open={showCustomFieldsModal} onClose={() => setCustomFieldsModal(false)} closeButtonSize={16}>
              <EditCustomFieldsComponent
                customHeight
                showEditCustomFields={showCustomFieldsModal}
                closeCustomFieldsModal={closeCustomFieldsModal}
                fields={customFieldsData?.lead?.visible_custom_fields}
                lead_id={customFieldsData?.lead?.id}
              />
            </Modal>
          )}
          {showRemoveCallResultModal && (
            <Modal
              open={showRemoveCallResultModal}
              onClose={() => setShowRemoveCallResultModal(false)}
              closeButtonSize={16}
            >
              <CenterModalDiv>
                <LabelText fontSize={16}>Remove Event?</LabelText>
                <AppText>Are you sure you want to remove this event?</AppText>
                <Divider />
                <ButtonDiv>
                  <NewAppButton
                    variant="attention"
                    onClick={() => {
                      setLastActivityId("");
                      setShowRemoveCallResultModal(false);
                    }}
                  >
                    Cancel
                  </NewAppButton>
                  <NewAppButton
                    variant="primary"
                    onClick={() => {
                      removeCallResult();
                      setLastActivityId("");
                      setShowRemoveCallResultModal(false);
                    }}
                  >
                    Remove Result
                  </NewAppButton>
                </ButtonDiv>
              </CenterModalDiv>
            </Modal>
          )}
          {reassignLeadModal && !!selectedLeadToReassign.lead_id && <ReassignLeadModalComponent trackMixpanel />}
          <HeaderDiv>
            <SaleMadeComponent
              lead_title={formatLeadCardTitle()}
              lead_id={lead_id}
              associate_lead_ids={
                dataAssociated?.fetchAssociateContact?.length
                  ? dataAssociated?.fetchAssociateContact?.map((item: any) => item?.id)
                  : []
              }
            />
            <FlexDiv gap={24} align="center">
              {dataLead?.fetchLead?.rep?.id ? (
                <LeadAssignedToText>
                  Lead assigned to&nbsp;
                  <span style={{ color: theme.PRIMARY500, fontWeight: 600 }}>
                    {dataLead?.fetchLead?.rep?.first_name ?? ""} {dataLead?.fetchLead?.rep?.last_name ?? ""}
                  </span>
                </LeadAssignedToText>
              ) : (
                <LeadAssignedToText>Lead not assigned</LeadAssignedToText>
              )}
              {(["ADMIN", "SM"].includes(loggedInUser().role ?? "") ||
                (dataLead?.fetchLead?.rep?.id === loggedInUser().id &&
                  dataLead?.fetchLead?.organization?.double_booking)) && (
                <NewAppButton
                  variant={"primary"}
                  style={{ marginLeft: "24px", fontSize: "10px", letterSpacing: "1px" }}
                  width={155}
                  onClick={() => {
                    MixpanelActions.track("Lead Detail", {
                      type: "Reassign Clicked",
                    });
                    setSelectedLeadToReassign({
                      lead_id: lead_id,
                      source_user_id: dataLead?.fetchLead?.rep?.id ?? "",
                      lead_id_list: [],
                    });
                    setReassignLeadModal(!reassignLeadModal);
                  }}
                >
                  REASSIGN LEAD
                </NewAppButton>
              )}
            </FlexDiv>
          </HeaderDiv>
          {!dataAssociatedParent?.fetchLead?.associate_parent_id ? (
            <GridDiv customObject={customObject}>
              {showAddAssociateModal ? (
                <SidebarContainer>
                  <SidebarWrapper>
                    <AddAssociatedContact setShowAddAssociatedContact={setShowAddAssociateModal} />
                  </SidebarWrapper>
                </SidebarContainer>
              ) : showAddOrEditContactModal ? (
                <SidebarContainer>
                  <SidebarWrapper>
                    <AddOrEditContactModal setShowAddContact={setShowAddOrEditContactModal} />
                  </SidebarWrapper>
                </SidebarContainer>
              ) : (
                <Left>
                  <LeadCardV2
                    hideActionButtons
                    expandOnRender
                    parentType={"leadDetails"}
                    leadData={dataLead?.fetchLead}
                    closeOtherModals={() => console.log("closing modals")}
                    customObjectSummary={dataCustomObjectSummary?.fetchAssociatedCustomObjects}
                    border
                    borderRadius={"8px"}
                    showLogNonCallActivity
                  />
                  <AppSidebarCard title="Upcoming Events">
                    {!!dataUpcomingEvents?.fetchScheduledItems?.length ? (
                      dataUpcomingEvents?.fetchScheduledItems?.map((item: any) => (
                        <ScheduledEventDiv key={item?.id}>
                          <FlexDiv align="center" gap={8}>
                            <StatCard
                              icon={item?.Lead[0]?.associate_parent_id ? associated_white : user_white}
                              variant={item?.Lead[0]?.associate_parent_id ? "secondary" : "primary"}
                            />
                            <AppText>{item?.action_label}</AppText>
                          </FlexDiv>
                          <AppText>
                            {moment(item?.start_time).format("MM/DD/YYYY")} &nbsp;{" "}
                            {moment(item?.start_time).format("h:mm A")}
                          </AppText>
                        </ScheduledEventDiv>
                      ))
                    ) : (
                      <ScheduledEventDiv>
                        <AppText>No scheduled events</AppText>
                      </ScheduledEventDiv>
                    )}
                  </AppSidebarCard>
                </Left>
              )}
              {showDisassociateLeadModal && (
                <DarkDiv2>
                  <DisassociateLeadModal />
                </DarkDiv2>
              )}
              {showSwapPrimaryLeadModal && (
                <DarkDiv2>
                  <SwapPrimaryLeadModal />
                </DarkDiv2>
              )}
              {!showMergeContactsModal ? (
                <Right>
                  <AppSidebarCard title="Associated Contacts" showExpand>
                    <AssociatedContactsContainer>
                      <ACButtonContainer>
                        <ACButton
                          onClick={() => {
                            setShowAddAssociateModal(true);
                            setPrimaryLeadToAddAssociateTo(dataLead?.fetchLead?.id);
                          }}
                          variant="newDesignSecondary"
                        >
                          <img src={addNewUser} alt="add new user" style={{ marginRight: "5px" }} />
                          ADD NEW
                        </ACButton>
                        <ACButton
                          variant="newDesignSecondary"
                          onClick={() => {
                            setShowMergeContactsModal(true);
                            // apply source lead data to modal context var
                            setMergeLeadsData({
                              source: {
                                id: dataLead?.fetchLead?.id,
                                full_name: dataLead?.fetchLead?.full_name,
                                business_name: dataLead?.fetchLead?.business_name,
                              },
                            });
                          }}
                          style={{ width: "fit-content" }}
                        >
                          <img src={merge_primary} alt="merge" style={{ marginRight: "5px" }} />
                          ASSOCIATE EXISTING
                        </ACButton>
                      </ACButtonContainer>

                      <ACBody direction="column" gap={8}>
                        {loadingAssociated ? (
                          <Loading />
                        ) : !dataAssociated?.fetchAssociateContact?.length ? (
                          <AppText fontSize={14}>This lead has no associated contacts.</AppText>
                        ) : (
                          dataAssociated?.fetchAssociateContact?.map((ele: any, i: number) => (
                            <AssociatedContactDropdown
                              data={ele}
                              leadData={dataLead?.fetchLead}
                              key={ele?.id}
                              index={i}
                            />
                          ))
                        )}
                      </ACBody>
                    </AssociatedContactsContainer>
                  </AppSidebarCard>

                  <AppSidebarCard title="Lead History" height={568} showExpand style={{ overflow: "hidden" }}>
                    <Header step={step} selectedSale={selectedSale} />

                    <HeaderTabs step={step} handleStepChange={handleStepChange} adminModifySale={adminModifySale} />

                    {step === 1 &&
                      (listError ? (
                        <MinHeightDiv>
                          <AppErrorText>Error loading stack rank data!</AppErrorText>
                        </MinHeightDiv>
                      ) : (
                        <MinHeightDiv style={{ overflowY: "hidden" }}>
                          {callList.length === 0 && !listLoading ? (
                            <NothingHereText>No call history has been reported</NothingHereText>
                          ) : (
                            <InfiniteScroll
                              dataLength={callList?.length}
                              next={() => handleShowMore("Call")}
                              hasMore={callHasMore}
                              loader={
                                <div>
                                  <Loading />
                                </div>
                              }
                              scrollableTarget="lead-detail-call-list"
                              height={517}
                            >
                              <AppTable
                                sticky
                                columns={[
                                  "Date/Time",
                                  "Lead Dialed",
                                  "Sales Rep",
                                  "Call Result",
                                  "Key Events",
                                  "Call Audio",
                                  "",
                                  "Call End Reason",
                                ]}
                                id="lead-detail-call-list"
                                headerStyle={{ zIndex: 1 }}
                                headerContainerZIndex={3}
                              >
                                {callList?.map((item: any) => (
                                  <TableRow key={item?.id}>
                                    <TableTD>
                                      {" "}
                                      {formatUnixImportDate(
                                        item?.communication_type === "NonCall"
                                          ? item?.created_at
                                          : item?.conference?.start_time ?? item?.created_at,
                                      )}
                                    </TableTD>
                                    <TableTD>
                                      <FlexDiv align="center" gap={8}>
                                        {renderAssociateStatCard({
                                          id: item?.associate_child?.id || item?.lead?.id,
                                          contacts: dataAssociateStat?.fetchCurrentAssociateStats,
                                          loading: loadingAssociateStat,
                                        })}
                                        <AppText>
                                          {!item?.associate_child
                                            ? item?.lead?.full_name || "No Name"
                                            : item?.associate_child?.full_name || "No Name"}
                                        </AppText>
                                      </FlexDiv>
                                    </TableTD>
                                    <TableTD fixedWidth={150} style={{}}>
                                      {loggedInUser().role === "ADMIN" ? (
                                        <div
                                          style={{
                                            position: "relative",
                                            overflow: "show",
                                          }}
                                        >
                                          <PhoenixMenuSelect
                                            height={"100%"}
                                            menuItemHeight={"fit-content"}
                                            onClick={() => {
                                              // add the id and to the edit modal item
                                              setEditCallHistoryModalItem({
                                                ...editCallHistoryModalItem,
                                                type: "Rep",
                                                lead_activity_id: item?.id,
                                                disposition_id: "",
                                              });

                                              setShowRepResultPopup(!showRepResultPopup);

                                              // always close other modal if it is open
                                              setShowCallResultPopup(false);
                                            }}
                                            title={item?.user?.full_name ?? "N/A"}
                                            showSearch
                                            containerZIndex={1}
                                            loading={loadingOrg}
                                            error={!!errorOrg}
                                            setShow={setShowRepResultPopup}
                                            show={
                                              showRepResultPopup &&
                                              editCallHistoryModalItem?.lead_activity_id === item?.id
                                            }
                                            options={
                                              dataOrg?.fetchOrganization?.Reps?.slice()
                                                ?.sort(
                                                  (a: any, b: any) => a?.full_name?.localeCompare(b?.full_name) ?? 0,
                                                )
                                                ?.map((rep: any) => ({
                                                  value: rep?.id,
                                                  label: rep?.full_name ?? "N/A",
                                                  onOptionClick: () => {
                                                    setShowRepResultPopup(false);
                                                    setEditCallHistoryModal(true);
                                                    setEditCallHistoryModalItem({
                                                      ...editCallHistoryModalItem,
                                                      type: "Rep",
                                                      new_rep_id: rep?.id,
                                                    });
                                                  },
                                                })) ?? [
                                                {
                                                  value: "No Available Call Results based on disposition rules",
                                                  label: "No Available Call Results based on disposition rules",
                                                  disabled: true,
                                                  onOptionClick: () => {},
                                                },
                                              ]
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <AppText>{item?.user?.full_name ?? "N/A"}</AppText>
                                      )}
                                    </TableTD>
                                    <TableTD>
                                      {loggedInUser().role === "ADMIN" ? (
                                        <FlexDiv
                                          align="center"
                                          gap={4}
                                          style={{
                                            position: "relative",
                                            width: "100%",
                                          }}
                                        >
                                          <PhoenixMenuSelect
                                            height={"100%"}
                                            menuItemHeight={"fit-content"}
                                            menuItemPadding={"4px 8px"}
                                            onClick={() => {
                                              setShowCallResultPopup(!showCallResultPopup);

                                              // always close other modal if it is open
                                              setShowRepResultPopup(false);

                                              // add the id and to the edit modal item
                                              setEditCallHistoryModalItem({
                                                ...editCallHistoryModalItem,
                                                type: "Call Result",
                                                lead_activity_id: item?.id,
                                              });
                                              // fetch the disposition options (bug if put in useEffect)
                                              fetchDispositionOptions({
                                                variables: {
                                                  lead_activity_id: item?.id,
                                                },
                                              });
                                            }}
                                            title={item?.related_disposition?.label ?? "No Call Result"}
                                            showSearch
                                            containerZIndex={9999}
                                            loading={loadingDispositionOptions}
                                            error={!!errorDispositionOptions}
                                            setShow={setShowCallResultPopup}
                                            show={
                                              showCallResultPopup &&
                                              editCallHistoryModalItem?.lead_activity_id === item?.id
                                            }
                                            options={
                                              dataDispositionOptions?.getDispositionChangeOptions
                                                ?.slice()
                                                ?.sort((a: any, b: any) => a?.label?.localeCompare(b?.label) ?? 0)
                                                ?.map((disposition: any) => ({
                                                  value: disposition?.disposition_id,
                                                  label: disposition?.label ?? "No Call Result",
                                                  onOptionClick: () => {
                                                    setShowCallResultPopup(false);
                                                    setEditCallHistoryModal(true);
                                                    setEditCallHistoryModalItem({
                                                      ...editCallHistoryModalItem,
                                                      type: "Call Result",
                                                      disposition_id: disposition?.disposition_id,
                                                    });
                                                  },
                                                })) ?? []
                                            }
                                          />
                                          {item?.related_disposition_reason && (
                                            <DispositionReasonPill
                                              reason={item?.related_disposition_reason || "N/A"}
                                              disposition={item?.related_disposition?.label || "No Call Result"}
                                            />
                                          )}
                                        </FlexDiv>
                                      ) : (
                                        <FlexDiv align="center" gap={4}>
                                          <CallResultPill
                                            result={item?.related_disposition?.label ?? "No Call Result"}
                                          />
                                          {item?.related_disposition_reason && (
                                            <DispositionReasonPill
                                              reason={item?.related_disposition_reason}
                                              disposition={item?.related_disposition?.label}
                                            />
                                          )}
                                        </FlexDiv>
                                      )}
                                    </TableTD>
                                    <TableTD style={{ textAlign: "center" }}>
                                      <KeyEventsDiv>{renderEvents(item)}</KeyEventsDiv>
                                    </TableTD>
                                    <TableTD>
                                      {!!item.conference?.duration &&
                                        item.conference?.id &&
                                        !!item?.conference?.recording_url_computed && (
                                          <CenterFlex
                                            onClick={() => {
                                              const santizedURL = sanitizeURL(
                                                `/call-report/${item.conference.id}/from_system_view=true`,
                                              );
                                              history.push(santizedURL);
                                            }}
                                          >
                                            <img src={iconPlayNew} />
                                            <TimeText>{formatCallDuration(item.conference?.duration)}</TimeText>
                                          </CenterFlex>
                                        )}
                                    </TableTD>
                                    <TableTD style={{ position: "relative" }}>
                                      {!!item?.is_last_activity &&
                                        loggedInUser().role === "ADMIN" &&
                                        // there is a disposition to remove
                                        (!!item?.related_disposition?.id || !!item?.related_dispostion_id) && (
                                          <>
                                            <NewAppButton
                                              onClick={() => {
                                                setLastActivityId(item?.id);
                                                setShowLastActivityOptions(true);
                                              }}
                                            >
                                              <BsThreeDotsVertical />
                                            </NewAppButton>
                                            {!!showLastActivityOptions && (
                                              <OptionsDiv>
                                                <Option
                                                  onClick={() => {
                                                    setShowRemoveCallResultModal(true);
                                                    setShowLastActivityOptions(false);
                                                  }}
                                                >
                                                  Remove result
                                                </Option>
                                              </OptionsDiv>
                                            )}
                                          </>
                                        )}
                                    </TableTD>
                                    <TableTD>
                                      <AppText>{camelToProperSplit(item?.call_end_reason)}</AppText>
                                    </TableTD>
                                  </TableRow>
                                ))}
                              </AppTable>
                            </InfiniteScroll>
                          )}
                        </MinHeightDiv>
                      ))}
                    {step === 2 &&
                      (!smsList && dataSMS && loadingSMS ? (
                        <MinHeightDiv style={{ marginTop: "24px" }}>
                          <Loading />
                        </MinHeightDiv>
                      ) : errorSMS ? (
                        <MinHeightDiv>
                          <AppErrorText>Error loading SMS messages</AppErrorText>
                        </MinHeightDiv>
                      ) : smsList?.length === 0 && !loadingSMS ? (
                        <MinHeightDiv>
                          <NothingHereText>No SMS history has been reported</NothingHereText>
                        </MinHeightDiv>
                      ) : (
                        <MinHeightDiv style={{ overflowY: "hidden" }} id="lead-detail-sms-list">
                          <InfiniteScroll
                            dataLength={smsList?.length}
                            next={() => handleShowMore("SMS")}
                            hasMore={smsHasMore}
                            loader={
                              <div>
                                <Loading />
                              </div>
                            }
                            scrollableTarget="lead-detail-sms-list"
                            height={517}
                          >
                            {smsList?.map((message: any) => {
                              if (!message) return null;
                              if (Object.values(CommunicationStates).includes(message?.type)) {
                                return (
                                  <CommunicationsActivityLeadDetail
                                    subscribed={message?.type === CommunicationStates.Subscribed}
                                    type="sms"
                                    computed_title={message?.computed_title}
                                    date={message.created_at}
                                  />
                                );
                              } else {
                                if (!message) return null;
                                const {
                                  isFromRep,
                                  id,
                                  text,
                                  sent_at,
                                  lead_number_used,
                                  lead_activity,
                                  user,
                                  lead,
                                  sequence_name,
                                  type,
                                } = message;

                                if (!text || !id) return null;

                                return (
                                  <MessageDialogDivV2 key={`${id}-message`}>
                                    <SMSMessageActivityV2
                                      isFromRep={isFromRep}
                                      id={id}
                                      text={text}
                                      sent_at={sent_at}
                                      lead_number_used={lead_number_used}
                                      lead_activity={{
                                        id: lead_activity?.id,
                                        user,
                                        lead,
                                      }}
                                      type={type}
                                      primary_lead_id={lead_activity?.lead?.id}
                                      associate_lead_ids={lead_activity?.lead?.associate_lead_ids}
                                      contact_id={lead_activity?.lead?.contact_id}
                                      isAssociatedLead={lead_activity?.lead?.is_associated_lead}
                                      sequence_name={sequence_name}
                                      userStillOwnsLead={lead?.rep_id === loggedInUser().id}
                                    />
                                  </MessageDialogDivV2>
                                );
                              }
                            })}
                          </InfiniteScroll>
                        </MinHeightDiv>
                      ))}
                    {step === 3 &&
                      (!emailList.length && dataEmail && loadingEmail ? (
                        <MinHeightDiv style={{ marginTop: "24px" }}>
                          <Loading />
                        </MinHeightDiv>
                      ) : errorEmail ? (
                        <MinHeightDiv>
                          <AppErrorText>Error loading email data!</AppErrorText>
                        </MinHeightDiv>
                      ) : (
                        <MinHeightDiv style={{ overflowY: "hidden" }} id="lead-detail-email-list">
                          {!!dataSync?.fetchRecentEmailSyncRecords[0]?.created_at && !!gmailSync && (
                            <SyncInfoText>
                              Last Synced by {dataSync?.fetchRecentEmailSyncRecords[0]?.lead?.full_name} at [
                              {formatUnixDateTime(dataSync?.fetchRecentEmailSyncRecords[0]?.created_at)}]
                            </SyncInfoText>
                          )}
                          <InfiniteScroll
                            dataLength={emailList?.length}
                            next={() => handleShowMore("Email")}
                            hasMore={emailHasMore}
                            loader={
                              <div>
                                <Loading />
                              </div>
                            }
                            scrollableTarget="lead-detail-email-list"
                            height={517}
                            style={{ overflowX: "hidden" }}
                          >
                            {!!emailList &&
                              (emailList.length > 0 ? (
                                emailList?.map((email: any, i: number) => {
                                  const isHot =
                                    email?.id === getLocalStorage("hotlead_redirect")?.id && !isUndefined(email?.id);
                                  if (Object.values(CommunicationStates).includes(email?.type)) {
                                    return (
                                      <CommunicationsActivityLeadDetail
                                        subscribed={email?.type === CommunicationStates.Subscribed}
                                        type="email"
                                        computed_title={email?.computed_title}
                                        date={email.created_at}
                                      />
                                    );
                                  } else {
                                    if (!email || !email.id || (email.html === null && email.body === null))
                                      return null;
                                    return (
                                      <EmailDiv
                                        key={email?.id}
                                        onClick={() => {
                                          setSelectedEmail({
                                            from: email?.from_email,
                                            date: moment(email?.sent_at).format("MM/DD/YYYY"),
                                            time: moment(email?.sent_at).format("h:mm A"),
                                            subject: email?.subject,
                                            html: email?.html || email?.body || "",
                                            lead_id: lead_id,
                                            lead_has_opted_out_of_email:
                                              dataLead?.fetchLead?.email_sub_status ===
                                                CommunicationStates.OPSIQUnsubscribed ||
                                              dataLead?.fetchLead?.email_sub_status ===
                                                CommunicationStates.LeadUnsubscribed,
                                            name: dataLead?.fetchLead?.business_name,
                                            email_thread_id: email?.email_thread_id,
                                            to: email?.to_email,
                                            channel: email?.channel,
                                          });
                                          setEmailModal(!emailModal);
                                        }}
                                        isHot={isHot}
                                      >
                                        {isHot && (
                                          <FlameIconContainer>
                                            <PhoenixIcon
                                              svg={flame}
                                              variant="white"
                                              hoverColor={theme.WHITE_COLOR}
                                              size={16}
                                            />
                                          </FlameIconContainer>
                                        )}
                                        <ImageBorder>
                                          <PictureDiv
                                            style={{ marginTop: "0px" }}
                                            isFromRep={email?.channel === "Inbound"}
                                          >
                                            {!!email &&
                                              !!email?.from_email &&
                                              DOMPurify.sanitize(email?.from_email).slice()[0].toUpperCase()}
                                          </PictureDiv>
                                        </ImageBorder>
                                        <FlexColDiv>
                                          <EmailText fontSize={14} unread={false}>
                                            {DOMPurify.sanitize(email?.from_email)}
                                          </EmailText>
                                          <EllipsisText>{email?.subject} &nbsp; </EllipsisText>
                                          {email?.html ? (
                                            <EllipsisText>
                                              {DOMPurify.sanitize(displaySanitizedHTML(email?.html || "") || "")}
                                            </EllipsisText>
                                          ) : (
                                            <EllipsisText>
                                              {DOMPurify.sanitize(displaySanitizedHTML(email?.body) || "") || ""}
                                            </EllipsisText>
                                          )}
                                        </FlexColDiv>
                                        <div>
                                          <EmailText>{moment(email?.sent_at).format("MM/DD/YYYY")}</EmailText>
                                          <EmailText>{moment(email?.sent_at).format("h:mm A")}</EmailText>
                                        </div>
                                      </EmailDiv>
                                    );
                                  }
                                })
                              ) : (
                                <NothingHereText>No email history has been reported</NothingHereText>
                              ))}
                          </InfiniteScroll>
                        </MinHeightDiv>
                      ))}

                    {step === 4 && (
                      <MinHeightDiv>
                        {dataCallNotes?.fetchLeadCallNoteHistory?.map((item: any) => (
                          <React.Fragment key={item?.id}>
                            <CallNoteTime>
                              {!!item.created_at
                                ? `${moment(item.created_at).format("M/D/YYYY h:mm A")} - ${item.user?.full_name}`
                                : ""}
                            </CallNoteTime>
                            {!!item.html
                              ? displaySanitizedHTML(item.notes)
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  .split("<br />")
                                  ?.map((text: string, i: number) => (
                                    <CallNoteText key={`${text}-${i}`}>{text}</CallNoteText>
                                  ))
                              : item.notes
                                  .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                  .split("<br />")
                                  ?.map((text: string, i: number) => (
                                    <CallNoteText key={`${text}-${i}`}>{text}</CallNoteText>
                                  ))}
                          </React.Fragment>
                        ))}
                      </MinHeightDiv>
                    )}

                    {step === 5 &&
                      (!dataOwnershipHistory || loadingOwnershipHistory ? (
                        <MinHeightDiv style={{ marginTop: "24px" }}>
                          <Loading />
                        </MinHeightDiv>
                      ) : errorOwnershipHistory ? (
                        <MinHeightDiv>
                          <AppErrorText>Error loading Lead Assignment Data</AppErrorText>
                        </MinHeightDiv>
                      ) : (
                        <MinHeightDiv>
                          {dataOwnershipHistory?.fetchLeadAssignmentHistory?.length === 0 ? (
                            <NothingHereText>No assignment history has been recorded</NothingHereText>
                          ) : (
                            <AppTable columns={["Date/Time", "New Rep", "Reason Changed", "Who Initiated?"]}>
                              <AppTableItems
                                data={[...dataOwnershipHistory?.fetchLeadAssignmentHistory]
                                  ?.sort((a: any, b: any) => b.created_at - a.created_at)
                                  ?.map((item: any, index: number) => [
                                    formatUnixImportDate(item?.created_at),
                                    item?.receiver_user?.full_name,
                                    item?.lead_assignment_origin_label ?? "Other",
                                    item?.sender_user?.full_name
                                      ? item?.sender_user?.full_name
                                      : item?.custom_who_initiated,
                                  ])}
                              />
                            </AppTable>
                          )}
                        </MinHeightDiv>
                      ))}
                    {step === 6 &&
                      (!dataAssociationHistory || loadingAssociationHistory ? (
                        <MinHeightDiv style={{ marginTop: "24px" }}>
                          <Loading />
                        </MinHeightDiv>
                      ) : errorAssociationHistory ? (
                        <MinHeightDiv>
                          <AppErrorText>Error loading Association History Data</AppErrorText>
                        </MinHeightDiv>
                      ) : (
                        <MinHeightDiv>
                          {dataAssociationHistory?.fetchAssociateContactHistory.length === 0 ? (
                            <NothingHereText>No association history history has been recorded</NothingHereText>
                          ) : (
                            <AppTable columns={["Date/Time", "Action", "Lead", "Lead Owner"]}>
                              {[...dataAssociationHistory?.fetchAssociateContactHistory]
                                .sort((a: any, b: any) => b.created_at - a.created_at)
                                ?.map((item: any, index: number) => (
                                  <TableRow key={item?.id}>
                                    <TableTD>{formatUnixImportDate(item?.created_at)}</TableTD>
                                    <TableTD>
                                      <StatCard
                                        icon={
                                          item?.action === "LeadDisassociated" ? arrowsLeftAndRight : associated_white
                                        }
                                        variant={item?.action === "LeadDisassociated" ? "tertiary" : "secondary"}
                                        text={
                                          item?.action === "LeadDisassociated"
                                            ? "DISASSOCIATED"
                                            : item?.action === "AssociateContactCreated"
                                            ? "ASSOCIATE CREATED"
                                            : "ASSOCIATED"
                                        }
                                      />
                                    </TableTD>
                                    <TableTD>{item?.associate_lead?.full_name || "No Name"}</TableTD>
                                    <TableTD>{item?.rep?.full_name || "No Name"}</TableTD>
                                  </TableRow>
                                ))}
                            </AppTable>
                          )}
                        </MinHeightDiv>
                      ))}
                    {adminModifySale && loggedInUser().role === "ADMIN" && step === 7 && (
                      <Step7Container
                        associatedLeadIds={
                          dataAssociated?.fetchAssociateContact?.map((lead: any, i: number) => lead.id) || []
                        }
                        primaryLeadId={dataLead?.fetchLead?.id}
                        filterByAssociateContact={filterByAssociateContact}
                      />
                    )}
                  </AppSidebarCard>
                </Right>
              ) : (
                <MergeContactModalWrap>
                  <MergeContactsModal />
                </MergeContactModalWrap>
              )}
              {renderCustomObject()}
            </GridDiv>
          ) : (
            <FlexDiv justify="center" style={{ width: "100%" }}>
              <AssociateWarning>
                <AppText fontSize={14}>
                  Note: This is an associated contact.{" "}
                  <PrimaryText
                    onClick={() =>
                      window.open(
                        `/lead-detail/${dataAssociatedParent?.fetchLead?.associate_parent_id}`,
                        "_blank",
                        "noreferrer",
                      )
                    }
                  >
                    Go to primary
                  </PrimaryText>
                </AppText>
              </AssociateWarning>
            </FlexDiv>
          )}
        </Main>
      )}
    </Sentry.ErrorBoundary>
  );
};

interface AssociatedContactDropdownProps {
  data: any;
  leadData: any;
  index: number;
}

interface AlternateContactsType {
  id: string;
  title: string;
  channel: "Phone" | "Email" | "Conference";
  type: string;
  value: string;
}

const AssociatedContactDropdown: React.FC<AssociatedContactDropdownProps> = ({ data, leadData, index }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { upcomingDials } = useContext(LeadCardContext);

  const {
    setSwapPrimaryLeadModalData,
    setShowDisassociateLeadModal,
    setShowSwapPrimaryLeadModal,
    setSelectedContactV2,
    setShowAddOrEditContactModal,
    showSMSChat,
    closeCustomFieldsModal,
    openSMSChat,
    showEmailEditorModal,
    openEmailEditorModal,
    setShowAddAssociateModal,
  } = useContext(ModalContext);

  const { goToCall, resetNextDial, callState } = useContext(CallContext);

  const selectContactToEdit = (selectedContact: SelectedContactType) => {
    // handleNumberEdit()
    // old system
    // setSelectedContact(selectedContact as SelectedContactType);
    // setEditCardType("Edit");
    // setShowEditCardComponent(true);
    setSelectedContactV2(selectedContact);
    setShowAddOrEditContactModal(true);
  };

  interface addContactModalProps {
    lead_id: string;
    country_code: string;
    card_type: string;
    has_conference_number: boolean;
  }
  const selectContactToAdd = ({ lead_id, country_code, card_type, has_conference_number }: addContactModalProps) => {
    setSelectedContactV2({ lead_id, country_code, card_type, has_conference_number });
    setShowAddOrEditContactModal(true);
  };

  const goToCallObject = {
    // phoneNumber: set in the goToCall()
    lead_id: data?.id,
    intentId: data?.next_intent_scheduled_or_unscheduled?.id
      ? data?.next_intent_scheduled_or_unscheduled?.id
      : undefined,
    intentReplacementId: data?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
      ? data?.next_intent_scheduled_or_unscheduled?.replacement_lead_intent_id
      : undefined,
    dialAsNumber: undefined,
  };

  const isInternational = loggedInUser().organization?.international;

  const isOwnedByRep =
    ["Owned", "Assigned"].includes(leadData?.lead_ownership_status) && leadData?.rep_id === loggedInUser()?.id;

  const leadUnsubscribedFromEmail =
    leadData?.email_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.fetchLead?.email_sub_status === CommunicationStates.LeadUnsubscribed;

  const leadUnsubscribedFromSMS =
    leadData?.fetchLead?.sms_sub_status === CommunicationStates.OPSIQUnsubscribed ||
    leadData?.fetchLead?.sms_sub_status === CommunicationStates.LeadUnsubscribed;

  const [changePrimaryNumber, { loading: loadingChangePrimaryNumber, error: errorChangePrimaryNumber }] = useMutation(
    SWAP_PRIMARY_NUMBER,
    {
      onCompleted({ swapPrimaryNumberOnLead }) {
        if (!swapPrimaryNumberOnLead) {
          appToast("Error: Something went wrong while editing number");
          return;
        }
        appToast("Changed primary number");
      },
      onError({ message }) {
        appToast(message);
        Sentry.captureEvent({
          message: `swapPrimaryNumberOnLead GraphQL Error: ${message}`,
        });
        console.log("Error in swapPrimaryNumberOnLead: ", message);
      },
      refetchQueries: ["fetchLead", "FetchAssociateContact"],
    },
  );

  return (
    <ACDContianer direction="column">
      <PhoenixStyledTooltip id="associated-header-tooltip" />
      <ACDHeader justify="space-between">
        <FlexDiv align="center" gap={16}>
          <StatCard icon={associated_white} text="ASSOCIATED" variant="secondary" />
          {data?.full_name || "No Name"}
          <EditButton leadId={data?.id} />
        </FlexDiv>
        {expanded ? (
          <FiChevronUp color={theme.PRIMARY500} size={24} cursor="pointer" onClick={() => setExpanded(!expanded)} />
        ) : (
          <FiChevronDown color={theme.PRIMARY500} size={24} cursor="pointer" onClick={() => setExpanded(!expanded)} />
        )}
      </ACDHeader>
      {expanded && (
        <>
          {/* body */}
          {!!data?.primary_phone_number && (
            <ContactInfoContainer>
              <FlexDiv direction="column">
                <FlexDiv align="center">
                  <FiEdit
                    size={14}
                    color={theme.PRIMARY500}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "edit associated contact clicked",
                        id: data?.id,
                        source: "leadDetails",
                      });
                      selectContactToEdit({
                        lead_id: data?.id ?? "",
                        id: data?.id ?? "",
                        category: "Primary",
                        phone_number: data?.primary_phone_number,
                        local_phone_number: data?.local_primary_phone_number,
                        conference_number: data?.conference_number,
                        country_code: data?.primary_phone_number_country_code,
                        channel: "Phone",
                        email: "",
                        title: data?.primary_phone_number_title,
                        type: data?.primary_phone_number_type,
                        card_type: "Edit",
                      });
                    }}
                  />
                  <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                    Primary Phone
                  </AppText>
                </FlexDiv>

                <FlexDiv direction="column">
                  {data?.primary_phone_number_title ? (
                    <ContactInfoTitle>
                      {data?.primary_phone_number_title}{" "}
                      {data?.primary_phone_number_type ? ` (${data?.primary_phone_number_type})` : ``}
                    </ContactInfoTitle>
                  ) : (
                    ""
                  )}
                  <ContactText>{formatPhoneNumber(data?.primary_phone_number)}</ContactText>
                </FlexDiv>
              </FlexDiv>
              {loggedInUser().role !== "ADMIN" && (
                <IconDiv>
                  <ActionButton
                    disabled={!isOwnedByRep}
                    onClick={() => {
                      closeCustomFieldsModal();
                      MixpanelActions.track("Lead Card", {
                        type: "associated contact call clicked",
                        id: data?.id,
                        source: "leadDetails",
                      });
                      goToCall({
                        phoneNumber: data?.primary_phone_number,
                        ...goToCallObject,
                      });
                      resetNextDial();
                    }}
                    callState={callState}
                  >
                    <img src={phone_primary} alt="Call" style={{ width: "16px", height: "16px" }} />
                  </ActionButton>

                  {/* Hide UI from international organizations. */}
                  {!isInternational && (
                    <TooltipWrapperDiv
                      data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                      data-for={leadUnsubscribedFromSMS ? "associated-header-tooltip" : undefined}
                    >
                      <ActionButton
                        // small
                        disabled={!isOwnedByRep || leadUnsubscribedFromSMS}
                        onClick={() => {
                          if (showSMSChat) {
                            appToast("There is currently a chat open. Please close the existing chat before opening.");
                            return;
                          }
                          MixpanelActions.track("Lead Card", {
                            type: "associated contact sms clicked",
                            id: data?.id,
                            source: "leadDetail",
                          });

                          openSMSChat({
                            lead_id: data?.id ?? "",
                            phone_number: data?.primary_phone_number,
                            intent:
                              // check if the upcoming dials top intent lead is the same and if so pass that intent in
                              // the current check is for suggested action but active queue should be checked as well
                              upcomingDials?.[0]?.lead?.id === leadData?.id ? upcomingDials?.[0] : undefined,
                          });
                        }}
                      >
                        <img src={chat} alt="SMS" style={{ width: "16px", height: "16px" }} />
                      </ActionButton>
                    </TooltipWrapperDiv>
                  )}
                </IconDiv>
              )}
            </ContactInfoContainer>
          )}

          {!!data?.primary_email && (
            <ContactInfoContainer>
              <FlexDiv direction="column">
                <FlexDiv align="center">
                  <FiEdit
                    size={14}
                    color={theme.PRIMARY500}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "edit associated contact clicked",
                        id: data?.id,
                        source: "leadDetail",
                      });
                      selectContactToEdit({
                        lead_id: data?.id ?? "",
                        category: "Primary",
                        phone_number: "",
                        local_phone_number: data?.local_primary_phone_number,
                        conference_number: data?.conference_number,
                        country_code: data?.primary_phone_number_country_code,
                        channel: "Email",
                        email: data?.primary_email,
                        title: data?.primary_email_title,
                        type: data?.primary_email_type,
                        card_type: "Edit",
                      });
                    }}
                  />
                  <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                    Primary Email
                  </AppText>
                </FlexDiv>
                <FlexDiv direction="column">
                  {data?.primary_email_title ? (
                    <ContactInfoTitle>
                      {data?.primary_email_title} {data?.primary_email_type ? ` (${data?.primary_email_type})` : ``}
                    </ContactInfoTitle>
                  ) : (
                    ""
                  )}
                  <ContactText>{data?.primary_email}</ContactText>
                </FlexDiv>
              </FlexDiv>
              {loggedInUser().role !== "ADMIN" && (
                <IconDiv
                  data-tip={leadUnsubscribedFromEmail ? EMAIL_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                  data-for={leadUnsubscribedFromEmail ? "associated-header-tooltip" : undefined}
                >
                  <ActionButton
                    // small
                    disabled={!isOwnedByRep || leadUnsubscribedFromEmail}
                    // callState={callState}
                    onClick={() => {
                      !showEmailEditorModal &&
                        openEmailEditorModal({
                          lead_id: data?.id ?? "",
                          full_name: data?.full_name,
                          email: data?.primary_email,
                          intent: leadData?.id === upcomingDials?.[0]?.lead?.id ? upcomingDials?.[0] : undefined,
                          isManualEmailSequenceStep:
                            leadData?.id === upcomingDials?.[0]?.lead?.id
                              ? upcomingDials?.[0]?.lead?.sequence_step?.actions?.[0]?.task === "manualEmail"
                              : false,
                          sequenceStepId:
                            leadData?.id === upcomingDials?.[0]?.lead?.id
                              ? upcomingDials?.[0]?.lead?.sequence_step?.id
                              : "",
                        });
                    }}
                  >
                    <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
                  </ActionButton>
                </IconDiv>
              )}
            </ContactInfoContainer>
          )}

          {data?.conference_number && (
            <ContactInfoContainer>
              <FlexDiv direction="column">
                <FlexDiv align="center">
                  <FiEdit
                    size={14}
                    color={theme.PRIMARY500}
                    style={{ cursor: "pointer", marginRight: "8px" }}
                    onClick={() => {
                      MixpanelActions.track("Lead Card", {
                        type: "edit associated contact clicked",
                        id: data?.id,
                        source: "leadDetail",
                      });

                      const regex = `+${data?.primary_phone_number_country_code ?? "1"}`;
                      selectContactToEdit({
                        lead_id: data?.id ?? "",
                        category: "Primary",
                        conference_number: data?.conference_number?.replace(regex, "") ?? "",
                        country_code: data?.primary_phone_number_country_code,
                        channel: "Conference",
                        email: "",
                        title: data?.primary_phone_number_title,
                        type: data?.primary_phone_number_type,
                        country: data?.country,
                        card_type: "Edit",
                      });
                    }}
                  />
                  <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                    Screen Sharing Number
                  </AppText>
                </FlexDiv>
                <ContactInfoTitle>
                  <ContactText>{formatPhoneNumber(data?.conference_number)}</ContactText>
                </ContactInfoTitle>
              </FlexDiv>
              {loggedInUser().role !== "ADMIN" && (
                <IconDiv>
                  <ActionButton
                    // small

                    disabled={!isOwnedByRep}
                    onClick={() => {
                      closeCustomFieldsModal();
                      MixpanelActions.track("Lead Card", {
                        type: "associated call clicked",
                        id: data?.id,
                        source: "leadDetail",
                      });
                      goToCall({
                        phoneNumber: data?.conference_number,
                        ...goToCallObject,
                      });
                      resetNextDial();
                    }}
                    callState={callState}
                  >
                    <img src={phone_primary} alt="Call" style={{ width: "16px", height: "16px" }} />
                  </ActionButton>

                  {!isInternational && (
                    <TooltipWrapperDiv
                      data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                      data-for={leadUnsubscribedFromSMS ? "associated-header-tooltip" : undefined}
                    >
                      <ActionButton
                        // small
                        disabled={!isOwnedByRep || leadUnsubscribedFromSMS}
                        onClick={() => {
                          if (showSMSChat) {
                            appToast("There is currently a chat open. Please close the existing chat before opening.");
                            return;
                          }
                          MixpanelActions.track("Lead Card", {
                            type: "sms clicked",
                            id: data?.id,
                            source: "leadDetail",
                          });

                          openSMSChat({
                            lead_id: data?.id ?? "",
                            phone_number: data?.conference_number,
                            intent:
                              // check if the upcoming dials top intent lead is the same and if so pass that intent in
                              // the current check is for suggested action but active queue should be checked as well
                              upcomingDials?.[0]?.lead?.id === leadData?.id ? upcomingDials?.[0] : undefined,
                          });
                        }}
                      >
                        <img src={chat} alt="SMS" style={{ width: "16px", height: "16px" }} />
                      </ActionButton>
                    </TooltipWrapperDiv>
                  )}
                </IconDiv>
              )}
            </ContactInfoContainer>
          )}

          {!!data?.alternate_contacts &&
            data?.alternate_contacts?.map(
              (item: AlternateContactsType) =>
                !!item.value && (
                  <ContactInfoContainer key={item?.id}>
                    <FlexDiv direction="column">
                      <FlexDiv align="center">
                        <FiEdit
                          size={16}
                          color={theme.PRIMARY500}
                          style={{ marginRight: "8px", cursor: "pointer" }}
                          onClick={() => {
                            const regex = `+${data?.primary_phone_number_country_code ?? "1"}`;
                            MixpanelActions.track("Lead Card", {
                              type: "edit associate contact clicked",
                              id: leadData?.id,
                              source: "leadDetail",
                            });
                            selectContactToEdit({
                              lead_id: data?.id ?? "",
                              id: item.id,
                              category: "Alternate",
                              phone_number: item?.channel === "Phone" ? item.value : "",
                              local_phone_number: item?.channel === "Phone" ? item.value.replace(regex, "") : "",
                              country_code: data?.primary_phone_number_country_code,
                              email: item?.channel === "Email" ? item.value : "",
                              channel: item?.channel,
                              title: item.title,
                              type: item.type,
                              card_type: "Edit",
                            });
                          }}
                        />
                        <AppText fontSize={12} fontWeight={400} style={{ color: theme.PRIMARY500 }}>
                          Alternate {item?.channel === "Phone" ? "Phone" : "Email"}
                        </AppText>
                      </FlexDiv>
                      <FlexDiv direction="column">
                        {item.title ? (
                          <ContactInfoTitle>
                            {item.title} {item.type ? ` (${item.type})` : ``}
                          </ContactInfoTitle>
                        ) : (
                          ""
                        )}
                        <ContactText>
                          {item?.channel === "Phone" ? formatPhoneNumber(item.value) : item.value}
                        </ContactText>
                        {item?.channel === "Phone" && (
                          <AppText
                            variant="primary"
                            onClick={async () => await changePrimaryNumber({ variables: { contact_id: item.id } })}
                            style={{ borderBottom: "none", cursor: "pointer", color: theme.PRIMARY500 }}
                          >
                            Make Primary Phone
                          </AppText>
                        )}
                      </FlexDiv>
                    </FlexDiv>
                    {loggedInUser().role !== "ADMIN" && (
                      <IconDiv>
                        {item?.channel !== "Phone" ? (
                          <ActionButton
                            disabled={!isOwnedByRep || leadUnsubscribedFromEmail}
                            // callState={callState}
                            onClick={() => {
                              //set email editor
                              MixpanelActions.track("Lead Card", {
                                type: "associated email clicked (not primary)",
                                id: data?.id,
                                source: "leadDetail",
                              });
                              !showEmailEditorModal &&
                                openEmailEditorModal({
                                  lead_id: data?.id ?? "",
                                  full_name: data?.full_name,
                                  email: item.value,
                                  intent:
                                    leadData?.id === upcomingDials?.[0]?.lead?.id ? upcomingDials?.[0] : undefined,
                                  isManualEmailSequenceStep:
                                    leadData?.id === upcomingDials?.[0]?.lead?.id
                                      ? upcomingDials?.[0]?.lead?.sequence_step?.actions?.[0]?.task === "manualEmail"
                                      : false,
                                  sequenceStepId:
                                    leadData?.id === upcomingDials?.[0]?.lead?.id
                                      ? upcomingDials?.[0]?.lead?.sequence_step?.id
                                      : "",
                                });
                            }}
                          >
                            <PhoenixIcon svg={mail} variant="brand" alt="Email" size="small" />
                          </ActionButton>
                        ) : (
                          <>
                            <ActionButton
                              disabled={!isOwnedByRep}
                              callState={callState}
                              onClick={() => {
                                MixpanelActions.track("Lead Card", {
                                  type: "call clicked (not primary)",
                                  id: data?.id,
                                  source: "leadDetail",
                                });
                                closeCustomFieldsModal();
                                goToCall({
                                  phoneNumber: item.value,
                                  ...goToCallObject,
                                });
                                resetNextDial();
                              }}
                            >
                              <img src={phone_primary} alt="Call" style={{ width: "16px", height: "16px" }} />
                            </ActionButton>
                            {!isInternational && (
                              <TooltipWrapperDiv
                                data-tip={leadUnsubscribedFromSMS ? SMS_UNSUBSCRIBED_REP_TOOLTIP : undefined}
                                data-for={leadUnsubscribedFromSMS ? "associated-header-tooltip" : undefined}
                              >
                                <ActionButton
                                  disabled={!isOwnedByRep || leadUnsubscribedFromSMS}
                                  onClick={() => {
                                    if (showSMSChat) {
                                      appToast(
                                        "There is currently a chat open. Please close the existing chat before opening.",
                                      );
                                      return;
                                    }
                                    MixpanelActions.track("Lead Card", {
                                      type: "sms clicked",
                                      id: data?.id,
                                      source: "leadDetail",
                                    });
                                    openSMSChat({
                                      lead_id: data?.id ?? "",
                                      phone_number: item.value,
                                      intent:
                                        // check if the upcoming dials top intent lead is the same and if so pass that intent in
                                        // the current check is for suggested action but active queue should be checked as well
                                        upcomingDials?.[0]?.lead?.id === leadData?.id ? upcomingDials?.[0] : undefined,
                                    });
                                  }}
                                >
                                  <img src={chat} alt="SMS" style={{ width: "16px", height: "16px" }} />
                                </ActionButton>
                              </TooltipWrapperDiv>
                            )}
                          </>
                        )}
                      </IconDiv>
                    )}
                  </ContactInfoContainer>
                ),
            )}

          {/* footer */}

          <ACDropdownFooter justify="space-between">
            <FlexDiv gap={16} align="center">
              <FooterAction
                onClick={() => {
                  setSwapPrimaryLeadModalData({
                    primary_lead_id: leadData?.id,
                    associate_lead_id: data?.id,
                    rep_id: data?.rep_id,
                    associate_primary_phone_number: data?.primary_phone_number,
                  });
                  setShowDisassociateLeadModal(true);
                }}
              >
                <img src={arrowsLeftAndRight} alt="arrows" style={{ width: "16px", height: "16px" }} />
                <AppText style={{ color: theme.DANGER600 }}>Disassociate</AppText>
              </FooterAction>
              <FooterAction
                onClick={() => {
                  setSwapPrimaryLeadModalData({
                    primary_lead_id: leadData?.id,
                    associate_lead_id: data?.id,
                    associate_primary_phone_number: data?.primary_phone_number,
                  });
                  setShowSwapPrimaryLeadModal(true);
                }}
              >
                <img src={arrowsUpAndDown} alt="arrows" style={{ width: "16px", height: "16px" }} />
                <AppText variant="primary">Make Primary</AppText>
              </FooterAction>
            </FlexDiv>

            <NewAppButton
              variant="primary"
              style={{
                fontSize: "10px",
                fontWeight: 600,
                width: "40px",
                height: "40px",
                padding: "2px 0px 0px 0px",
              }}
              onClick={() => {
                MixpanelActions.track("Lead Card", {
                  type: "add alternate associated contact",
                  id: data?.id,
                  source: "leadDetail",
                });
                selectContactToAdd({
                  lead_id: data?.id ?? "",
                  country_code: data?.primary_phone_number_country_code,
                  card_type: "Add",
                  // if the lead already has a conference number they can't add another
                  has_conference_number: !!data?.conference_number,
                });

                setShowAddOrEditContactModal(true);
                setShowAddAssociateModal(false);
              }}
              data-tip="Add alternate contact:|Phone, Email, Screensharing number"
              data-for={`alt-contact-info-tooltip-${index}`}
            >
              <StyledTooltip
                multiline
                effect="solid"
                id={`alt-contact-info-tooltip-${index}`}
                backgroundColor={theme.PRIMARY800}
                getContent={(dataTip) => (
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "10px",
                      lineHeight: "14px",
                      width: "180px",
                    }}
                  >
                    <div>{dataTip?.split("|")[0]}</div>
                    <div>{dataTip?.split("|")[1]}</div>
                  </div>
                )}
              />
              <img src={contact_info_add} alt="add alternative contact information" />
            </NewAppButton>
          </ACDropdownFooter>
        </>
      )}
    </ACDContianer>
  );
};

const HeaderTabs = ({
  step,
  handleStepChange,
  adminModifySale,
}: {
  step: number;
  handleStepChange: (step: string) => void;
  adminModifySale: boolean;
}) => {
  return (
    <TabsDiv>
      <HistoryTab
        thisTab={1}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Lead Detail", {
            type: "detail tab clicked",
            tab: "Result History",
          });
          handleStepChange("1");
        }}
      >
        <HistoryTabTitle thisTab={1} tab={step}>
          RESULT HISTORY
        </HistoryTabTitle>
      </HistoryTab>

      <HistoryTab
        thisTab={2}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Lead Detail", {
            type: "detail tab clicked",
            tab: "SMS Messages",
          });
          handleStepChange("2");
        }}
      >
        <HistoryTabTitle thisTab={2} tab={step}>
          SMS MESSAGES
        </HistoryTabTitle>
      </HistoryTab>

      <HistoryTab
        thisTab={3}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Lead Detail", {
            type: "detail tab clicked",
            tab: "Email",
          });
          handleStepChange("3");
        }}
      >
        <HistoryTabTitle thisTab={3} tab={step}>
          EMAIL
        </HistoryTabTitle>
      </HistoryTab>

      <HistoryTab
        thisTab={4}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Lead Detail", {
            type: "detail tab clicked",
            tab: "Call Notes",
          });
          handleStepChange("4");
        }}
      >
        <HistoryTabTitle thisTab={4} tab={step}>
          CALL NOTES
        </HistoryTabTitle>
      </HistoryTab>

      <HistoryTab
        thisTab={5}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Ownership History", {
            type: "detail tab clicked",
            tab: "Ownership History",
          });
          handleStepChange("5");
        }}
      >
        <HistoryTabTitle thisTab={5} tab={step}>
          OWNERSHIP HISTORY
        </HistoryTabTitle>
      </HistoryTab>

      <HistoryTab
        thisTab={6}
        tab={step}
        onClick={() => {
          MixpanelActions.track("Association History", {
            type: "detail tab clicked",
            tab: "Association History",
          });
          handleStepChange("6");
        }}
      >
        <HistoryTabTitle thisTab={6} tab={step}>
          ASSOCIATION HISTORY
        </HistoryTabTitle>
      </HistoryTab>
      {APP_ENABLEMENT_ROLES.includes(loggedInUser()?.role as APP_ROLES) && adminModifySale && (
        <HistoryTab
          thisTab={7}
          tab={step}
          onClick={() => {
            MixpanelActions.track("Association History", {
              type: "detail tab clicked",
              tab: "Association History",
            });
            handleStepChange("7");
          }}
        >
          <HistoryTabTitle thisTab={7} tab={step}>
            SALE
          </HistoryTabTitle>
        </HistoryTab>
      )}
    </TabsDiv>
  );
};

const ACDropdownFooter = styled(FlexDiv)`
  padding-top: 16px;
`;

interface EditButtonProps {
  leadId: string;
}

const EditButton = ({ leadId }: EditButtonProps) => {
  const { setEditModalLeadId, setEditModal } = useContext(ModalContext);

  return (
    <div
      onClick={() => {
        MixpanelActions.track("Lead Card", {
          type: "associated contact edit form clicked",
          id: leadId,
          source: "leadDetail",
        });
        setEditModalLeadId(leadId);
        setEditModal(true);
      }}
      style={{ display: "flex", alignItems: "center" }}
    >
      <FiEdit size={16} color={theme.PRIMARY500} cursor="pointer" />
    </div>
  );
};

interface INumberDiv {
  primary?: boolean;
  callState?: boolean;
  // noBorder?: boolean;
}
const ActionButton = styled.button<INumberDiv>`
  display: ${(props) => (props.callState ? "none" : "auto")};
  height: 40px;
  width: 40px;
  margin-right: 4px;
  margin-left: 4px;
  background-color: ${theme.LIGHT_BLUE};
  border: 1px solid ${theme.PRIMARY500};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  // for disabled fade out
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Left = styled.div`
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Right = styled.div`
  height: 100%;
  overflow-y: auto;
  max-height: 100vh;
`;

const ContactText = styled(AppText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.2px;
  color: ${theme.PRIMARY800};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;

const ContactInfoTitle = styled(AppText)`
  /* opacity: 0.5;
  padding: 1px; */
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: ${theme.LIGHT_GRAY};
`;

const ContactInfoContainer = styled.div`
  width: 100%;
  /* height: 56px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const DarkDiv2 = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -72px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const FooterAction = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;

  :hover {
    filter: brightness(0.8);
  }
`;

const LeadHistoryHeader = styled(FlexDiv)`
  width: 100%;
  padding: 16px;
`;

const ACDHeader = styled(FlexDiv)`
  width: 100%;
`;

const ACDContianer = styled(FlexDiv)`
  padding: 16px;
  border-radius: 4px;
  background-color: ${theme.LIGHT_BLUE};
`;

const EllipsisText = styled(AppText)`
  font-size: 14px;
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface UnreadProp {
  unread?: boolean;
}

const EmailText = styled(AppText)<UnreadProp>`
  font-weight: ${(props) => (props.unread ? 600 : "normal")};
`;

const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EmailDiv = styled.div<{ isHot: boolean }>`
  background-color: ${theme.WHITE_COLOR};
  width: 100%;
  margin: 0px;
  padding: 0px 24px;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  display: flex;
  align-items: center;
  height: 78px;
  cursor: pointer;
  :hover {
    background-color: ${theme.NEUTRAL100};
  }
  gap: 16px;

  ${(props) =>
    props.isHot &&
    `background-color: ${theme.fill.dataviz4.secondary};
  border: solid ${theme.border.dataviz4.inverse};
    border-width: 1px 0px;
    :hover {
    background-color: ${theme.fill.dataviz4.secondary};
  }
  `}
`;

const SyncInfoText = styled(AppText)`
  color: ${theme.NEUTRAL300};
  width: 100%;
  padding: 11px 24px 11px 24px;
`;

interface MessageProps {
  isFromRep: boolean;
}

const PictureDiv = styled.div<MessageProps>`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  color: ${theme.NEUTRAL400};
  background-color: ${(props) => (props.isFromRep ? theme.WHITE_COLOR : theme.NEUTRAL200)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
`;

const MessageDialogDivV2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  padding-bottom: 0px;
`;

const NothingHereText = styled(AppText)`
  padding: 24px 32px;
`;

const MinHeightDiv = styled.div`
  min-height: 517px;
  max-height: 517px;

  overflow: auto;
  padding-bottom: 24px;
`;

const CallNoteTime = styled(AppText)`
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 24px;
`;
const CallNoteText = styled(AppText)`
  margin-top: 8px;
  font-size: 14px;
  padding: 0px 24px;
`;

const TimeText = styled(AppText)`
  margin-left: 8px;

  :hover {
    cursor: pointer;
  }
`;

const CenterFlex = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ScheduledEventDiv = styled.div`
  height: 68px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
`;

interface ActiveTabProps {
  tab: number;
  thisTab: number;
}

const HistoryTabTitle = styled(AppText)<ActiveTabProps>`
  white-space: nowrap;
  font-size: 10px;
  letter-spacing: 1px;
  margin: auto;
  font-weight: 600;
  color: ${(props) => (props.thisTab === props.tab ? theme.BLACK_COLOR : theme.NEUTRAL300)};
  line-height: 16px;
  :hover {
    cursor: pointer;
  }
`;

const HistoryTab = styled.div<ActiveTabProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 36px;
  max-height: 36px;
  padding-right: 24px;
  padding-left: 24px;

  border-bottom: ${(props) => (props.thisTab === props.tab ? `solid 3px ${theme.PRIMARY500}` : "normal")};
  transition: border-bottom 0.05s ease;
`;

const TabsDiv = styled.div`
  display: flex;
  justify-content: flex-start;

  border-top: solid 1px ${theme.NEUTRAL200};
  border-bottom: solid 1px ${theme.NEUTRAL200};

  overflow-x: auto;
  cursor: pointer;
`;

type GridProps = {
  customObject?: boolean;
};
const GridDiv = styled.div<GridProps>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: ${(props) =>
    props.customObject ? "432px minmax(600px, 1fr) 432px" : "600px minmax(500px, 1fr)"};
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  padding: 0px 24px 24px 24px;
  /* background: ${theme.fill.neutral.secondary}; */
`;

const LeadAssignedToText = styled(AppText)`
  font-size: 14px;
  line-height: 20px;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 98px;

  margin-bottom: 24px;
  padding: 0px 24px 0px 24px;
  background-color: ${theme.WHITE_COLOR};
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${theme.NEUTRAL100};

  align-items: center;
  @media ${veryLargeDesktopSize} {
    align-items: flex-start;
    min-width: auto;
  }
`;

const KeyEventDiv = styled.div`
  border-radius: 4px;
  color: ${theme.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 4px 8px 4px 8px;
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
`;
const Divider = styled.div`
  width: 100%;
  background-color: ${theme.NEUTRAL200};
  height: 1px;
`;

const CenterModalDiv = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-around;
  align-items: center;
`;
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-around;
  width: 100%;
  padding: 40px;
`;

const KeyEventsDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-start;
  padding-bottom: 8px;
  flex-wrap: wrap;
  max-width: 70px;
`;

const OptionsDiv = styled.div`
  position: absolute;
  width: 120px;
  right: 40px;
  top: 75%;
  background-color: ${theme.WHITE_COLOR};
  border: 1px solid ${theme.NEUTRAL200};
  border-radius: 4px;
  z-index: 1;

  animation: ${theme.popup} 0.2s ease;
`;

const SidebarContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
`;

const SidebarWrapper = styled.div`
  position: relative;
  border-right: 1px solid ${theme.NEUTRAL100};
  padding-bottom: 100px;
`;
const Option = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding: 16px;
  background-color: ${theme.WHITE_COLOR};
  :first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  :hover {
    background-color: ${theme.NEUTRAL200};
    cursor: pointer;
  }
`;

const LabelText = styled(AppText)`
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const ImageBorder = styled.div`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid ${theme.PRIMARY500};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AssociateWarning = styled.div`
  width: fit-content;
  margin-top: 10%;
  padding: 8px 12px 8px 12px;
  background-color: ${theme.ATTENTION100};
  border: 1px solid ${theme.ATTENTION500};
  border-radius: 4px;
`;

const PrimaryText = styled.span`
  color: ${theme.PRIMARY500};
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const AssociatedContactsContainer = styled.div`
  height: fit-content;
  max-height: 600px;
  padding: 16px;
`;

const ACButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const ACButton = styled(NewAppButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 134px;
`;

const MergeContactModalWrap = styled.div`
  height: 100vh;
`;

const ACBody = styled(FlexDiv)`
  height: 100%;
  max-height: 500px;
  overflow: auto;
`;

const TooltipWrapperDiv = styled.div``;

const FullViewportDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 16px;
`;

const NoRelatedRecordDiv = styled.div`
  padding: 16px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const FlameIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;

  border-radius: 4px;
  background-color: ${theme.fill.dataviz4.inverse};
`;

export { LeadDetailV3 };
