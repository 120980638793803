import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useClickOutside } from "src/utils/hooks";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv } from "../../UI";
import { PhoenixIcon } from "../../UI/Phoenix";
import { settings, link, more_vertical, users } from "../../../images/NewDesign";
import CallParticipantsMenu from "./CallParticipantsMenu";

interface CallMoreMenuProps {
  businessName?: string;
  isExpandedCallView: boolean;
  onAudioSettings: () => void;
  onInviteLink: () => void;
  participants?: string[];
  refetchParticipants: () => void;
}

const CallMoreMenu: React.FC<CallMoreMenuProps> = ({
  onAudioSettings,
  onInviteLink,
  isExpandedCallView,
  participants,
  businessName,
  refetchParticipants,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);

  useClickOutside(triggerRef, () => setIsOpen(false));

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuWrapper ref={triggerRef}>
      <TriggerButton size={isExpandedCallView ? 40 : 24} onClick={handleClick}>
        <PhoenixIcon
          svg={more_vertical}
          size={24}
          color={theme.text.neutral.inverse}
          pointer
          hoverColor={theme.text.neutral.inverse}
        />
      </TriggerButton>

      <MenuContainer isOpen={isOpen}>
        {!isExpandedCallView ? (
          <CallParticipantsMenu
            participants={participants}
            businessName={businessName}
            refetchParticipants={refetchParticipants}
          >
            {(openParticipantsMenu) => (
              <MenuItem
                onClick={() => {
                  openParticipantsMenu();
                }}
              >
                <PhoenixIcon svg={users} variant="neutral" size={16} />
                <AppText fontSize={14} color={theme.text.neutral.primary}>
                  Call Participants
                </AppText>
              </MenuItem>
            )}
          </CallParticipantsMenu>
        ) : null}

        <MenuItem
          onClick={() => {
            onAudioSettings();
            setIsOpen(false);
          }}
        >
          <PhoenixIcon svg={settings} variant="neutral" size={16} />
          <AppText fontSize={14} color={theme.text.neutral.primary}>
            Audio Settings
          </AppText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onInviteLink();
            setIsOpen(false);
          }}
        >
          <PhoenixIcon svg={link} variant="neutral" size={16} />
          <AppText fontSize={14} color={theme.text.neutral.primary}>
            Call Invite Link
          </AppText>
        </MenuItem>
      </MenuContainer>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: relative;
`;

const TriggerButton = styled.button<{ size: number }>`
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  height: ${({ size }) => size}px;
  justify-content: center;
  padding: 8px;
  transition: background-color 0.2s ease;
  width: ${({ size }) => size}px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  background: ${theme.surface.neutral.primary};
  border-radius: 8px;
  bottom: calc(100% + 8px); // Position above the trigger
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  padding: 8px;
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  position: absolute;
  right: 0;
  scale: ${({ isOpen }) => (isOpen ? 1 : 0.96)};
  transition: all 0.3s ease;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  width: 200px;
  z-index: 1000;
`;

const MenuItem = styled(FlexDiv)`
  padding: 8px;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background: ${theme.surface.neutral.secondary};
  }
`;

export default CallMoreMenu;
