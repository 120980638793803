import "ag-grid-community/styles/ag-grid.css";
import { AgGridReact } from "ag-grid-react";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef } from "react";
import { SaveToFolderModal } from "src/Components/modal";
import { CallResultPill } from "src/Components/UI/Pills";
import { SORTABLE_ALL_CALLS_TABLE_COLUMNS, SortOption } from "src/types";
import styled from "styled-components";
import { caret_down, phone_primary, video } from "../../../images/NewDesign";
import { useFirstRender, useModalContext } from "../../../utils/hooks";
import { convertSecondsToTimestamp } from "../../../utils/misc";
import { theme } from "../../../utils/theme";
import { AppText, FlexDiv, Loading } from "../../UI";
import { PhoenixIcon } from "../../UI/Phoenix";
import { CallReportActionCell } from "../CallReportV2Segments/CallReportActionCell";
import { ProfileImage } from "../SettingSegments/ProfileImage";
import "./AllCallsTable.css";

interface AllCallsTableProps {
  data: ExpectedCallObject[];
  setCurrentConferenceID: Dispatch<SetStateAction<string>>;
  tableSort: SortOption;
  sortOrder: "asc" | "desc";
  setTableSort: Dispatch<SetStateAction<SortOption>>;
  setSortOrder: Dispatch<SetStateAction<"asc" | "desc">>;
  loading: boolean;
}

interface ExpectedCallObject {
  recording_url_computed: string;
  conference_id: string;
  lead_id: string;
  profile_image: string;
  duration: number;
  type: string;
  business_name: string;
  created_at: string;
  person_spoke_to: string;
  salesperson: string;
  drive_recording_links: string[];
  label: string;
}
export const AllCallsTableV2: React.FC<AllCallsTableProps> = ({
  data,
  tableSort,
  setTableSort,
  sortOrder,
  setSortOrder,
  loading,
}) => {
  const handleColumnClick = useCallback(
    (sortOption: SortOption) => {
      setTableSort(sortOption);
      if (tableSort === sortOption) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortOrder("asc");
      }
    },
    [tableSort, sortOrder],
  );

  const gridRef = useRef<AgGridReact>(null);

  const rowData = useMemo(() => {
    return data?.map((call: ExpectedCallObject) => ({
      created_at: moment(call?.created_at).format("MM/DD/YYYY hh:mm A"),
      rep_name: {
        profile_image: call?.profile_image,
        name: call?.salesperson,
      },
      media_type: call?.drive_recording_links?.length ? "Video" : "Audio",
      business_name: {
        lead_id: call?.lead_id,
        business_name: call?.business_name,
      },
      person_spoke_to: call?.person_spoke_to,
      label: call?.label,
      duration: {
        duration: call?.duration,
        recording_url_computed: call?.recording_url_computed,
        conference_id: call?.conference_id,
      },
      more: call?.conference_id,
      handleColumnClick: (field: string) => handleColumnClick(field as SortOption),
    }));
  }, [data, handleColumnClick]);
  const { saveCallToLibraryModal, setSaveCallToLibraryModal, currentConferenceID } = useModalContext();

  const CustomHeaderCell = (props: {
    displayName: string;
    column: { getColId: () => string; getDisplayName: () => string };
  }) => {
    const columnIsBeingSorted = props?.column?.getColId() === tableSort;

    const columnIsSortable = SORTABLE_ALL_CALLS_TABLE_COLUMNS.includes(props?.column?.getColId());

    return (
      <Cell
        onClick={() => {
          if (columnIsSortable) {
            handleColumnClick(props?.column?.getColId() as SortOption);
          }
        }}
        style={{ cursor: columnIsSortable ? "pointer" : "default" }}
      >
        <HeaderText fontSize={12} fontWeight={400} lineHeight={18} noWrap color={theme.text.neutral.primary}>
          {props?.displayName}
        </HeaderText>
        {columnIsSortable && (
          <>
            {columnIsBeingSorted ? (
              <PhoenixIcon
                svg={caret_down}
                variant="brand"
                hoverColor={theme.PRIMARY500}
                size={16}
                fillIcon
                pointer
                style={{ transform: `rotate(${sortOrder === "desc" ? "0deg" : "180deg"})` }}
              />
            ) : (
              <PhoenixIcon svg={caret_down} color={theme.NEUTRAL200} size={16} />
            )}
          </>
        )}
      </Cell>
    );
  };

  const isFirstLoad = useFirstRender();

  const loadingTableData = (loading || !data.length) && isFirstLoad;

  if (loadingTableData) {
    return <Loading />;
  }

  return (
    <>
      {saveCallToLibraryModal && (
        <SaveToFolderModal
          blinds={saveCallToLibraryModal}
          setBlinds={setSaveCallToLibraryModal}
          conferenceID={currentConferenceID}
        />
      )}
      <div
        className={"ag-theme-call-library-list"}
        style={{
          height: "100%",
          width: "100%",
          padding: "4px",
        }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={[
            {
              headerName: "Date/Time",
              field: "created_at",
              cellRenderer: DateTimeCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              width: 160,
              maxWidth: 160,
              minWidth: 160,
              resizable: false,
            },
            {
              headerName: "Call Report",
              field: "duration",
              cellRenderer: CallReportCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              width: 120,
              maxWidth: 120,
              minWidth: 120,
              resizable: false,
            },
            {
              headerName: "Rep",
              field: "rep_name",
              cellRenderer: RepCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              minWidth: 120,
              flex: 1,
              resizable: true,
            },
            {
              headerName: "Company",
              field: "business_name",
              cellRenderer: CompanyCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              minWidth: 160,
              flex: 1,
              resizable: true,
            },
            {
              headerName: "Who Spoke With",
              field: "person_spoke_to",
              cellRenderer: WhoSpokeWithCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              minWidth: 100,
              flex: 1,
              resizable: true,
            },
            {
              headerName: "Call Result",
              field: "label",
              cellRenderer: CallResultCell,
              headerClass: "ag-call-library-header-center",
              headerComponent: CustomHeaderCell,
              minWidth: 100,
              flex: 1,
              resizable: true,
            },
          ]}
          rowData={loading ? undefined : rowData}
          onGridReady={() => {
            gridRef.current?.api.sizeColumnsToFit();
          }}
          onGridSizeChanged={() => {
            gridRef.current?.api.sizeColumnsToFit();
          }}
          loadingOverlayComponent={LoadingOverlay}
          noRowsOverlayComponent={LoadingOverlay}
          suppressRowClickSelection={true}
          enableCellTextSelection={false}
        />
      </div>
    </>
  );
};

const LoadingOverlay = () => <Loading />;

const DateTimeCell = (props: { value: string }) => (
  <Cell>
    <AppText fontSize={12} fontWeight={400} lineHeight={18} noWrap>
      {props.value}
    </AppText>
  </Cell>
);

const RepCell = (props: {
  value: {
    profile_image: string;
    name: string;
  };
}) => (
  <Cell>
    <ProfileImage profileImage={props?.value?.profile_image} diameter={24} borderDiameter={24} borderWidth={0} />
    <AppText
      fontSize={12}
      fontWeight={400}
      lineHeight={18}
      noWrap
      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
    >
      {props?.value?.name}
    </AppText>
  </Cell>
);

const CompanyCell = (props: {
  value: {
    lead_id: string;
    business_name: string;
  };
}) => (
  <Cell>
    <AppText
      fontSize={12}
      fontWeight={400}
      lineHeight={18}
      noWrap
      color={theme.text.brand.primary}
      cursor="pointer"
      style={{ borderBottom: "none", textOverflow: "ellipsis", overflow: "hidden" }}
      onClick={() => window.open(`/lead-detail/${props.value?.lead_id ?? "N/A"}`, "_blank", "noreferrer")}
    >
      {props.value?.business_name || "N/A"}
    </AppText>
  </Cell>
);

const WhoSpokeWithCell = (props: { value: string }) => (
  <Cell>
    <CallResultPill result={props.value} />
  </Cell>
);

const CallResultCell = (props: { value: string }) => (
  <Cell>
    <CallResultPill result={props.value} />
  </Cell>
);

const CallReportCell = (props: {
  value: { duration: number; recording_url_computed: string; conference_id: string };
}) => (
  <CallReportActionCell
    marginLeft="0px"
    duration={props.value?.duration}
    recordingUrl={props.value?.recording_url_computed}
    conferenceId={props.value?.conference_id}
  />
);

const HeaderText = styled(AppText)`
  font-size: 12px;
  font-weight: 600;
  color: ${theme.text.neutral.primary};
  white-space: nowrap;
`;

const Cell = styled.span<{ center?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 100%;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
