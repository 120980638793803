import React from "react";
import moment from "moment";
import { cloudinary } from "src/services/cloudinary";

import { LiveUserStatusItem } from "src/context";
import { DEFAULT_CLOUDINARY_IMAGE } from "src/utils/variables";
import { AppText, FlexDiv } from "src/Components/UI";
import { call_type_other } from "src/images/NewDesign";
import { MixpanelActions } from "src/services/mixpanel";

import { TimeInterval } from "../TimeInterval";
import styled from "styled-components";
import { theme } from "src/utils/theme";
import { NavLink } from "react-router-dom";
import { AdvancedImage } from "@cloudinary/react";
import { CALL_TYPE_COLOR_MAP, CALL_TYPE_ICON_MAP } from "./constants";
import { PhoenixStyledTooltip } from "src/Components/Dumb/PhoenixStyledTooltip";

export const CallerCard = ({ item, isCurrentCall }: { item: LiveUserStatusItem; isCurrentCall: boolean }) => {
  const callType = item.status === "IDLE" ? "IDLE" : item.status === "CALL" ? item.activity_type : "OFFLINE";

  const callTypeBackground = CALL_TYPE_COLOR_MAP[callType] ?? "#C8E5FF";
  const callTypeIcon = CALL_TYPE_ICON_MAP[callType] ?? call_type_other;

  const updatedTime = moment(item?.updated_at);
  const firstName = item?.user?.first_name || "";
  const lastName = item?.user?.last_name || "";

  const profileImage = cloudinary.image(item?.user?.profile_image ? item.user.profile_image : DEFAULT_CLOUDINARY_IMAGE);
  //conditional instead of isActive prop on NavLink since pointerEvents: none would only disable link through click only

  return (
    <CallerCardWrapper
      isCurrentCall={isCurrentCall}
      to={`/call-report/${item.conference_id}`}
      onClick={() => {
        if (item.conference_id) {
          MixpanelActions.track("Call Report Viewed", {
            report_id: item.conference_id,
            trigger_source: "Active Calls",
          });
        }
      }}
    >
      <PhoenixStyledTooltip id="caller-card-tooltip" />
      <FlexDiv direction="column" gap={8} align="center" justify="center">
        <ImageWrapper>
          <AdvancedImageStyle style={{ filter: `blur(0)` }} cldImg={profileImage} />
        </ImageWrapper>
        <AppText fontSize={14} fontWeight={500} textAlign="center">{`${firstName} ${
          lastName.length > 5 ? lastName[0] + `.` : lastName
        }`}</AppText>
      </FlexDiv>

      <CallDurationGroup>
        <CallDurationIconWrapper style={{ backgroundColor: callTypeBackground }}>
          <img src={callTypeIcon} alt={callType} data-for="caller-card-tooltip" data-tip={callType} />
        </CallDurationIconWrapper>
        <CallDurationDivider />
        <CallDurationText>
          <AppText>
            <TimeInterval time={updatedTime} />
          </AppText>
        </CallDurationText>
      </CallDurationGroup>
    </CallerCardWrapper>
  );
};

const CallerCardWrapper = styled(NavLink)<{ isCurrentCall: boolean }>`
  align-items: center;
  background: ${theme.text.neutral.inverse};
  border-radius: 8px;
  border: ${({ isCurrentCall }) =>
    `1px solid ${isCurrentCall ? theme.text.brand.primary : theme.text.neutral.inverse}`};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 16px;
  justify-content: space-between;
  min-width: 152px;
  padding: 16px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 24px;
  }

  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  }
`;

const CallDurationGroup = styled.div`
  align-items: stretch;
  border-radius: 8px;
  border: 1px solid ${theme.NEUTRAL300};
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const CallDurationDivider = styled.div`
  background-color: ${theme.NEUTRAL300};
  height: 100%;
  width: 1px;
`;

const CallDurationIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  width: 32px;
  flex-shrink: 0;
`;

const CallDurationText = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 88px;
`;

const ImageWrapper = styled.div`
  height: 40px;
  width: 40px;
  position: relative;
`;

const AdvancedImageStyle = styled(AdvancedImage)`
  border-radius: 50%;
  height: 40px;
  object-fit: cover;
  width: 40px;
`;
