import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import moment from "moment";
import { appToast } from "src/utils/toast";
import { PhoenixInput, PhoenixMultiSelect } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import { AppText, FlexDiv } from "src/Components/UI";

import DynamicDropdown from "./DynamicDropdown";
import { callResultsTypeOptions, leadHistoryOperators } from "./shared";
import DateRangePicker from "./DateRangePicker";
import { hasValue } from "src/utils/misc";

const LeadHistoryFilter = ({
  value,
  onChange,
  removeFilter,
}: {
  value?: any;
  onChange?: (value: any) => void;
  removeFilter?: () => void;
}) => {
  const [localValue, setLocalValue] = useState<{
    metric: string | undefined;
    operator: string | undefined;
    amount: number | string | undefined;
    amount_2: number | string | undefined;
    lowerbound_date: string | undefined;
    upperbound_date: string | undefined;
  }>(value);
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  const disabledState = useMemo(() => !localValue.lowerbound_date || !localValue.upperbound_date, [localValue]);
  const emptyState = useMemo(
    () =>
      !localValue.metric &&
      !localValue.operator &&
      !localValue.amount &&
      !localValue.amount_2 &&
      !localValue.lowerbound_date &&
      !localValue.upperbound_date,
    [localValue],
  );

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  const isRangeOperator = localValue?.operator === "Between" || localValue?.operator === "NotBetween";
  const selectedMetric = callResultsTypeOptions?.find((item) => item.value === localValue?.metric);
  const selectedOperator = leadHistoryOperators?.find((item) => item.value === localValue?.operator);

  const label = useMemo(() => {
    return `Lead received ${selectedMetric?.label?.toLowerCase() ?? ""} ${
      selectedOperator?.label?.toLowerCase() ?? ""
    } ${localValue?.amount || ""} ${localValue?.amount_2 ? `- ${localValue.amount_2}` : ""} ${
      localValue?.lowerbound_date && localValue?.upperbound_date
        ? `, ${moment(localValue.lowerbound_date).format("MM/DD/YYYY")} - ${moment(localValue.upperbound_date).format(
            "MM/DD/YYYY",
          )}`
        : ""
    }`.trim();
  }, [selectedMetric, selectedOperator, localValue]);

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (newIsOpen) {
        setIsOpen(newIsOpen);
        return;
      }

      if (disabledState && !emptyState) {
        setIsOpen(false);
        return;
      }

      if (localValue?.amount || localValue?.operator || localValue?.metric) {
        if (!localValue?.amount && localValue?.amount !== 0) {
          appToast("Invalid Lead History Filter! Must specify an amount");
          return;
        }
        if (!localValue?.operator) {
          appToast("Invalid Lead History Filter! Must specify an operator");
          return;
        }
        if (!localValue?.metric) {
          appToast("Invalid Lead History Filter! Must specify a metric");
          return;
        }
      }

      let finalValue = { ...localValue };

      if (localValue?.operator === "Between" || localValue?.operator === "NotBetween") {
        if (!localValue?.amount_2 && localValue?.amount_2 !== 0) {
          appToast("Invalid Lead History Filter! Must specify a second amount");
          return;
        }

        if (
          (localValue?.amount || localValue?.amount === 0) &&
          (localValue?.amount_2 || localValue?.amount_2 === 0) &&
          localValue.amount > localValue.amount_2
        ) {
          finalValue = {
            ...finalValue,
            amount: localValue.amount_2,
            amount_2: localValue.amount,
          };
        }
      } else {
        finalValue = {
          ...finalValue,
          amount_2: undefined,
        };
      }

      if (hasChangesRef.current) {
        onChange?.(finalValue);
        hasChangesRef.current = false;
      }

      setLocalValue(finalValue);
      setIsOpen(false);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue({
      metric: undefined,
      operator: undefined,
      amount: undefined,
      amount_2: undefined,
      lowerbound_date: undefined,
      upperbound_date: undefined,
    });
    hasChangesRef.current = true;
  }, []);

  const handleValueChange = useCallback((updates: any) => {
    setLocalValue((prev) => ({ ...prev, ...updates }));
    hasChangesRef.current = true;
  }, []);

  return (
    <DynamicDropdown<string>
      label="Lead History"
      onClear={handleClear}
      isOpen={isOpen}
      setIsOpen={handleIsOpenChange}
      value={localValue?.metric ?? ""}
      onRemove={hasValue(localValue) ? removeFilter : undefined}
      renderHeader={
        <AppText
          fontSize={12}
          color={theme.text.brand.primary}
          style={{ maxWidth: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {label}
        </AppText>
      }
      disabled={disabledState}
    >
      <FlexDiv direction="column" gap={8}>
        <AppText>
          Lead Received
          {localValue?.operator === "Between" || localValue?.operator === "NotBetween" ? "" : " a"}
        </AppText>

        <PhoenixMultiSelect
          isMulti={false}
          marginBottom={false}
          menuPosition="fixed"
          menuShouldBlockScroll
          name="lead_history_received"
          options={callResultsTypeOptions?.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
          placeholder="Select Metric"
          value={selectedMetric}
          onChange={(item: OptionItem) => handleValueChange({ metric: item?.value })}
        />
      </FlexDiv>
      <FlexDiv gap={8} direction={isRangeOperator ? "column" : "row"}>
        <FlexDiv width="100%" direction="column">
          <PhoenixMultiSelect
            isMulti={false}
            marginBottom={false}
            menuPosition="fixed"
            menuShouldBlockScroll
            name="lead_history_operator"
            options={leadHistoryOperators?.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))}
            placeholder="Select Operator"
            value={selectedOperator}
            onChange={(item: OptionItem) => handleValueChange({ operator: item?.value })}
          />
        </FlexDiv>

        <FlexDiv align="center" gap={8} width="100%">
          <PhoenixInput
            name="lead_history_number"
            inputValueType="number"
            value={localValue?.amount}
            placeholder="0"
            type="number"
            showNumberArrows
            onChange={(e: any) => handleValueChange({ amount: parseInt(e.target.value) || 0 })}
            handleNumberArrowUpClick={() =>
              handleValueChange({ amount: (parseInt(localValue?.amount?.toString() ?? "0") || 0) + 1 })
            }
            handleNumberArrowDownClick={() =>
              handleValueChange({ amount: Math.max(0, (parseInt(localValue?.amount?.toString() ?? "0") || 0) - 1) })
            }
            displayNoContextText
          />

          <AppText>{isRangeOperator ? "and" : "times"}</AppText>

          {isRangeOperator && (
            <>
              <PhoenixInput
                name="lead_history_number"
                inputValueType="number"
                value={localValue?.amount_2}
                type="number"
                placeholder="0"
                showNumberArrows
                onChange={(e: any) => handleValueChange({ amount_2: parseInt(e.target.value) || 0 })}
                handleNumberArrowUpClick={() =>
                  handleValueChange({ amount_2: (parseInt(localValue?.amount_2?.toString() ?? "0") || 0) + 1 })
                }
                handleNumberArrowDownClick={() =>
                  handleValueChange({
                    amount_2: Math.max(0, (parseInt(localValue?.amount_2?.toString() ?? "0") || 0) - 1),
                  })
                }
                displayNoContextText
              />
              <AppText>times</AppText>
            </>
          )}
        </FlexDiv>
      </FlexDiv>

      <DateRangePicker
        endDateId="lead_history_end_date"
        onChange={handleValueChange}
        startDateId="lead_history_start_date"
        value={localValue}
        min_date={moment().subtract(6, "months")}
      />
    </DynamicDropdown>
  );
};

export default LeadHistoryFilter;
