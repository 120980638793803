import * as Sentry from "@sentry/react";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { AppSidebarCard, AppText, SkeletonBlock } from "../UI";
import { NewAppButton } from "../UI/NewAppButton";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { tzOverride } from "../../apollo/cache";
import {
  CallContext,
  LeadCardContext,
  LeadFilterContext,
  ModalContext,
  ScheduleContext,
  SelfSourceLeadContext,
} from "../../context";
import { UserStatusContext } from "../../context/UserStatusContext";
import { info, search } from "../../images/NewDesign";
import { MixpanelActions } from "../../services/mixpanel";
import { CurrentLeadType, SelectedContactType } from "../../types";
import "../../utils/react-big-calendar.css";
import { appToast } from "../../utils/toast";
import { FETCH_CURRENT_LEAD_POLL_INTERVAL, SEARCH_DEBOUNCE_INTERVAL } from "../../utils/variables";
import { PhoenixStyledTooltip } from "../Dumb/PhoenixStyledTooltip";
import { LeadCardV2, RecentActivitiesComponent } from "../Segments/DashboardSideBarSegments";
import { LeadCardError, SearchList } from "../Smart";
import { PhoenixAppButton, PhoenixIcon, PhoenixInput } from "../UI/Phoenix";
import { AddAssociatedContact, AddOrEditContactModal, SelectScheduledEventModal } from "../modal/";
import { CORE_LEAD_CARD_DATA } from "./../../apollo/fragments";
import { ParentType } from "./../../types";
import { NewLeadForm, RecentDialsComponent, WillDialAsV2 } from "./DashboardSideBarSegments";
import { PhoenixSuggestedActionCardV2 } from "./DashboardSideBarSegments/LeadCardV2";
import { SelfSourceLeadV2 } from "./DashboardSideBarSegments/SelfSourceLeadV2";
import { UpcomingDialsComponent } from "./DashboardSideBarSegments/UpcomingDials";
import { useDebounce } from "src/utils/hooks";

export const FETCH_NEXT_DIAL = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchNextDial($lead_filter: LeadFilter, $override_timezone_contraints: Boolean) {
    fetchNextDial(lead_filter: $lead_filter, override_timezone_contraints: $override_timezone_contraints) {
      id
      lead_id
      type
      is_scheduled_item
      schedule_item {
        id
        start_time
        end_time
        google_space_sellfire_id
        google_space_info {
          google_space_sellfire_id
          id
          meeting_url
          meeting_code
        }
        zoom_meeting_sellfire_id
        zoom_meeting_info {
          zoom_meeting_sellfire_id
          id
          meeting_url
          meeting_id
        }
      }
      lead {
        id
        next_scheduled_event {
          id
          type
        }
        next_scheduled_event_time
        ...CoreLeadCardData
      }
      associate_parent {
        id
        ...CoreLeadCardData
      }
      associate_parent_id
    }
  }
`;

const FETCH_TOUCHED_LEADS = gql`
  query fetchTouchedLeads($searchText: String, $skip: Int, $take: Int) {
    fetchTouchedLeads(searchText: $searchText, skip: $skip, take: $take) {
      id
      favorited
      primary_phone_number
      primary_email
      current_lead_type
      city
      state
      country
      channel
      rep_id
      computed_mrr
      timezone_by_state
      lead_source
      industry
      sub_industry
      business_name
      full_name
      first_name
      last_name
      favorited
      primary_phone_number
      primary_email
      current_lead_type
      city
      state
      country
      channel
      rep_id
      timezone_by_state
      lead_source
      industry
      sub_industry
      business_name
      lead_ownership_status
    }
  }
`;

const FETCH_LEAD = gql`
  ${CORE_LEAD_CARD_DATA}
  query fetchLead($id: String!, $always_return_primary: Boolean) {
    fetchLead(id: $id, always_return_primary: $always_return_primary) {
      id
      ...CoreLeadCardData
      newIncomingComputed
      next_scheduled_event_time
      primary_phone_number_type
      primary_phone_number_title
      associate_parent_id
      local_primary_phone_number
      primary_phone_number_country_code
      primary_email
      primary_email_type
      primary_email_title
      alternate_contacts {
        id
        title
        channel
        type
        value
      }
      call_notes {
        id
        notes
        created_at
      }
      visible_custom_fields {
        id
        key
        value
        visible
        boo_value
        num_value
        date_value
        type
        lead_id
        options
        computed_value
        list_value
        allow_reps_to_edit
      }
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription notification {
    notification {
      id
      text
      details
    }
  }
`;

interface SidebarProps {
  popup: boolean;
  setPopup: (popup: boolean) => void;
  currentTask: string;
  setCurrentTask: (currentTask: string) => void;
  setSelectedContact: (selectedContact: SelectedContactType) => void;
  callState: boolean;
  callStartTime?: Date;
  openCustomFieldsModal: () => void;
  closeOtherModals?: () => void;
}

const DashboardSidebar: React.FC<SidebarProps> = ({
  popup,
  setPopup,
  setCurrentTask,
  setSelectedContact,
  callStartTime,
  callState,
  openCustomFieldsModal,
  closeOtherModals,
}) => {
  const { toggleSequences, recentActivities } = useFlags();

  const { updateUserToIdle } = useContext(UserStatusContext);

  const [nonScheduledCallBack, setNonScheduledCallBack] = useState(false);

  const {
    setSelfSource,
    selfSource,
    skipLeadModal,
    setSkipLeadModal,
    showFiltersModal,
    setShowFiltersModal,
    showCustomFieldsModal,
    editModal,
    selectEventModal,
    setSelectEventModal,
    showAddAssociateModal,
    setShowAddAssociateModal,
    showAddOrEditContactModal,
    setShowAddOrEditContactModal,
  } = useContext(ModalContext);
  const { leadFilter } = useContext(LeadFilterContext);
  const { myScheduleDate } = useContext(ScheduleContext);

  const {
    callLeadId,
    nextActionOverride,
    stopScheduledEventNotification,
    setStopScheduledEventNotification,
    setSuggestedActionData,
    suggestedActionData,
    dialAsNumber,
    setDialAsNumber,
    resetNextDial,
    repLeadSearch,
    setRepLeadSearch,
  } = useContext(CallContext);

  const {
    selectedTabDialQueue,
    sortOptionDialQueue,
    upcomingDials,
    customDials,
    setAbleToOverideNextDial,
    setLeadInNextDial,
    dataRecommendedActionV2,
    loadingRecommendedActionV2,
    fetchSuggestedActionLeadV2,
    setLeadIntentInNextDial,
    recentActivitiesRefreshNow,
    setRecentActivitiesRefreshNow,
  } = useContext(LeadCardContext);

  const leadSearchTake = 4;
  const [leadSearchSkip, setLeadSearchSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [allFetchedLeads, setAllFetchedLeads] = useState<CurrentLeadType[]>([]);
  const [searchValue, setSearchValue] = useState("");

  // HOTFIX context var causing rerender issues. we should come up with a better solution once we have lead search modal
  useDebounce(
    () => {
      setRepLeadSearch(searchValue);
    },
    [searchValue],
    SEARCH_DEBOUNCE_INTERVAL,
  );

  useEffect(() => {
    if (repLeadSearch !== searchValue) {
      setSearchValue("");
    }
  }, [repLeadSearch]);

  const [
    fetchTouchedLeads,
    { data: touchedLeadsData, loading: touchedLeadsLoading, error: touchedLeadsError, called: touchedLeadsCalled },
  ] = useLazyQuery(FETCH_TOUCHED_LEADS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      // remove duplicates
      const newList = [...allFetchedLeads, ...data.fetchTouchedLeads].reduce(
        (acc, cv) => (acc?.map((a: any) => a?.id).includes(cv?.id) ? acc : [...acc, cv]),
        [],
      );

      if (data.fetchTouchedLeads?.length < leadSearchTake) setHasMore(false);
      else setHasMore(true);

      setAllFetchedLeads(newList);
    },
  });

  const handleResetLeadState = () => {
    setAllFetchedLeads([]);
    setLeadSearchSkip(0);
  };

  useDebounce(
    () => {
      handleResetLeadState();
      fetchTouchedLeads({
        variables: {
          searchText: repLeadSearch,
          skip: leadSearchSkip,
          take: leadSearchTake,
        },
      });
    },
    [repLeadSearch],
    SEARCH_DEBOUNCE_INTERVAL,
  );

  const handleFetchMore = () => {
    setLeadSearchSkip(leadSearchSkip + leadSearchTake);

    fetchTouchedLeads({
      variables: {
        searchText: repLeadSearch,
        skip: leadSearchSkip + leadSearchTake,
        take: leadSearchTake,
      },
    });
  };

  const [
    getLead,
    { data: leadData, loading: leadLoading, error: leadError, refetch: refetchLead },
  ] = useLazyQuery(FETCH_LEAD, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!!callLeadId) {
      getLead({
        variables: {
          id: callLeadId,
          always_return_primary: true,
        },
      });
    }
  }, [callLeadId]);

  const { resetSelfSourcedLead } = useContext(SelfSourceLeadContext);

  const { data: dataNext, loading: loadingNext, error: errorNext, refetch: refetchNext } = useQuery(FETCH_NEXT_DIAL, {
    fetchPolicy: "network-only",
    variables: {
      lead_filter: leadFilter,
      override_timezone_contraints: tzOverride(),
      pollInterval: FETCH_CURRENT_LEAD_POLL_INTERVAL, // 5 minutes
    },
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  useEffect(() => {
    fetchSuggestedActionLeadV2({
      variables: {
        lead_filter: leadFilter,
        skip: 0,
        take: 1,
        // the active queue let's the user change this sort option
        // the suggested action is always sorted by the organization's order defind by admin
        sort_option: "OrganizationOrder",
      },
    });
  }, [leadFilter]);

  useEffect(() => {
    fetchSuggestedActionLeadV2({
      variables: {
        lead_filter: leadFilter,
        skip: 0,
        take: 1,
        // the active queue let's the user change this sort option
        // the suggested action is always sorted by the organization's order defind by admin
        sort_option: "OrganizationOrder",
      },
    });
  }, [leadFilter]);

  const refetchQueries = async () => {
    await refetchNext();
  };

  useEffect(() => {
    refetchQueries();
  }, [callState]);

  useEffect(() => {
    if (recentActivitiesRefreshNow) {
      new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
        setRecentActivitiesRefreshNow(false);
      });
    }
  }, [recentActivitiesRefreshNow]);

  const isNextEventDoubleBooked = useMemo(() => {
    return (
      !!dataNext &&
      !!dataNext.fetchNextDial &&
      dataNext.fetchNextDial.slice().filter((item: any) => item.is_scheduled_item).length > 1
    );
  }, [dataNext]);

  /**
   * Returns the next action lead.
   *
   * This is a waterfall check to determine the next action lead.
   *
   * this can be sourced from:
   * 1. fetchNextDial (events)
   * 2. nextActionOverride (context. Added by clicking make next action button on any lead)
   * 3. upcomingDials (active queue)
   * 4. customDials (custom queue)
   * 5. base case: suggested action lead
   *
   * @returns {Object} - the next action lead
   *
   * Example: If the next action lead is a scheduled event, it will return the scheduled event lead and show it as blocks_make_next_dial on other leads
   *
   * Example: if there is no event, and no override, it will return the first lead in the active queue or 1st lead in the custom queue depending on the selectedTabDialQueue
   *
   *
   */
  const returnNextDialLeadIntent = useCallback(() => {
    if (loadingNext || loadingRecommendedActionV2) {
      return undefined;
    }

    // Define conditions for each source of the next action lead.
    const conditions = [
      {
        label: "event",
        check: () =>
          dataNext?.fetchNextDial?.length &&
          dataNext.fetchNextDial[0].is_scheduled_item &&
          !stopScheduledEventNotification,
        result: () => {
          setLeadIntentInNextDial(dataNext.fetchNextDial[0]);
          return {
            intent_object: dataNext.fetchNextDial[0],
            parentType: "nextAction",
            blocks_make_next_dial: true,
          };
        },
      },
      {
        label: "override",
        check: () => !!nextActionOverride?.intent_object?.lead?.id,
        result: () => {
          setLeadIntentInNextDial(nextActionOverride.intent_object);
          return {
            intent_object: nextActionOverride.intent_object,
            parentType: nextActionOverride.parent,
            blocks_make_next_dial: false,
          };
        },
      },
      {
        label: "active",
        check: () =>
          selectedTabDialQueue === "active" &&
          !!upcomingDials?.length &&
          upcomingDials[0]?.lead?.id !== dataNext?.fetchNextDial?.[0]?.lead?.id,
        result: () => {
          // there are minor edge cases where the active queue and suggested sort become out of sync
          // (this is not something we can forsee as it involves doubled intents in sequences that aren't visible until the first intent is completed)
          // in order to solve for those we will use suggested action if the sort is the same (organization order)
          const activeAndSuggestedSortAreTheSame = sortOptionDialQueue === "OrganizationOrder";

          if (activeAndSuggestedSortAreTheSame) {
            setLeadIntentInNextDial(dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]);
            return {
              // suggested action fetch intent
              intent_object: dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0],
              parentType: "suggestedAction",
              blocks_make_next_dial: false,
            };
          } else {
            setLeadIntentInNextDial(upcomingDials[0]);
            return {
              // active queue fetch intent
              intent_object: upcomingDials[0],
              parentType: "activeQueueDials",
              blocks_make_next_dial: false,
            };
          }
        },
      },
      {
        label: "custom",
        check: () => selectedTabDialQueue === "custom" && !!customDials?.length,
        result: () => {
          setLeadIntentInNextDial(customDials[0]);
          return {
            intent_object: customDials[0],
            parentType: "customQueueDials",
            blocks_make_next_dial: false,
          };
        },
      },
      {
        label: "suggested action (base case)",
        check: () => !!suggestedActionData && suggestedActionData.intentData,
        result: () => {
          setLeadIntentInNextDial(dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]);
          return {
            intent_object: dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0],
            parentType: "suggestedAction",
            blocks_make_next_dial: false,
          };
        },
      },
    ];

    // Iterate over conditions and return the first matching result.
    for (const condition of conditions) {
      if (condition.check()) {
        return condition.result();
      }
    }

    // No next dial available (edge case)
    setAbleToOverideNextDial(true);
    setLeadInNextDial("");
    setLeadIntentInNextDial(undefined);

    return undefined;
  }, [
    dataNext,
    nextActionOverride,
    selectedTabDialQueue,
    upcomingDials,
    customDials,
    stopScheduledEventNotification,
    suggestedActionData,
    loadingNext,
    loadingRecommendedActionV2,
    sortOptionDialQueue,
  ]);

  const suggestedAndNextActionAreTheSameLead = useMemo(() => {
    return (
      dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]?.lead?.id ===
      returnNextDialLeadIntent()?.intent_object?.lead?.id
    );
  }, [suggestedActionData, returnNextDialLeadIntent, loadingRecommendedActionV2]);

  useEffect(() => {
    if (suggestedAndNextActionAreTheSameLead) {
      setSuggestedActionData({
        ...suggestedActionData,
        suggestedAndNextActionAreTheSameLead,
      });
    }
  }, [suggestedAndNextActionAreTheSameLead]);

  const eventInNextAction = useMemo(() => {
    return (
      !!returnNextDialLeadIntent()?.intent_object?.is_scheduled_item &&
      // not in the past
      // we need to check if the event is in the past because the override can be set in the past and then not acted on.
      // in that case we can allow the rep to call the suggested action lead when the screen is refreshed or tab changed
      moment.utc(returnNextDialLeadIntent()?.intent_object?.schedule_item?.end_time).isAfter(moment.utc())
    );
  }, [returnNextDialLeadIntent]);

  if (showAddAssociateModal) {
    return (
      <SidebarContainer>
        <SidebarWrapper>
          <AddAssociatedContact setShowAddAssociatedContact={setShowAddAssociateModal} width={432} />
        </SidebarWrapper>
      </SidebarContainer>
    );
  } else if (showAddOrEditContactModal) {
    return (
      <SidebarContainer>
        <SidebarWrapper>
          <AddOrEditContactModal setShowAddContact={setShowAddOrEditContactModal} width={432} />
        </SidebarWrapper>
      </SidebarContainer>
    );
  } else {
    // on a call
    if (callState) {
      if (leadLoading) {
        return (
          <SkeletonBlock
            height={"100%"}
            width={432}
            borderRadius={8}
            style={{
              margin: "16px auto",
            }}
          />
        );
      }

      if (leadError) return <p>Error loading data</p>;
      if (!leadData || !leadData?.fetchLead || !!leadData?.fetchLead?.newIncomingComputed) {
        return (
          <SidebarContainer>
            <SidebarWrapper>
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
              <AddNewLead
                // cancel
                onClick={() => {
                  setSelfSource(true);
                  setPopup(true);
                }}
                buttonType="secondary"
              >
                Add New Lead
              </AddNewLead>
              {searchValue ? (
                <SearchList
                  setNonScheduledCallBack={setNonScheduledCallBack}
                  setSelectedContact={setSelectedContact}
                  allFetchedLeads={touchedLeadsData?.fetchTouchedLeads}
                  loading={touchedLeadsLoading}
                  error={touchedLeadsError}
                  disableOutsideEvent={false}
                  onCancelClick={() => {
                    setSearchValue("");
                    setRepLeadSearch("");
                  }}
                  getLead={getLead}
                  hasMore={hasMore}
                  handleFetchMore={handleFetchMore}
                  handleResetLeadState={handleResetLeadState}
                  called={touchedLeadsCalled}
                />
              ) : (
                <AppSidebarCard title="Create New Lead" color={theme.TERTIARY500}>
                  <NewLeadForm refetch={getLead} lead_id={callLeadId} />
                </AppSidebarCard>
              )}
            </SidebarWrapper>
          </SidebarContainer>
        );
      }
      return (
        <SidebarContainer>
          <SidebarWrapper>
            <AppSidebarCard
              title={leadData?.fetchLead?.next_intent_scheduled_or_unscheduled?.event_type_label}
              width={432}
              removeFooter
            >
              <LeadCardV2 showEventTypeCard parentType={"currentLead"} expandOnRender leadData={leadData?.fetchLead} />
            </AppSidebarCard>
          </SidebarWrapper>
        </SidebarContainer>
      );
    }

    if (selfSource)
      return (
        <SelfSourceLeadV2
          setSelectedContact={setSelectedContact}
          callState={false}
          openCustomFieldsModal={openCustomFieldsModal}
          closeOtherModals={closeOtherModals}
          setSelfSource={setSelfSource}
          // refetchLeadHistory={refetchLeadHistory}
        />
      );

    if (nonScheduledCallBack) {
      return (
        <SidebarContainer>
          <SidebarWrapper>
            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
            <AddNewLead
              // cancel
              onClick={() => {
                setSelfSource(true);
                setPopup(true);
              }}
              buttonType="secondary"
            >
              Add New Lead
            </AddNewLead>
            <SearchList
              setNonScheduledCallBack={setNonScheduledCallBack}
              setSelectedContact={setSelectedContact}
              allFetchedLeads={allFetchedLeads}
              loading={touchedLeadsLoading}
              error={touchedLeadsError}
              disableOutsideEvent={popup}
              hasMore={hasMore}
              handleFetchMore={handleFetchMore}
              handleResetLeadState={handleResetLeadState}
              called={touchedLeadsCalled}
            />

            <CenterDiv>
              <AddNewLead
                // cancel
                onClick={() => {
                  setSelfSource(true);
                  setPopup(true);
                }}
                buttonType="secondary"
              >
                Add New Lead
              </AddNewLead>
            </CenterDiv>
          </SidebarWrapper>
        </SidebarContainer>
      );
    }

    // if (searchValue) {
    //   return (
    //     <SidebarContainer>
    //       <SidebarWrapper>
    //         {selectEventModal && (
    //           <SelectScheduledEventModal
    //             events={dataNext.fetchNextDial.slice().filter((item: any) => item.is_scheduled_item)}
    //           />
    //         )}
    //         <Horizontal>
    //           <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
    //           <AddNewLead
    //             buttonType="secondary"
    //             variant="brand-outline"
    //             onClick={() => {
    //               // clear the last self sourced lead
    //               resetSelfSourcedLead();
    //               MixpanelActions.track("Add Self Sourced Lead", { type: "click", view: "Dashboard" });
    //               setSelfSource(true);
    //               setPopup(true);
    //             }}
    //           >
    //             Add Lead
    //           </AddNewLead>
    //           <PhoenixAppButton
    //             variant="danger-outline"
    //             buttonType="secondary"
    //             onClick={() => {
    //               setSearchValue("");
    //             }}
    //             disabled={!searchValue}
    //           >
    //             Clear
    //           </PhoenixAppButton>
    //         </Horizontal>
    //         {searchValue && (
    //           <div style={{ marginTop: "-50px" }}>
    //             <SearchList
    //               setNonScheduledCallBack={setNonScheduledCallBack}
    //               setSelectedContact={setSelectedContact}
    //               allFetchedLeads={allFetchedLeads}
    //               loading={touchedLeadsLoading}
    //               error={touchedLeadsError}
    //               disableOutsideEvent={false}
    //               onCancelClick={() => {
    //                 setSearchValue("");
    //               }}
    //               isSearchDashboard
    //               hasMore={hasMore}
    //               handleFetchMore={handleFetchMore}
    //               handleResetLeadState={handleResetLeadState}
    //               called={touchedLeadsCalled}
    //             />
    //           </div>
    //         )}
    //       </SidebarWrapper>
    //     </SidebarContainer>
    //   );
    // }

    return (
      <SidebarContainer>
        <SidebarWrapper>
          {selectEventModal && (
            <SelectScheduledEventModal
              events={dataNext.fetchNextDial.slice().filter((item: any) => item.is_scheduled_item)}
            />
          )}
          <Horizontal>
            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
            <AddNewLead
              buttonType="secondary"
              variant="brand-outline"
              onClick={() => {
                // clear the last self sourced lead
                resetSelfSourcedLead();
                MixpanelActions.track("Add Self Sourced Lead", { type: "click", view: "Dashboard" });
                setSelfSource(true);
                setPopup(true);
              }}
            >
              Add Lead
            </AddNewLead>
            {searchValue && (
              <PhoenixAppButton
                variant="danger-outline"
                buttonType="secondary"
                onClick={() => {
                  setSearchValue("");
                }}
                disabled={!searchValue}
              >
                Clear
              </PhoenixAppButton>
            )}
          </Horizontal>
          {searchValue && (
            <div style={{ marginTop: "-50px" }}>
              <SearchList
                setNonScheduledCallBack={setNonScheduledCallBack}
                setSelectedContact={setSelectedContact}
                allFetchedLeads={allFetchedLeads}
                loading={touchedLeadsLoading}
                error={touchedLeadsError}
                disableOutsideEvent={false}
                onCancelClick={() => {
                  setSearchValue("");
                }}
                isSearchDashboard
                hasMore={hasMore}
                handleFetchMore={handleFetchMore}
                handleResetLeadState={handleResetLeadState}
                called={touchedLeadsCalled}
              />
            </div>
          )}
          {!suggestedAndNextActionAreTheSameLead && !eventInNextAction && (
            <>
              <PhoenixSuggestedActionCardV2
                intentData={dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]}
                leadData={dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]?.lead}
                loadingState={!!loadingRecommendedActionV2 || !!loadingNext}
                scheduledEventBehavior={
                  dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]?.lead?.sequence_step?.actions?.[0]
                    ?.scheduled_event_behavior
                }
                expectedAction={
                  dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]?.type === "SequenceAction"
                    ? dataRecommendedActionV2?.fetchFilteredLeadsInQueueV2?.[0]?.lead?.sequence_step?.actions?.[0]?.task
                    : "manualCall"
                }
                suggestedAndNextActionAreTheSameLead={suggestedAndNextActionAreTheSameLead}
              />
            </>
          )}

          {loadingNext ? null : errorNext ? (
            <LeadCardError error="Could not load the next dial." title="Next dial" refetch={() => refetchNext()} />
          ) : isNextEventDoubleBooked && !stopScheduledEventNotification ? (
            <AppSidebarCard showExpand title={"Next Dial"} width={432}>
              <PaddingDiv>
                <SelectActionInfoText>
                  The average cold call lasts longer than the time before your next scheduled event. We recommend you...
                </SelectActionInfoText>
                <NewAppButton
                  onClick={() => {
                    MixpanelActions.track("Double-booking", { type: "impression" });
                    setSelectEventModal(!selectEventModal);
                  }}
                  style={{ marginBottom: "12px" }}
                  variant={"primary"}
                >
                  Select From double-booked events
                </NewAppButton>
                <NewAppButton
                  onClick={() => {
                    setStopScheduledEventNotification(true);
                  }}
                >
                  Make another cold call
                </NewAppButton>
              </PaddingDiv>
            </AppSidebarCard>
          ) : (
            !!returnNextDialLeadIntent()?.intent_object?.lead?.id && (
              <AppSidebarCard
                title={"Next Action"}
                TooltipComponent={
                  suggestedAndNextActionAreTheSameLead || eventInNextAction ? (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <PhoenixStyledTooltip
                        id="valuable"
                        place="bottom"
                        textAlign="center"
                        width={186}
                        height={65}
                        maxHeight={65}
                        maxWidth={186}
                        textWrap="wrap"
                        lineHeight={16}
                      />
                      <PhoenixIcon
                        svg={info}
                        color={theme.WHITE_COLOR}
                        hoverColor={theme.WHITE_COLOR}
                        size={16}
                        data-tip="You're about to take Sellfire's suggested action."
                        data-for="valuable"
                      />
                    </div>
                  ) : null
                }
                render={
                  <WillDialAsV2
                    leadPhoneNumber={returnNextDialLeadIntent()?.intent_object?.lead?.primary_phone_number}
                    leadId={returnNextDialLeadIntent()?.intent_object?.lead?.id}
                    dialAsNumber={dialAsNumber}
                    setDialAsNumber={setDialAsNumber}
                  />
                }
                width={432}
              >
                <LeadCardV2
                  showEventTypeCard
                  parentType={
                    returnNextDialLeadIntent()?.parentType
                      ? (returnNextDialLeadIntent()?.parentType as ParentType)
                      : "nextAction"
                  }
                  isNextDial
                  expandOnRender
                  intent={returnNextDialLeadIntent()?.intent_object}
                  blocksMakeNextAction={
                    (returnNextDialLeadIntent()?.blocks_make_next_dial ||
                      returnNextDialLeadIntent()?.intent_object?.is_scheduled_item) &&
                    // not in the past
                    // we need to check if the event is in the past because the override can be set in the past and then not acted on.
                    // in that case we can allow the rep to make the next action on another lead.
                    moment.utc(returnNextDialLeadIntent()?.intent_object?.schedule_item?.end_time).isAfter(moment.utc())
                    //)
                  }
                  dialAsNumber={dialAsNumber}
                  leadIdOverride={returnNextDialLeadIntent()?.intent_object?.associate_parent_id}
                  scheduledEventIsWithAssociatedLead={
                    !!returnNextDialLeadIntent()?.intent_object?.associate_parent_id &&
                    !!returnNextDialLeadIntent()?.intent_object?.is_scheduled_item
                  }
                />
              </AppSidebarCard>
            )
          )}

          <UpcomingDialsComponent
            closeOtherModals={closeOtherModals}
            leadIdToFilter={returnNextDialLeadIntent()?.intent_object?.lead?.id}
          />
          {recentActivities ? (
            !recentActivitiesRefreshNow && <RecentActivitiesComponent closeOtherModals={closeOtherModals} />
          ) : (
            <RecentDialsComponent closeOtherModals={closeOtherModals} />
          )}
          <CenterDiv></CenterDiv>
        </SidebarWrapper>
      </SidebarContainer>
    );
  }
};

const SearchInputRaw = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (value: string) => void;
}) => {
  return (
    <PhoenixInput
      type="text"
      displayNoContextText
      insideLeftElementOverride={<SearchIcon />}
      noTopMargin
      value={searchValue}
      placeholder="Search Existing Leads"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
      }}
    />
  );
};

const SearchInput = React.memo(SearchInputRaw, (prevProps, nextProps) => {
  return prevProps.searchValue === nextProps.searchValue;
});

const SelectActionInfoText = styled(AppText)`
  max-width: 310px;
  margin: 0px auto;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
`;

const PaddingDiv = styled.div`
  padding: 24px;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddNewLead = styled(PhoenixAppButton)`
  width: 138px;
  height: 40px;
  margin-left: 10px;
`;

const SearchIcon: React.FC = () => (
  <PhoenixIcon
    svg={search}
    variant="neutral"
    color={theme.icon.neutral.default}
    hoverColor={theme.icon.neutral.default}
    size={16}
  />
);

const SidebarWrapper = styled.div`
  position: relative;
  padding-left: 18px;
  padding-right: 20px;
`;

const SidebarContainer = styled.div`
  padding-top: 18px;
  padding-bottom: 60px;
  min-width: 490px;
  max-height: 100vh;
  overflow-y: auto;
  position: relative; // Ensure the container is positioned
  z-index: 1; // Set a base z-index for the container
`;

const Horizontal = styled.div`
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 432px;
  margin-bottom: 25px;
  gap: 8px;
`;

export { DashboardSidebar };
