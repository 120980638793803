import React, { useState, useRef, useCallback, useMemo, useEffect, useContext } from "react";
import { AppText } from "src/Components/UI";
import { PhoenixCheckbox, PhoenixMultiSelect, PhoenixRadio } from "src/Components/UI/Phoenix";
import { OptionItem } from "src/types";
import { theme } from "src/utils/theme";
import DynamicDropdown, { DropdownMoreBadge } from "./DynamicDropdown";
import { hasValue } from "src/utils/misc";
import { useOpenOnMoreFilter } from "src/utils/hooks";
import { gql, useQuery } from "@apollo/client";
import { FILTER_OPERATIONS_ARRAY } from "../SystemView/LeadSearchFIltersModal";
import { LeadFilterContext, ModalContext } from "src/context";
import styled from "styled-components";

type SequencesDashFilter = { value: string; label: string };

type SequencesFilterProps = {
  isMoreFilter?: boolean;
  onChange: (value: SequencesDashFilter[]) => void;
  removeFilter?: () => void;
  value: SequencesDashFilter[];
};

const FETCH_SEQUENCES = gql`
  query fetchSequences($sequencesFilter: SequencesFilter!) {
    fetchSequences(SequencesFilter: $sequencesFilter) {
      id
      name
      sequence_entry_criteria {
        id
        current_phase
      }
    }
  }
`;

const SequencesFilter = ({ isMoreFilter, onChange, removeFilter, value }: SequencesFilterProps) => {
  const [localOptions, setLocalOptions] = useState<SequencesDashFilter[]>([] as SequencesDashFilter[]);
  const [localValue, setLocalValue] = useState<SequencesDashFilter[]>(value ?? ([] as SequencesDashFilter[]));
  const [isOpen, setIsOpen] = useState(false);
  const hasChangesRef = useRef(false);

  useOpenOnMoreFilter(value, setIsOpen, isMoreFilter);

  const {
    tempLeadSystemOperators,
    setTempLeadSystemOperators,
    tempLeadFilter,
    setTempLeadFilter,
    leadSystemFilter,
  } = useContext(LeadFilterContext);

  const { data: dataSequences, loading: loadingSequences, error: errorSequences } = useQuery(FETCH_SEQUENCES, {
    fetchPolicy: "cache-and-network",
    // sequenceData can be passed into modal. If no sequenceData is passed we will fetch it
    skip: false,
    variables: {
      sequencesFilter: {},
    },
    onCompleted({ fetchSequences }) {
      console.log("fetchSequences:", fetchSequences);
    },
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const sequencesOptionsArray = loadingSequences
    ? []
    : dataSequences?.fetchSequences?.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

  useEffect(() => {
    if (!isOpen) {
      setLocalValue(value ?? []);
      hasChangesRef.current = false;
    }
  }, [value, isOpen]);

  useEffect(() => {
    hasChangesRef.current = JSON.stringify(localValue) !== JSON.stringify(value);
  }, [localValue, value]);

  useEffect(() => {
    setLocalOptions(
      localValue.map(({ label, value }) => ({
        label,
        value,
      })),
    );
  }, []);

  const selectedOptions = useMemo(
    () => tempLeadFilter.sequences.filter((o: any) => localValue?.some((v: any) => v.value === o.value)),
    [tempLeadFilter.sequences, localValue],
  );

  const moreItems = localValue?.length - 1;

  const handleIsOpenChange = useCallback(
    (newIsOpen: boolean) => {
      if (!newIsOpen && hasChangesRef.current) {
        onChange?.(localValue);
        hasChangesRef.current = false;
      }
      setIsOpen(newIsOpen);
    },
    [localValue, onChange],
  );

  const handleClear = useCallback(() => {
    setLocalValue([]);
    setLocalOptions([]);
    hasChangesRef.current = true;
  }, []);

  // const handleCreate = useCallback((input: string) => {
  //   if (!input) return;
  //   const newOption = {
  //     label: input,
  //     value: input,
  //   };
  //   setLocalOptions((prev) => [...prev, newOption]);
  //   setLocalValue((prev) => [...(prev || []), input]);
  //   hasChangesRef.current = true;
  // }, []);

  const handleChange = useCallback((items: SequencesDashFilter[]) => {
    // const newValues = items.map((item) => item.value.toString());
    setTempLeadFilter({ ...tempLeadFilter, sequences: items });
    setLocalValue(items);
    hasChangesRef.current = true;
  }, []);

  const headerContent = useMemo(
    () => (
      <>
        <AppText fontSize={12} fontWeight={500} color={theme.PRIMARY500}>
          {selectedOptions?.[0]?.label}
        </AppText>
        {moreItems ? (
          <DropdownMoreBadge>
            <AppText fontSize={12} fontWeight={500} color={theme.text.neutral.inverse}>
              +{moreItems}
            </AppText>
          </DropdownMoreBadge>
        ) : null}
      </>
    ),
    [selectedOptions, moreItems],
  );

  return (
    <DynamicDropdown<SequencesDashFilter[]>
      isOpen={isOpen}
      label="Sequences"
      onClear={handleClear}
      onRemove={hasValue(localValue) || isMoreFilter ? removeFilter : undefined}
      renderHeader={headerContent}
      setIsOpen={handleIsOpenChange}
      value={localValue}
    >
      {/* <FilterOperatorSelectionComponent
        selectedFilter={selectedFilter}
        tempLeadSystemOperators={tempLeadSystemOperators}
        setTempLeadSystemOperators={setTempLeadSystemOperators}
      /> */}
      {/* {JSON.stringify(tempLeadFilter.sequences)}
      {JSON.stringify(leadSystemFilter.sequences)} */}
      <RadioSection>
        {FILTER_OPERATIONS_ARRAY.map((item: any) => {
          return (
            <RadioDiv>
              <PhoenixRadio
                selected={tempLeadSystemOperators.sequences_operator === item.value}
                onClick={async () => {
                  setTempLeadSystemOperators({ ...tempLeadSystemOperators, sequences_operator: item.value });
                }}
              />
              <AppText fontSize={12} fontWeight={500}>
                {item.label}
              </AppText>
            </RadioDiv>
          );
        })}
      </RadioSection>
      {/* 
      {tempLeadSystemOperators.sequences_operator !== "EmptyField" &&
        dataSequences?.fetchSequences?.map((item: any) => {
          return (
            <RadioDiv>
              <PhoenixCheckbox
                checked={tempLeadFilter.sequences.map((seq: any) => seq.value).includes(item.id)}
                onChange={() =>
                  setTempLeadFilter({
                    ...tempLeadFilter,
                    sequences: tempLeadFilter.sequences.map((seq: { label: string; value: string }) =>
                      seq.value === item.id ? { ...seq, value: item.id } : seq,
                    ),
                  })
                }
              />
              <AppText fontSize={12} fontWeight={500}>
                {item.name}
              </AppText>
            </RadioDiv>
          );
        })} */}
      {tempLeadSystemOperators.sequences_operator !== "EmptyField" && (
        <PhoenixMultiSelect
          isMulti
          isClearable
          marginBottom={false}
          menuPosition="fixed"
          menuShouldBlockScroll
          name="sequences"
          onChange={handleChange}
          // onCreateOption={handleCreate}
          options={sequencesOptionsArray}
          placeholder="Type Sequence Name"
          value={tempLeadFilter.sequences}
        />
      )}

      {/* <PhoenixMultiSelect
        titleText="Sequences"
        titleTextSpacing={8}
        marginBottom={false}
        menuPosition="fixed"
        menuShouldBlockScroll
        name="sequences"
        options={sequencesOptionsArray}
        placeholder="Select Sequences"
        value={selectedOptions}
        onChange={(items: SequencesFilterValue[]) => {
          handleChange(items);
        }}
      /> */}
    </DynamicDropdown>
  );
};

const RadioDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
`;

const RadioSection = styled.div`
  display: flex;
  gap: 3px;
  margin-bottom: 24px;
`;

export default SequencesFilter;
